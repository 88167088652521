&ttl {
  @include fz(56);

  @include mq(1024) {
    @include fz(40);
  }

  &__sub {
    @include fz(16);
    margin-left: 20px;
    display: inline-block;

    @include mq(1024) {
      @include fz(14);
    }
  }

  &02 {
    @include fz(36);
    font-weight: 500;
    line-height: 1.35;
    border-bottom: 1px solid $col_sub;
    margin-bottom: 24px;
    padding-bottom: 22px;

    @include mq(sp) {
      @include fz(25);
      line-height: 1.2;
      margin-bottom: 15px;
      padding-bottom: 10px;
    }
  }
}