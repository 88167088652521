&list {
  li {
    text-indent: -1.1em;
    padding-left: 1.1em;
  }
  &-anchor {
    margin: 65px 0 75px;
    @include flex("", "c");

    @include mq(sp) {
      margin: 30px 0 60px;
    }

    li {
      @include fz(18);
      margin-right: 30px;

      @include mq(tab) {
        width: 50%;
        margin-right: 0;
      }

      @include mq(sp) {
        @include fz(15);
        margin-bottom: 15px;
      }

      a {
        padding-right: 23px;
        position: relative;
        display: inline-block;
        transition: color .3s;

        &:before {
          content: "";
          width: 100%;
          height: 1px;
          background: $col_main;
          position: absolute;
          bottom: -3px;
          left: 0;
          display: inline-block;
          transform: scale(0, 1);
          transform-origin: right top;
          transition: transform .3s;
        }
        &:after {
          content: "";
          width: 6px;
          height: 6px;
          border-right: 1px solid $col_sub;
          border-bottom: 1px solid $col_sub;
          margin: auto 0;
          position: absolute;
          top: -4px;
          right: 3px;
          bottom: 0;
          display: inline-block;
          transform: rotate(45deg);
          transition: border-color .3s;
        }
        &:hover {
          color: $col_main;
          &:before {
            transform: scale(1, 1);
            transform-origin: left top;
          }
          &:after {
            border-color: $col_main;
          }
        }
      }
      &:nth-child(n+3) {
        @include mq(tab) {
          margin-top: 15px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-pagenavi {
    margin-top: 80px;

    @include mq(sp) {
      margin-top: 50px;
    }

    .wp-pagenavi {
      @include flex("c","c");
      span {
        width: 35px;
        @include fz(16);
        line-height: 1;
        text-align: center;
        border: 1px solid $col_sub;
        padding: 9px 0 8px;
        &.current {
          color: #fff;
          background: $col_sub;
        }
        + * {
          margin-left: 10px;
        }
      }
      a {
        width: 35px;
        @include fz(16);
        line-height: 1;
        text-align: center;
        border: 1px solid $col_sub;
        padding: 9px 0 8px;
        transition: color .1s, background .1s;
        &.previouspostslink,
        &.nextpostslink {
          font-size: 0;
          color: inherit !important;
          background: none !important;
          border: none;
          &:before {
            content: "";
            width: 7px;
            height: 7px;
            border: 1px solid $col_sub;
            margin: auto;
            display: inline-block;
            transform: rotate(45deg);
          }
          &.previouspostslink {
            &:before {
              border-top: none;
              border-right: none;
            }
          }
          &.nextpostslink {
            &:before {
              border-bottom: none;
              border-left: none;
            }
          }
        }
        &:hover {
          color: #fff;
          background: $col_sub;
        }
        + * {
          margin-left: 10px;
        }
      }
    }
  }
}