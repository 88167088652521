&header {
  width: 100%;
  background: #fff;
  padding: 0 vwpc(32) 0 vwpc(37);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background .1s;

  @include mq(1024) {
    padding: 20px 15px;
  }

  &__inner {
    height: 100%;
    @include flex("c", "fs");
  }
  &__logo {
    width: 128px;
    height: 40px;
    margin-right: auto;

    @include mq(1024) {
      width: 112.5px;
      height: auto;
    }
  }
  &__search {
    width: 150px;
    background: rgba(#a2a2a2, .6);
    margin-left: vwpc(38);
    position: relative;

    @include mq(pc) {
      height: auto !important;
      padding: 0 !important;
      display: block !important;
    }

    @include mq(1103) {
      margin-left: 1.95vw;
    }

    @include mq(1024) {
      width: 100%;
      background: rgba(#000, .8);
      margin-left: 0;
      padding: 35px 15px;
      position: absolute;
      top: 75px;
      left: 0;
      display: none;
    }

    &__area {
      appearance: none;
      width: 100%;
      @include fz(14);
      font-weight: 300;
      color: #fff;
      background: none;
      border: none;
      padding: 1px 20px 1px 8px;

      @include mq(1024) {
        @include fz(20);
        background: #ddd;
        padding: 6px 18px;
      }

      &::placeholder {
        color: #fff;
      }
    }
    &__btn {
      width: 12px;
      height: 12px;
      background: none;
      border: none;
      margin: auto 0;
      position: absolute;
      top: 0;
      right: 6px;
      bottom: 0;

      @include mq(1024) {
        display: none;
      }
    }

    &-sp {
      @include mq(pc) {
        display: none;
      }

      width: 23.5px;
      height: 23.5px;
      margin-right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__menu {
    width: 43px;
    height: 27px;
    position: relative;
    cursor: pointer;

    @include mq(pc) {
      display: none;
    }

    &:before,
    span,
    &:after {
      content: "";
      height: 2px;
      background: $col_main;
      position: absolute;
      left: 0;
      z-index: 5;
      display: inline-block;
      transition: transform .3s, opacity .3s;
    }
    &:before {
      width: 100%;
      top: 0;
    }
    span {
      width: 75%;
      margin: auto 0;
      top: 0;
      bottom: 0;
    }
    &:after {
      width: 50%;
      bottom: 0;
    }
    &.is-active {
      &:before {
        width: 100%;
        transform: translateY(12px) rotate(-45deg);
      }
      span {
        opacity: 0;
      }
      &:after {
        width: 100%;
        transform: translateY(-13px) rotate(45deg);
      }
    }
  }

  &--home {
    @extend .l-header;
    background: transparent;
    &.is-fixed {
      background: #fff !important;
      .l-header__menu {
        &:before,
        span,
        &:after {
          background: $col_main;
        }
      }
      .l-gnavi {
        &__list {
          > li {
            > a {
              color: inherit !important;

              @include mq(1024) {
                color: #fff !important;
              }

              &:hover {
                @include mq(pc) {
                  color: $col_main !important;
                }
              }
            }
          }
        }
      }
    }
  }

  &--shinko {
    &__logo {
      a {
        background: #fff;
        padding: 30px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        display: inline-block;

        @include mq(sp) {
          padding: 20px;
        }

        &:hover {
          img {
            opacity: .5;
          }
        }

        img {
          width: 103px;
          height: 97px;
          transition: opacity .3s;

          @include mq(sp) {
            width: 65px;
            height: 60px;
          }
        }
      }
    }
    &__contact {
      width: 245px;
      @include fz(16);
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1000;

      @include mq(sp) {
        width: 55px;
        height: 55px;
        margin: 20px 15px 0 0;
      }

      a {
        width: 100%;
        height: 100%;
        color: #fff;
        background: #37517b;
        padding: 18px 0;
        @include flex("c", "c");
        transition: color .3s, background .3s;
        &:before {
          content: "";
          width: 21px;
          height: 14px;
          background: url(#{$img_common_path}/cmn-contact-ico01.svg) 0 0 no-repeat;
          background-size: 100% 100%;
          margin: 2px 10px 0 0;
          display: inline-block;
          transition: background .3s;

          @include mq(sp) {
            margin: 0;
          }
        }

        @include mq(sp) {
          font-size: 0;
          border-radius: 50%;
        }

        &:hover {
          color: #37517b;
          background: #fff;
          &:before {
            background-image: url(#{$img_common_path}/cmn-contact-ico02.svg);
          }
        }
      }
    }
  }
}