@charset "UTF-8";
/*!
Coding style from FLOCSS
https://github.com/hiloki/flocss
 */
/* 汎用スタイル */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font-style: normal;
  font-weight: normal;
  vertical-align: baseline; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block; }

ul, ol {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline; }

picture {
  font-size: 0; }

sub, sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 50%; }

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

* {
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: break-all; }

html {
  font-size: 10px; }

body {
  font-family: 'Noto Sans CJK JP', 'Noto Sans JP', sans-serif;
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  color: #201f1f;
  line-height: 1.5; }
  body.is-preload * {
    transition: none !important; }
    body.is-preload *:before, body.is-preload *:after {
      transition: none !important; }

a {
  color: #201f1f;
  text-decoration: none;
  outline: none;
  transition: opacity .3s; }
  @media print, screen and (min-width: 1025px) {
    a[href^="tel:"] {
      cursor: default;
      pointer-events: none; } }

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom; }

input, textarea, button, select {
  font: inherit; }

button {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer; }

label {
  cursor: pointer; }

.l-header, .l-header--home {
  width: 100%;
  background: #fff;
  padding: 0 2vw 0 2.3125vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: background .1s; }
  @media screen and (max-width: 1024px) {
    .l-header, .l-header--home {
      padding: 20px 15px; } }
  .l-header__inner {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start; }
  .l-header__logo {
    width: 128px;
    height: 40px;
    margin-right: auto; }
    @media screen and (max-width: 1024px) {
      .l-header__logo {
        width: 112.5px;
        height: auto; } }
  .l-header__search {
    width: 150px;
    background: rgba(162, 162, 162, 0.6);
    margin-left: 2.375vw;
    position: relative; }
    @media print, screen and (min-width: 1025px) {
      .l-header__search {
        height: auto !important;
        padding: 0 !important;
        display: block !important; } }
    @media screen and (max-width: 1103px) {
      .l-header__search {
        margin-left: 1.95vw; } }
    @media screen and (max-width: 1024px) {
      .l-header__search {
        width: 100%;
        background: rgba(0, 0, 0, 0.8);
        margin-left: 0;
        padding: 35px 15px;
        position: absolute;
        top: 75px;
        left: 0;
        display: none; } }
    .l-header__search__area {
      appearance: none;
      width: 100%;
      font-size: 14px;
      font-size: 1.4rem;
      font-weight: 300;
      color: #fff;
      background: none;
      border: none;
      padding: 1px 20px 1px 8px; }
      @media screen and (max-width: 1024px) {
        .l-header__search__area {
          font-size: 20px;
          font-size: 2rem;
          background: #ddd;
          padding: 6px 18px; } }
      .l-header__search__area::placeholder {
        color: #fff; }
    .l-header__search__btn {
      width: 12px;
      height: 12px;
      background: none;
      border: none;
      margin: auto 0;
      position: absolute;
      top: 0;
      right: 6px;
      bottom: 0; }
      @media screen and (max-width: 1024px) {
        .l-header__search__btn {
          display: none; } }
    .l-header__search-sp {
      width: 23.5px;
      height: 23.5px;
      margin-right: 20px;
      cursor: pointer; }
      @media print, screen and (min-width: 1025px) {
        .l-header__search-sp {
          display: none; } }
      .l-header__search-sp img {
        width: 100%;
        height: 100%; }
  .l-header__menu {
    width: 43px;
    height: 27px;
    position: relative;
    cursor: pointer; }
    @media print, screen and (min-width: 1025px) {
      .l-header__menu {
        display: none; } }
    .l-header__menu:before,
    .l-header__menu span, .l-header__menu:after {
      content: "";
      height: 2px;
      background: #004ea2;
      position: absolute;
      left: 0;
      z-index: 5;
      display: inline-block;
      transition: transform .3s, opacity .3s; }
    .l-header__menu:before {
      width: 100%;
      top: 0; }
    .l-header__menu span {
      width: 75%;
      margin: auto 0;
      top: 0;
      bottom: 0; }
    .l-header__menu:after {
      width: 50%;
      bottom: 0; }
    .l-header__menu.is-active:before {
      width: 100%;
      transform: translateY(12px) rotate(-45deg); }
    .l-header__menu.is-active span {
      opacity: 0; }
    .l-header__menu.is-active:after {
      width: 100%;
      transform: translateY(-13px) rotate(45deg); }
  .l-header--home {
    background: transparent; }
    .l-header--home.is-fixed {
      background: #fff !important; }
      .l-header--home.is-fixed .l-header__menu:before,
      .l-header--home.is-fixed .l-header__menu span, .l-header--home.is-fixed .l-header__menu:after {
        background: #004ea2; }
      .l-header--home.is-fixed .l-gnavi__list > li > a {
        color: inherit !important; }
        @media screen and (max-width: 1024px) {
          .l-header--home.is-fixed .l-gnavi__list > li > a {
            color: #fff !important; } }
        @media print, screen and (min-width: 1025px) {
          .l-header--home.is-fixed .l-gnavi__list > li > a:hover {
            color: #004ea2 !important; } }
  .l-header--shinko__logo a {
    background: #fff;
    padding: 30px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: inline-block; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-header--shinko__logo a {
        padding: 20px; } }
    .l-header--shinko__logo a:hover img {
      opacity: .5; }
    .l-header--shinko__logo a img {
      width: 103px;
      height: 97px;
      transition: opacity .3s; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-header--shinko__logo a img {
          width: 65px;
          height: 60px; } }
  .l-header--shinko__contact {
    width: 245px;
    font-size: 16px;
    font-size: 1.6rem;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-header--shinko__contact {
        width: 55px;
        height: 55px;
        margin: 20px 15px 0 0; } }
    .l-header--shinko__contact a {
      width: 100%;
      height: 100%;
      color: #fff;
      background: #37517b;
      padding: 18px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      transition: color .3s, background .3s; }
      .l-header--shinko__contact a:before {
        content: "";
        width: 21px;
        height: 14px;
        background: url(/cms/wp-content/themes/tada-con/images/common/cmn-contact-ico01.svg) 0 0 no-repeat;
        background-size: 100% 100%;
        margin: 2px 10px 0 0;
        display: inline-block;
        transition: background .3s; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .l-header--shinko__contact a:before {
            margin: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-header--shinko__contact a {
          font-size: 0;
          border-radius: 50%; } }
      .l-header--shinko__contact a:hover {
        color: #37517b;
        background: #fff; }
        .l-header--shinko__contact a:hover:before {
          background-image: url(/cms/wp-content/themes/tada-con/images/common/cmn-contact-ico02.svg); }

@media print, screen and (min-width: 1025px) {
  .l-gnavi {
    height: auto !important;
    display: block !important; } }

@media screen and (max-width: 1024px) {
  .l-gnavi {
    width: 100%;
    max-height: calc(100vh - 75px);
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 75px;
    left: 0;
    overflow-y: auto;
    display: none; } }

.l-gnavi__list {
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start; }
  @media screen and (max-width: 1024px) {
    .l-gnavi__list {
      height: auto;
      padding: 60px 15px 100px;
      flex-direction: column; } }
  .l-gnavi__list > li {
    height: 100%;
    font-size: 15px;
    font-size: 1.5rem; }
    @media screen and (max-width: 1024px) {
      .l-gnavi__list > li {
        width: 100%;
        height: auto; }
        .l-gnavi__list > li + li {
          margin-top: 40px; } }
    .l-gnavi__list > li > a {
      height: 100%;
      padding: 0 1.375vw;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      transition: color .1s; }
      @media screen and (max-width: 1103px) {
        .l-gnavi__list > li > a {
          padding: 0 0.9375vw; } }
      @media screen and (max-width: 1024px) {
        .l-gnavi__list > li > a {
          font-family: "リュウミン R-KL", "Noto Serif CJK JP", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, serif;
          font-size: 23px;
          font-size: 2.3rem;
          color: #fff;
          padding: 0; } }
      @media print, screen and (min-width: 1025px) {
        .l-gnavi__list > li > a:hover {
          color: #004ea2 !important; } }
      .l-gnavi__list > li > a span {
        font-weight: 500;
        display: inline-block; }
    .l-gnavi__list > li.is-mnavi > a {
      color: #004ea2; }
    .l-gnavi__list > li.is-current > a span {
      position: relative; }
      .l-gnavi__list > li.is-current > a span:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #004ea2;
        margin: 0 auto;
        position: absolute;
        right: 0;
        bottom: -4px;
        left: 0;
        display: inline-block; }
        @media screen and (max-width: 1024px) {
          .l-gnavi__list > li.is-current > a span:after {
            content: none; } }

.l-mnavi {
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 10;
  display: none; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-mnavi {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (max-width: 1024px) {
    .l-mnavi {
      height: auto !important;
      background: none;
      padding: 0;
      position: static;
      display: block !important; } }
  .l-mnavi__inner {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    color: #fff;
    padding-top: 27px;
    padding-bottom: 27px; }
    @media screen and (max-width: 1024px) {
      .l-mnavi__inner {
        padding-top: 0;
        padding-bottom: 0;
        flex-direction: column; } }
    .l-mnavi__inner > div {
      margin-left: 55px;
      flex: 1; }
      @media screen and (max-width: 1024px) {
        .l-mnavi__inner > div {
          margin-left: 0;
          flex: unset; } }
  @media screen and (max-width: 1024px) {
    .l-mnavi__thumb {
      display: none; } }
  .l-mnavi__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    margin-top: 5px; }
    @media screen and (max-width: 1024px) {
      .l-mnavi__ttl {
        display: none; } }
  .l-mnavi__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    border-left: 1px solid #fff;
    margin-top: 25px; }
    @media screen and (max-width: 1024px) {
      .l-mnavi__list {
        border-left: none;
        margin-top: 0;
        flex-direction: column; } }
    .l-mnavi__list li {
      font-size: 16px;
      font-size: 1.6rem;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      font-weight: 500;
      text-align: center;
      border-right: 1px solid #fff;
      padding: 0 20px; }
      @media screen and (max-width: 1024px) {
        .l-mnavi__list li {
          text-align: left;
          border-right: none;
          margin-top: 1em;
          padding: 0;
          justify-content: flex-start; } }
      .l-mnavi__list li a {
        color: #fff; }
        @media screen and (max-width: 1024px) {
          .l-mnavi__list li a {
            color: rgba(255, 255, 255, 0.8); }
            .l-mnavi__list li a:before {
              content: "– "; } }
        .l-mnavi__list li a:hover {
          text-decoration: underline; }

.l-wrapper--info {
  max-width: 1340px;
  margin: 0 auto;
  margin-bottom: 130px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-wrapper--info {
      margin-bottom: 70px; } }
  .l-wrapper--info__inner, .l-wrapper--mansion__inner {
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-wrapper--info__inner, .l-wrapper--mansion__inner {
        padding-right: 15px;
        padding-left: 15px; } }
    @media screen and (max-width: 834px) {
      .l-wrapper--info__inner, .l-wrapper--mansion__inner {
        display: block; } }
    .l-wrapper--info__inner .l-content, .l-wrapper--mansion__inner .l-content {
      width: 72%; }
      @media screen and (max-width: 834px) {
        .l-wrapper--info__inner .l-content, .l-wrapper--mansion__inner .l-content {
          width: 100%; } }

.l-wrapper--mansion {
  margin-bottom: 130px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-wrapper--mansion {
      margin-bottom: 70px; } }
  .l-wrapper--mansion__inner {
    display: block; }
    .l-wrapper--mansion__inner .l-content {
      width: 100%; }

.l-wrapper--search {
  margin-top: 165px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-wrapper--search {
      margin-top: 120px; } }

.l-page-mv {
  margin: 80px 0 85px;
  position: relative; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-page-mv {
      margin: 75px 0 45px; } }
  .l-page-mv__img img {
    width: 100%; }
  .l-page-mv__ttl {
    width: 100%;
    height: 100%;
    font-size: 36px;
    font-size: 3.6rem;
    font-weight: 500;
    color: #fff;
    text-shadow: 0px 4px 43px #080103;
    margin: auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-page-mv__ttl {
        font-size: 30px;
        font-size: 3rem; } }

.l-breadcrumb {
  padding-right: 30px;
  padding-left: 30px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-breadcrumb {
      padding-right: 15px;
      padding-left: 15px; } }
  .l-breadcrumb__list {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    .l-breadcrumb__list li {
      font-size: 14px;
      font-size: 1.4rem; }
      .l-breadcrumb__list li:after {
        content: "＞";
        font-size: 12px;
        font-size: 1.2rem;
        color: #757575;
        margin: 0 .5em;
        display: inline-block; }
      .l-breadcrumb__list li:last-child:after {
        content: none; }
      .l-breadcrumb__list li a:hover {
        text-decoration: underline; }
      .l-breadcrumb__list li span {
        color: #757575; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-breadcrumb {
      display: none; } }

.l-page-ttl {
  padding-right: 30px;
  padding-left: 30px;
  max-width: 1340px;
  margin: 15px auto 0; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-page-ttl {
      padding-right: 15px;
      padding-left: 15px; } }
  .l-page-ttl__main {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 50px 7px 0;
    position: relative;
    display: inline-block; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-page-ttl__main {
        width: 100%;
        padding: 0;
        line-height: 1.2; } }
    .l-page-ttl__main:before {
      content: "";
      width: 160px;
      height: 4px;
      background: #004ea2;
      position: absolute;
      bottom: 1px;
      left: 0;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-page-ttl__main:before {
          width: 45px;
          bottom: -19px; } }
    .l-page-ttl__main:after {
      content: "";
      width: 100%;
      height: 1px;
      background: #201f1f;
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-page-ttl__main:after {
          width: 100%;
          bottom: -20px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-page-ttl__main .c-ttl__sub {
        margin-left: 0;
        display: block; } }

.l-sidebar {
  width: 19.92%;
  margin: 10px 0 0 5.74%; }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .l-sidebar {
      width: 175px;
      margin-left: 3%; } }
  @media screen and (max-width: 834px) {
    .l-sidebar {
      width: 100%;
      margin: 60px 0 0; } }
  .l-sidebar__list {
    font-size: 15px;
    font-size: 1.5rem;
    text-align: center;
    border-top: 1px solid #201f1f; }
    .l-sidebar__list:last-child {
      border-bottom: 1px solid #201f1f; }
    .l-sidebar__list dt {
      padding: 15px 0;
      position: relative;
      cursor: pointer;
      user-select: none;
      transition: background .1s; }
      .l-sidebar__list dt:after {
        content: "";
        width: 8px;
        height: 8px;
        border-right: 1px solid #201f1f;
        border-bottom: 1px solid #201f1f;
        margin: auto 0;
        position: absolute;
        top: -7px;
        right: 20px;
        bottom: 0;
        display: inline-block;
        transform: rotate(45deg); }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .l-sidebar__list dt:after {
            right: 15px; } }
      .l-sidebar__list dt.is-active {
        background: #f4f4f4; }
        .l-sidebar__list dt.is-active:after {
          top: 5px;
          transform: rotate(-135deg); }
    .l-sidebar__list dd {
      display: none; }
    .l-sidebar__list__cat li {
      border-top: 1px solid #e8e8e8; }
      .l-sidebar__list__cat li a {
        width: 100%;
        padding: 15px 0;
        display: inline-block;
        transition: background .3s; }
        .l-sidebar__list__cat li a:hover {
          background: #f4f4f4; }
    .l-sidebar__list__cat--parent {
      font-weight: bold; }
    .l-sidebar__list__years li {
      border-top: 1px solid #e8e8e8; }
      .l-sidebar__list__years li a {
        width: 100%;
        padding: 15px 0;
        display: inline-block;
        transition: background .3s; }
        .l-sidebar__list__years li a:hover {
          background: #f4f4f4; }

.l-loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
  visibility: visible;
  transform: skew(-35deg);
  transition: visibility 1s; }
  .l-loading:before, .l-loading:after {
    content: "";
    width: 100%;
    height: 100%;
    background: #004ea2;
    display: block;
    position: fixed;
    top: 0;
    transition: transform 0.5s ease-in;
    transition-delay: .5s; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-loading:before, .l-loading:after {
        width: 120%; } }
  .l-loading:before {
    right: calc(50% - 1px); }
  .l-loading:after {
    left: calc(50% - 1px); }
  .l-loading.is-load {
    visibility: hidden; }
    .l-loading.is-load:before {
      transform: translateX(-120%); }
    .l-loading.is-load:after {
      transform: translateX(120%); }

.l-pagetop {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 10;
  display: none; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-pagetop {
      display: none !important; } }
  .l-pagetop a {
    width: 100%;
    display: inline-block;
    transition: opacity .3s; }
    .l-pagetop a:hover {
      opacity: .5; }

.l-contact {
  padding-right: 30px;
  padding-left: 30px;
  color: #fff;
  background: url(/cms/wp-content/themes/tada-con/images/common/cmn-contact-bg-pc.jpg) center top no-repeat;
  background-size: cover;
  margin-top: 140px;
  padding-top: 100px;
  padding-bottom: 100px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-contact {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-contact {
      background-image: url(/cms/wp-content/themes/tada-con/images/common/cmn-contact-bg-sp.jpg);
      margin-top: 80px;
      padding-top: 39px;
      padding-bottom: 39px; } }
  .l-contact__inr {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-contact__inr {
        display: block; } }
  .l-contact__ttl {
    font-size: 22px;
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 10px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-contact__ttl {
        font-size: 23px;
        font-size: 2.3rem;
        line-height: 1;
        margin-bottom: 20px; } }
    .l-contact__ttl span {
      font-size: 14px;
      font-size: 1.4rem;
      margin-bottom: 10px;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-contact__ttl span {
          font-size: 13px;
          font-size: 1.3rem;
          margin-bottom: 5px; } }
  .l-contact__lead {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-contact__lead {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.5; } }
  .l-contact__info {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-contact__info {
        width: 100%; } }
  .l-contact__tel {
    font-size: 34px;
    font-size: 3.4rem;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-contact__tel {
        font-size: 26px;
        font-size: 2.6rem; } }
    .l-contact__tel:before {
      content: "";
      width: 26px;
      height: 26px;
      background: url(/cms/wp-content/themes/tada-con/images/common/cmn-contat-tel-ico.svg) 0 0 no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-contact__tel:before {
          width: 19px;
          height: 19px;
          margin-right: 7px; } }
  .l-contact__time {
    font-size: 14px;
    font-size: 1.4rem; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-contact__time {
        font-size: 12px;
        font-size: 1.2rem; } }
  .l-contact__btn {
    width: 260px;
    font-size: 16px;
    font-size: 1.6rem;
    margin-left: 30px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-contact__btn {
        width: 37.8%;
        font-size: 13px;
        font-size: 1.3rem;
        margin-left: 0; } }
    .l-contact__btn a {
      width: 100%;
      color: #fff;
      border: 1px solid #fff;
      padding: 18px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      transition: color .3s, background .3s; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-contact__btn a {
          padding: 9px 0; } }
      .l-contact__btn a:before {
        content: "";
        width: 21px;
        height: 14px;
        background: url(/cms/wp-content/themes/tada-con/images/common/cmn-contact-ico01.svg) 0 0 no-repeat;
        background-size: 100% 100%;
        margin: 2px 10px 0 0;
        display: inline-block;
        transition: background .3s; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .l-contact__btn a:before {
            width: 13px;
            height: 9px; } }
      .l-contact__btn a:hover {
        color: #37517b;
        background: rgba(255, 255, 255, 0.9); }
        .l-contact__btn a:hover:before {
          background-image: url(/cms/wp-content/themes/tada-con/images/common/cmn-contact-ico02.svg); }
  .l-contact__logo {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end; }
    .l-contact__logo__01 {
      width: 210px;
      text-align: center; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-contact__logo__01 {
          width: 50%; }
          .l-contact__logo__01 img {
            width: 150px; } }
    .l-contact__logo__02 {
      width: 106px;
      text-align: center;
      margin-left: 30px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-contact__logo__02 {
          width: 50%;
          margin-left: 0; }
          .l-contact__logo__02 img {
            width: 112px; } }

.l-footer {
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 80px;
  padding-bottom: 30px;
  background: #e6e6e6; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .l-footer {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (max-width: 1024px) {
    .l-footer {
      padding-top: 55px;
      padding-bottom: 25px; } }
  .l-footer__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .l-footer__main {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .l-footer__main {
        flex-direction: column; } }
  .l-footer__logo {
    width: 184px; }
    @media screen and (max-width: 1024px) {
      .l-footer__logo {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between; } }
    @media screen and (max-width: 1024px) {
      .l-footer__logo > p {
        text-align: center; } }
    .l-footer__logo > p + p {
      margin-top: 30px; }
    .l-footer__logo__01 {
      width: 100%; }
      @media screen and (max-width: 1024px) {
        .l-footer__logo__01 img {
          width: 150px; } }
    @media screen and (max-width: 1024px) {
      .l-footer__logo__02 {
        width: 50%; } }
    @media screen and (max-width: 1024px) {
      .l-footer__logo__02 img {
        width: 150px; } }
    @media screen and (max-width: 1024px) {
      .l-footer__logo__03 {
        width: 50%; } }
    .l-footer__logo__03 img {
      width: 103px; }
      @media screen and (max-width: 1024px) {
        .l-footer__logo__03 img {
          width: 74px; } }
  .l-footer__navi {
    width: 48.59%;
    margin-top: 15px; }
    @media screen and (max-width: 1024px) {
      .l-footer__navi {
        display: none; } }
    .l-footer__navi__list {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between; }
      .l-footer__navi__list dl dt {
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 10px; }
        .l-footer__navi__list dl dt a {
          color: #201f1f; }
          .l-footer__navi__list dl dt a:hover {
            text-decoration: underline; }
      .l-footer__navi__list dl dd {
        font-size: 13px;
        font-size: 1.3rem;
        margin-left: 8px; }
        .l-footer__navi__list dl dd a {
          color: #626262; }
          .l-footer__navi__list dl dd a:hover {
            text-decoration: underline; }
      .l-footer__navi__list dl + dl {
        margin-top: 25px; }
  @media screen and (max-width: 1024px) {
    .l-footer__btn {
      width: 64.19%;
      margin: 25px auto 0; } }
  .l-footer__btn li {
    width: 215px; }
    @media screen and (max-width: 1024px) {
      .l-footer__btn li {
        width: 100%; } }
    .l-footer__btn li + li {
      margin-top: 15px; }
      @media screen and (max-width: 1024px) {
        .l-footer__btn li + li {
          margin-top: 20px; } }
  .l-footer__bottom {
    margin-top: 46px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-end; }
    @media screen and (max-width: 1024px) {
      .l-footer__bottom {
        margin-top: 25px;
        display: block; } }
    .l-footer__bottom li {
      font-size: 13px;
      font-size: 1.3rem;
      border-right: 1px solid #626262;
      margin-right: 1.3em;
      padding-right: 1.3em; }
      @media screen and (max-width: 1024px) {
        .l-footer__bottom li {
          width: 100%;
          text-align: center;
          border-right: none;
          margin-right: 0;
          margin-bottom: 15px;
          padding-right: 0; } }
      .l-footer__bottom li a {
        color: #626262; }
        .l-footer__bottom li a:hover {
          text-decoration: underline; }
      .l-footer__bottom li:last-child {
        border-right: none;
        margin-right: 0;
        padding-right: 0; }
  .l-footer__copy {
    text-align: center;
    margin-top: 35px;
    display: block; }
    @media screen and (max-width: 1024px) {
      .l-footer__copy {
        margin-top: 0; } }
  .l-footer--shinko {
    background: #004ea2;
    padding: 16px 0; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .l-footer--shinko {
        padding: 8px 0; } }
    .l-footer--shinko__copy {
      font-size: 12px;
      font-size: 1.2rem;
      text-align: center;
      color: #fff;
      display: block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .l-footer--shinko__copy {
          font-size: 10px;
          font-size: 1rem; } }

.c-ttl {
  font-size: 56px;
  font-size: 5.6rem; }
  @media screen and (max-width: 1024px) {
    .c-ttl {
      font-size: 40px;
      font-size: 4rem; } }
  .c-ttl__sub {
    font-size: 16px;
    font-size: 1.6rem;
    margin-left: 20px;
    display: inline-block; }
    @media screen and (max-width: 1024px) {
      .c-ttl__sub {
        font-size: 14px;
        font-size: 1.4rem; } }
  .c-ttl02 {
    font-size: 36px;
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 1.35;
    border-bottom: 1px solid #201f1f;
    margin-bottom: 24px;
    padding-bottom: 22px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .c-ttl02 {
        font-size: 25px;
        font-size: 2.5rem;
        line-height: 1.2;
        margin-bottom: 15px;
        padding-bottom: 10px; } }

.c-txt {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2.13; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .c-txt {
      line-height: 1.5; } }

.c-btn, .c-btn--rev, .c-btn02, .c-btn02--rev {
  width: 235px;
  font-size: 15px;
  font-size: 1.5rem;
  text-align: center;
  border: 1px solid #201f1f;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .c-btn, .c-btn--rev, .c-btn02, .c-btn02--rev {
      width: 60%; } }
  .c-btn a, .c-btn--rev a, .c-btn02 a, .c-btn02--rev a,
  .c-btn button,
  .c-btn--rev button,
  .c-btn02 button,
  .c-btn02--rev button {
    width: 100%;
    color: #201f1f;
    padding: 13px 0;
    position: relative;
    z-index: 0;
    display: inline-block;
    transition: color .3s; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .c-btn a, .c-btn--rev a, .c-btn02 a, .c-btn02--rev a,
      .c-btn button,
      .c-btn--rev button,
      .c-btn02 button,
      .c-btn02--rev button {
        padding: 14px 0; } }
    .c-btn a:after, .c-btn--rev a:after, .c-btn02 a:after, .c-btn02--rev a:after,
    .c-btn button:after,
    .c-btn--rev button:after,
    .c-btn02 button:after,
    .c-btn02--rev button:after {
      content: "";
      width: 120%;
      height: 100%;
      background: #201f1f;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform-origin: right top;
      transform: skewX(-30deg) scale(0, 1);
      transition: transform .3s; }
    .c-btn a:hover, .c-btn--rev a:hover, .c-btn02 a:hover, .c-btn02--rev a:hover,
    .c-btn button:hover,
    .c-btn--rev button:hover,
    .c-btn02 button:hover,
    .c-btn02--rev button:hover {
      color: #fff; }
      .c-btn a:hover:after, .c-btn--rev a:hover:after, .c-btn02 a:hover:after, .c-btn02--rev a:hover:after,
      .c-btn button:hover:after,
      .c-btn--rev button:hover:after,
      .c-btn02 button:hover:after,
      .c-btn02--rev button:hover:after {
        transform-origin: left top;
        transform: skewX(-30deg) scale(1, 1); }
  .c-btn--rev {
    border-color: #fff; }
    .c-btn--rev a,
    .c-btn--rev button {
      color: #fff; }
      .c-btn--rev a:after,
      .c-btn--rev button:after {
        background: #fff; }
      .c-btn--rev a:hover,
      .c-btn--rev button:hover {
        color: #201f1f; }
  .c-btn02, .c-btn02--rev {
    border-color: #fff; }
    .c-btn02 a, .c-btn02--rev a {
      color: #fff; }
      .c-btn02 a:after, .c-btn02--rev a:after {
        background: #fff; }
      .c-btn02 a:hover, .c-btn02--rev a:hover {
        color: #004ea2; }
    .c-btn02--rev {
      border-color: #004ea2; }
      .c-btn02--rev a {
        color: #004ea2; }
        .c-btn02--rev a:after {
          background: #004ea2; }
        .c-btn02--rev a:hover {
          color: #fff; }

.c-list li {
  text-indent: -1.1em;
  padding-left: 1.1em; }

.c-list-anchor {
  margin: 65px 0 75px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .c-list-anchor {
      margin: 30px 0 60px; } }
  .c-list-anchor li {
    font-size: 18px;
    font-size: 1.8rem;
    margin-right: 30px; }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .c-list-anchor li {
        width: 50%;
        margin-right: 0; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .c-list-anchor li {
        font-size: 15px;
        font-size: 1.5rem;
        margin-bottom: 15px; } }
    .c-list-anchor li a {
      padding-right: 23px;
      position: relative;
      display: inline-block;
      transition: color .3s; }
      .c-list-anchor li a:before {
        content: "";
        width: 100%;
        height: 1px;
        background: #004ea2;
        position: absolute;
        bottom: -3px;
        left: 0;
        display: inline-block;
        transform: scale(0, 1);
        transform-origin: right top;
        transition: transform .3s; }
      .c-list-anchor li a:after {
        content: "";
        width: 6px;
        height: 6px;
        border-right: 1px solid #201f1f;
        border-bottom: 1px solid #201f1f;
        margin: auto 0;
        position: absolute;
        top: -4px;
        right: 3px;
        bottom: 0;
        display: inline-block;
        transform: rotate(45deg);
        transition: border-color .3s; }
      .c-list-anchor li a:hover {
        color: #004ea2; }
        .c-list-anchor li a:hover:before {
          transform: scale(1, 1);
          transform-origin: left top; }
        .c-list-anchor li a:hover:after {
          border-color: #004ea2; }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .c-list-anchor li:nth-child(n+3) {
        margin-top: 15px; } }
    .c-list-anchor li:last-child {
      margin-right: 0; }

.c-list-pagenavi {
  margin-top: 80px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .c-list-pagenavi {
      margin-top: 50px; } }
  .c-list-pagenavi .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    .c-list-pagenavi .wp-pagenavi span {
      width: 35px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1;
      text-align: center;
      border: 1px solid #201f1f;
      padding: 9px 0 8px; }
      .c-list-pagenavi .wp-pagenavi span.current {
        color: #fff;
        background: #201f1f; }
      .c-list-pagenavi .wp-pagenavi span + * {
        margin-left: 10px; }
    .c-list-pagenavi .wp-pagenavi a {
      width: 35px;
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1;
      text-align: center;
      border: 1px solid #201f1f;
      padding: 9px 0 8px;
      transition: color .1s, background .1s; }
      .c-list-pagenavi .wp-pagenavi a.previouspostslink, .c-list-pagenavi .wp-pagenavi a.nextpostslink {
        font-size: 0;
        color: inherit !important;
        background: none !important;
        border: none; }
        .c-list-pagenavi .wp-pagenavi a.previouspostslink:before, .c-list-pagenavi .wp-pagenavi a.nextpostslink:before {
          content: "";
          width: 7px;
          height: 7px;
          border: 1px solid #201f1f;
          margin: auto;
          display: inline-block;
          transform: rotate(45deg); }
        .c-list-pagenavi .wp-pagenavi a.previouspostslink.previouspostslink:before, .c-list-pagenavi .wp-pagenavi a.nextpostslink.previouspostslink:before {
          border-top: none;
          border-right: none; }
        .c-list-pagenavi .wp-pagenavi a.previouspostslink.nextpostslink:before, .c-list-pagenavi .wp-pagenavi a.nextpostslink.nextpostslink:before {
          border-bottom: none;
          border-left: none; }
      .c-list-pagenavi .wp-pagenavi a:hover {
        color: #fff;
        background: #201f1f; }
      .c-list-pagenavi .wp-pagenavi a + * {
        margin-left: 10px; }

.c-table {
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .c-table {
      font-size: 14px;
      font-size: 1.4rem; } }
  .c-table tr {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede; }
    .c-table tr:nth-child(odd) {
      background: #f9f9f9; }
    .c-table tr th {
      width: 21.88%;
      line-height: 1.88;
      text-align: left;
      vertical-align: middle;
      padding: 25px 0 25px 15px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .c-table tr th {
          width: 29%;
          padding: 15px 5px 15px; } }
    .c-table tr td {
      line-height: 1.88;
      vertical-align: middle;
      padding: 25px 15px 25px 0; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .c-table tr td {
          padding: 20px 5px 20px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
  height: 100%; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 100%; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .slick-slide > div {
    height: 100%; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-arrow {
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background: #333 url(/cms/wp-content/themes/tada-con/images/common/cmn-link-ico02.svg) center center no-repeat;
  background-size: 6px 11px;
  border: none;
  margin: auto 0;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 5;
  cursor: pointer; }
  .slick-arrow.slick-prev {
    transform: rotate(-180deg); }
  .slick-arrow.slick-disabled {
    display: none !important; }

.p-top-mv {
  position: relative; }
  .p-top-mv img {
    width: 100vw;
    height: 100vh;
    object-fit: cover; }
  .p-top-mv__ttl {
    font-size: 120px;
    font-size: 12rem;
    line-height: 1.08;
    text-shadow: 0px 15px 8px rgba(8, 1, 3, 0.48);
    color: #fff;
    margin: 0 auto;
    padding: 0 30px;
    position: absolute;
    right: 40px;
    bottom: 97px; }
    @media screen and (max-width: 834px) {
      .p-top-mv__ttl {
        font-size: 110px;
        font-size: 11rem; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-mv__ttl {
        font-size: 14.5vw;
        padding: 0 15px;
        right: auto;
        bottom: 87.5px;
        left: 0; } }
    .p-top-mv__ttl span {
      opacity: 0;
      display: inline-block;
      transform: scale(2);
      transition: opacity .2s, transform .2s; }
      .p-top-mv__ttl span.is-animate {
        opacity: 1;
        transform: scale(1); }
      .p-top-mv__ttl span:nth-child(1) {
        transition-delay: 1s; }
      .p-top-mv__ttl span:nth-child(2) {
        transition-delay: 1.1s; }
      .p-top-mv__ttl span:nth-child(3) {
        transition-delay: 1.15s; }
      .p-top-mv__ttl span:nth-child(4) {
        transition-delay: 1.2s; }
      .p-top-mv__ttl span:nth-child(5) {
        transition-delay: 1.25s; }
      .p-top-mv__ttl span:nth-child(6) {
        transition-delay: 1.3s; }
      .p-top-mv__ttl span:nth-child(7) {
        transition-delay: 1.35s; }
      .p-top-mv__ttl span:nth-child(8) {
        transition-delay: 1.4s; }
      .p-top-mv__ttl span:nth-child(9) {
        transition-delay: 1.45s; }
      .p-top-mv__ttl span:nth-child(10) {
        transition-delay: 1.5s; }
      .p-top-mv__ttl span:nth-child(11) {
        transition-delay: 1.55s; }
      .p-top-mv__ttl span:nth-child(12) {
        transition-delay: 1.6s; }
      .p-top-mv__ttl span:nth-child(13) {
        transition-delay: 1.65s; }
      .p-top-mv__ttl span:nth-child(14) {
        transition-delay: 1.7s; }
      .p-top-mv__ttl span:nth-child(15) {
        transition-delay: 1.75s; }
      .p-top-mv__ttl span:nth-child(16) {
        transition-delay: 1.8s; }
      .p-top-mv__ttl span:nth-child(17) {
        transition-delay: 1.85s; }
      .p-top-mv__ttl span:nth-child(18) {
        transition-delay: 1.9s; }
      .p-top-mv__ttl span:nth-child(19) {
        transition-delay: 1.95s; }
      .p-top-mv__ttl span:nth-child(20) {
        transition-delay: 2s; }
      .p-top-mv__ttl span:nth-child(21) {
        transition-delay: 2.05s; }

.p-top-news {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 120px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-top-news {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-top-news {
      margin-top: 70px; } }
  .p-top-news__inner {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 3.25vw 0 4.875vw;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-news__inner {
        padding: 0;
        flex-direction: column; } }
  .p-top-news__ttl {
    width: 93px;
    margin: 30px 3.625vw 0 0; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-news__ttl {
        width: 100%;
        margin: 0 0 35px 0; } }
    .p-top-news__ttl__main {
      width: 1em;
      font-size: 36px;
      font-size: 3.6rem;
      line-height: 1; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-news__ttl__main {
          width: 100%;
          font-size: 30px;
          font-size: 3rem;
          text-align: center; } }
    .p-top-news__ttl__sub {
      font-size: 24px;
      font-size: 2.4rem;
      text-align: right;
      color: #004ea2;
      margin-top: 25px;
      padding-top: 20px;
      position: relative; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-news__ttl__sub {
          font-size: 13px;
          font-size: 1.3rem;
          text-align: center;
          margin-top: 15px;
          padding-top: 0; } }
      .p-top-news__ttl__sub:before {
        content: "";
        width: 105px;
        height: 1px;
        background: #004ea2;
        position: absolute;
        top: 0;
        left: -17px;
        display: inline-block;
        transform: rotate(-45deg); }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-top-news__ttl__sub:before {
            content: none; } }
  .p-top-news .p-news__list {
    width: 86.96%;
    flex: 1; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-news .p-news__list {
        width: 100%; } }
  .p-top-news__link {
    margin-top: 80px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-news__link {
        margin-top: 40px; } }

.p-top-recruit {
  height: 400px;
  margin-top: 120px;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .p-top-recruit {
      height: auto;
      margin-top: 70px; } }
  .p-top-recruit__list {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (max-width: 1024px) {
      .p-top-recruit__list {
        height: auto; } }
    .p-top-recruit__list li {
      width: 18.75%;
      height: 100%;
      position: relative;
      overflow: hidden; }
      .p-top-recruit__list li:nth-child(odd) {
        background: #f2f6fa; }
      .p-top-recruit__list li:nth-child(even) {
        background: #d9e5f1; }
      .p-top-recruit__list li:nth-child(3) {
        margin-left: auto; }
      @media screen and (max-width: 1024px) {
        .p-top-recruit__list li {
          flex: 1; }
          .p-top-recruit__list li:first-child {
            display: none; } }
      .p-top-recruit__list li img {
        max-width: unset;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%); }
        @media screen and (max-width: 1024px) {
          .p-top-recruit__list li img {
            width: 100%;
            position: static;
            transform: translateX(0); } }
  .p-top-recruit__center {
    width: 25%;
    min-width: 350px;
    height: 100%;
    text-align: center;
    color: #fff;
    background: #004ea2;
    margin: 0 auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    @media screen and (max-width: 1024px) {
      .p-top-recruit__center {
        width: 100%;
        min-width: unset;
        height: auto;
        padding: 50px 0;
        position: static; } }
    .p-top-recruit__center__ttl {
      line-height: .6; }
      .p-top-recruit__center__ttl__sub {
        font-size: 14px;
        font-size: 1.4rem; }
    .p-top-recruit__center__txt {
      font-size: 15px;
      font-size: 1.5rem;
      margin: 35px 0; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-recruit__center__txt {
          margin: 20px 0 30px; } }

.p-top-results {
  color: #fff;
  background: #201f1f;
  margin-top: 90px; }
  @media screen and (max-width: 1024px) {
    .p-top-results {
      margin-top: 0; } }
  .p-top-results__list {
    height: 760px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    overflow: hidden; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-results__list {
        height: auto;
        flex-direction: column; } }
    .p-top-results__list__main {
      width: 62.5%;
      height: 100%;
      position: relative; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-results__list__main {
          width: 100%;
          height: 304px;
          overflow: hidden; } }
      .p-top-results__list__main li {
        height: 100%; }
        .p-top-results__list__main li:not(:first-child) {
          display: none; }
        .p-top-results__list__main li a {
          width: 100%;
          height: 100%;
          color: #fff;
          display: inline-block;
          transition: opacity .3s; }
          .p-top-results__list__main li a:hover {
            opacity: .5; }
          .p-top-results__list__main li a > p {
            height: 100%; }
            .p-top-results__list__main li a > p img {
              width: 100%;
              height: 100%;
              object-fit: cover; }
          .p-top-results__list__main li a > div {
            position: absolute;
            bottom: 7.11%;
            left: 16%; }
            @media screen and (max-width: 1024px) {
              .p-top-results__list__main li a > div {
                bottom: 25px;
                left: 15px; } }
      .p-top-results__list__main__ttl {
        font-size: 34px;
        font-size: 3.4rem;
        text-shadow: 0px 4px 5px #080103; }
        @media screen and (max-width: 1024px) {
          .p-top-results__list__main__ttl {
            font-size: 25px;
            font-size: 2.5rem; } }
      .p-top-results__list__main__completion {
        font-size: 20px;
        font-size: 2rem;
        text-shadow: 0px 4px 5px #080103;
        margin-top: 10px; }
        @media screen and (max-width: 1024px) {
          .p-top-results__list__main__completion {
            font-size: 15px;
            font-size: 1.5rem;
            margin-top: 5px; } }
    .p-top-results__list__thumb {
      width: 37.5%;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-results__list__thumb {
          width: 100%; } }
      .p-top-results__list__thumb li {
        width: 50%;
        height: 190.25px;
        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;
        cursor: pointer; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-top-results__list__thumb li {
            width: 25%;
            height: 64px; }
            .p-top-results__list__thumb li:nth-child(4n+1) {
              border-left: none; }
            .p-top-results__list__thumb li:nth-child(n+5) {
              border-bottom: none; } }
        .p-top-results__list__thumb li img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: .4;
          transition: opacity .3s; }
        .p-top-results__list__thumb li.is-current img {
          opacity: 1; }
        @media print, screen and (min-width: 1025px) {
          .p-top-results__list__thumb li:hover img {
            opacity: 1; } }
  .p-top-results__box {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 60px;
    padding-bottom: 60px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-results__box {
        padding-right: 15px;
        padding-left: 15px; } }
    @media screen and (max-width: 1024px) {
      .p-top-results__box {
        padding-top: 40px;
        padding-bottom: 50px; } }
  .p-top-results__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-top-results__head {
    border-bottom: 1px solid #fff;
    margin-bottom: 50px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .p-top-results__head {
        margin-bottom: 25px;
        padding-bottom: 0; } }
    .p-top-results__head__ttl .c-ttl__sub {
      margin-left: 30px; }
      @media screen and (max-width: 1024px) {
        .p-top-results__head__ttl .c-ttl__sub {
          margin-left: 12px; } }
    .p-top-results__head__link {
      font-size: 16px;
      font-size: 1.6rem; }
      @media screen and (max-width: 1024px) {
        .p-top-results__head__link {
          font-size: 14px;
          font-size: 1.4rem;
          margin-bottom: -25px; } }
      .p-top-results__head__link a {
        color: #fff;
        display: inline-block; }
        .p-top-results__head__link a:after {
          content: "＞";
          font-size: 12px;
          font-size: 1.2rem;
          margin-left: 1em;
          display: inline-block; }
          @media screen and (max-width: 1024px) {
            .p-top-results__head__link a:after {
              font-size: 10px;
              font-size: 1rem; } }
        .p-top-results__head__link a:hover {
          border-bottom: 1px solid #fff; }
  .p-top-results__search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (max-width: 1024px) {
      .p-top-results__search {
        flex-direction: column;
        align-items: flex-start; } }
    .p-top-results__search__ttl {
      font-size: 16px;
      font-size: 1.6rem;
      margin-right: 30px;
      padding-right: 45px;
      position: relative; }
      @media screen and (max-width: 1024px) {
        .p-top-results__search__ttl {
          font-size: 15px;
          font-size: 1.5rem;
          margin-right: 20px;
          padding-right: 20px; } }
      .p-top-results__search__ttl:after {
        content: "";
        width: 1px;
        height: 48px;
        background: #fff;
        margin: auto 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: inline-block;
        transform: rotate(30deg); }
    .p-top-results__search__cat {
      width: 32.81%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start; }
      @media screen and (max-width: 1245px) {
        .p-top-results__search__cat {
          width: 41.81%; } }
      @media screen and (max-width: 1024px) {
        .p-top-results__search__cat {
          width: 100%; } }
      .p-top-results__search__cat__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1; }
        .p-top-results__search__cat__list li {
          width: 50%;
          font-size: 15px;
          font-size: 1.5rem; }
          @media screen and (max-width: 1024px) {
            .p-top-results__search__cat__list li {
              font-size: 14px;
              font-size: 1.4rem; } }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-top-results__search__cat__list li {
              font-size: 13px;
              font-size: 1.3rem; } }
          .p-top-results__search__cat__list li:nth-child(n+3) {
            margin-top: 10px; }
          .p-top-results__search__cat__list li label {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start; }
            .p-top-results__search__cat__list li label input {
              margin: 0 4px 0 0; }
    .p-top-results__search__keywords {
      width: 32.81%;
      margin-left: 6.25%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start; }
      @media screen and (max-width: 1024px) {
        .p-top-results__search__keywords {
          width: 100%;
          margin: 30px 0 0 0; } }
      .p-top-results__search__keywords__area {
        width: 100%;
        max-width: 224px;
        flex: 1; }
        @media screen and (max-width: 1024px) {
          .p-top-results__search__keywords__area {
            max-width: unset; } }
        .p-top-results__search__keywords__area input {
          appearance: none;
          width: 100%;
          height: 28px;
          border: none;
          padding: 1px;
          border-radius: 0; }
    .p-top-results__search__submit {
      width: 18.36%;
      font-size: 15px;
      font-size: 1.5rem;
      color: #fff;
      background: #201f1f;
      border: 1px solid #fff;
      margin-left: auto;
      position: relative;
      z-index: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
      transition: color .3s; }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .p-top-results__search__submit {
          width: 235px;
          margin: 35px auto 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-results__search__submit {
          width: 60%;
          margin: 35px auto 0; } }
      .p-top-results__search__submit:before {
        content: "";
        width: 17px;
        height: 17px;
        background: url(/cms/wp-content/themes/tada-con/images/common/cmn-search-ico.svg) 0 0 no-repeat;
        background-size: 100% 100%;
        display: inline-block;
        transition: background .3s; }
        @media screen and (max-width: 1024px) {
          .p-top-results__search__submit:before {
            width: 12px;
            height: 12px; } }
      .p-top-results__search__submit:after {
        content: "";
        width: 120%;
        height: 100%;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform-origin: right top;
        transform: skewX(-30deg) scale(0, 1);
        transition: transform .3s; }
      .p-top-results__search__submit span {
        font-family: 'Noto Sans CJK JP', 'Noto Sans JP', sans-serif;
        margin-left: 20px;
        padding: 13px 0;
        display: inline-block; }
        @media screen and (max-width: 1024px) {
          .p-top-results__search__submit span {
            margin-left: 8px; } }
      .p-top-results__search__submit:hover {
        color: #201f1f; }
        .p-top-results__search__submit:hover:before {
          background-image: url(/cms/wp-content/themes/tada-con/images/common/cmn-search-ico02.svg); }
        .p-top-results__search__submit:hover:after {
          transform-origin: left top;
          transform: skewX(-30deg) scale(1, 1); }

.p-top-methods {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start; }
  @media screen and (max-width: 1024px) {
    .p-top-methods {
      margin-top: 60px;
      flex-direction: column; } }
  .p-top-methods > div {
    width: 36.06%;
    min-width: 418px;
    padding: 0 2.88% 0 10.13%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column; }
    @media screen and (max-width: 1024px) {
      .p-top-methods > div {
        width: 100%;
        padding: 0 15px;
        min-width: unset; } }
  @media screen and (max-width: 1024px) {
    .p-top-methods__ttl {
      margin-left: 80px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-top-methods__ttl {
      margin-left: 65px; } }
  .p-top-methods__ttl:before, .p-top-methods__ttl:after {
    content: "";
    background: #004ea2;
    position: absolute;
    display: inline-block; }
  .p-top-methods__ttl:before {
    width: 312px;
    height: 1px;
    top: 97px;
    left: 0; }
    @media screen and (max-width: 1024px) {
      .p-top-methods__ttl:before {
        width: 155px;
        top: 71px; } }
  .p-top-methods__ttl:after {
    width: 1px;
    height: 155px;
    top: 20px;
    left: 16.32%; }
    @media screen and (max-width: 1024px) {
      .p-top-methods__ttl:after {
        height: 79px;
        top: 33px;
        left: 45px; } }
  .p-top-methods__sub {
    font-size: 60px;
    font-size: 6rem;
    line-height: 1.17;
    text-align: center;
    margin-top: 60px; }
    @media screen and (max-width: 1024px) {
      .p-top-methods__sub {
        width: 100%;
        font-size: 40px;
        font-size: 4rem;
        margin-top: 50px; } }
    .p-top-methods__sub:before, .p-top-methods__sub:after {
      content: "";
      background: #004ea2;
      position: absolute;
      display: inline-block; }
    .p-top-methods__sub:before {
      width: 312px;
      height: 1px;
      top: 366px;
      right: 0; }
      @media screen and (max-width: 1024px) {
        .p-top-methods__sub:before {
          width: 155px;
          top: 258px; } }
    .p-top-methods__sub:after {
      width: 1px;
      height: 155px;
      top: 289px;
      right: 69px; }
      @media screen and (max-width: 1024px) {
        .p-top-methods__sub:after {
          height: 79px;
          top: 218px;
          right: 39px; } }
  .p-top-methods__lead {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2.13;
    margin-top: 190px; }
    @media screen and (max-width: 1024px) {
      .p-top-methods__lead {
        margin-top: 110px; } }
  .p-top-methods__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start;
    flex: 1; }
    @media screen and (max-width: 1024px) {
      .p-top-methods__list {
        margin-top: 50px;
        padding: 0 15px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-methods__list {
        width: 100%;
        flex-direction: column;
        flex: unset; } }
    .p-top-methods__list li {
      width: 272px;
      height: 600px;
      margin-right: 24px;
      overflow: hidden; }
      @media screen and (max-width: 1368px) {
        .p-top-methods__list li {
          width: 31%;
          margin-right: 2.78%; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-methods__list li {
          width: 100%;
          height: auto;
          margin-right: 0; } }
      .p-top-methods__list li:nth-child(2) {
        margin-top: 28px; }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .p-top-methods__list li:nth-child(2) {
            margin-top: 0; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-top-methods__list li:nth-child(2) {
            margin-top: 40px; } }
      .p-top-methods__list li:last-child {
        margin-top: 56px;
        margin-right: 0; }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .p-top-methods__list li:last-child {
            margin-top: 0; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-top-methods__list li:last-child {
            margin-top: 40px; } }
      .p-top-methods__list li a {
        width: 100%;
        height: 100%;
        color: #fff;
        position: relative;
        display: inline-block; }
        .p-top-methods__list li a img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        @media print, screen and (min-width: 1025px) {
          .p-top-methods__list li a:hover .p-top-methods__list__cv:before {
            transform-origin: left top;
            transform: skewY(-30deg) scale(1, 1); }
          .p-top-methods__list li a:hover .p-top-methods__list__cv__ttl {
            margin-top: 144px; }
          .p-top-methods__list li a:hover .p-top-methods__list__cv__lead {
            opacity: 1;
            visibility: visible; }
          .p-top-methods__list li a:hover .p-top-methods__list__cv__num {
            opacity: 0;
            visibility: hidden; }
          .p-top-methods__list li a:hover .p-top-methods__list__cv__hov-txt {
            opacity: 1;
            visibility: visible; } }
    .p-top-methods__list__cv {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      padding: 0 9.19%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start;
      flex-direction: column; }
      @media print, screen and (min-width: 1025px) {
        .p-top-methods__list__cv:before {
          content: "";
          position: absolute;
          top: 0;
          left: -5%;
          z-index: -1;
          width: 110%;
          height: 180%;
          background: rgba(0, 36, 144, 0.75);
          display: inline-block;
          transform-origin: left bottom;
          transform: skewY(-30deg) scale(1, 0);
          transition: transform .5s; } }
      @media screen and (max-width: 1024px) {
        .p-top-methods__list__cv {
          align-items: center;
          justify-content: center; } }
      .p-top-methods__list__cv__ttl {
        font-size: 36px;
        font-size: 3.6rem;
        line-height: 1.11;
        text-align: center;
        text-shadow: -3.1px 2.5px 9px #080103;
        margin-top: 210px;
        transition: margin-top .5s; }
        @media screen and (max-width: 1219px) {
          .p-top-methods__list__cv__ttl {
            font-size: 2.875vw; } }
        @media screen and (max-width: 1024px) {
          .p-top-methods__list__cv__ttl {
            font-size: 26px;
            font-size: 2.6rem;
            line-height: 1.33;
            margin-top: 0; } }
      .p-top-methods__list__cv__lead {
        font-size: 14px;
        font-size: 1.4rem;
        text-align: justify;
        margin-top: 70px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .5s, visibility .5s; }
        @media screen and (max-width: 1219px) {
          .p-top-methods__list__cv__lead {
            font-size: 1.125vw; } }
        @media screen and (max-width: 1024px) {
          .p-top-methods__list__cv__lead {
            display: none; } }
      .p-top-methods__list__cv__num {
        width: 100%;
        font-size: 30px;
        font-size: 3rem;
        line-height: .9;
        color: #fff;
        padding-right: 10px;
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        transition: opacity .5s, visibility .5s; }
        @media screen and (max-width: 1219px) {
          .p-top-methods__list__cv__num {
            font-size: 2.5vw; } }
        @media screen and (max-width: 1024px) {
          .p-top-methods__list__cv__num {
            font-size: 18px;
            font-size: 1.8rem;
            padding-right: 7px; } }
        .p-top-methods__list__cv__num span {
          font-size: 120px;
          font-size: 12rem;
          margin-left: 5.51%;
          display: inline-block; }
          @media screen and (max-width: 1219px) {
            .p-top-methods__list__cv__num span {
              font-size: 8.125vw; } }
          @media screen and (max-width: 1024px) {
            .p-top-methods__list__cv__num span {
              font-size: 73px;
              font-size: 7.3rem;
              margin-left: 12px; } }
      .p-top-methods__list__cv__hov-txt {
        width: 57.35%;
        font-size: 16px;
        font-size: 1.6rem;
        text-align: right;
        position: absolute;
        right: 11.03%;
        bottom: 50px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .5s, visibility .5s; }
        @media screen and (max-width: 1219px) {
          .p-top-methods__list__cv__hov-txt {
            font-size: 1.125vw; } }
        .p-top-methods__list__cv__hov-txt:before {
          content: "";
          width: 38.96%;
          height: 1px;
          background: #fff;
          margin: auto 0;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          display: inline-block; }

.p-top-noble {
  padding-right: 30px;
  padding-left: 30px;
  color: #fff;
  background: url(/cms/wp-content/themes/tada-con/images/top-noble-bg-pc.jpg) center top no-repeat;
  background-size: cover;
  margin-top: 120px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-top-noble {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-top-noble {
      background-image: url(/cms/wp-content/themes/tada-con/images/top-noble-bg-sp.jpg);
      margin-top: 70px; } }
  .p-top-noble__inner {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-noble__inner {
        padding-top: 60px;
        padding-bottom: 70px; } }
  .p-top-noble__ttl {
    font-size: 46px;
    font-size: 4.6rem;
    text-align: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-noble__ttl {
        font-size: 30px;
        font-size: 3rem; } }
  .p-top-noble__lead {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2.13;
    text-align: center;
    margin-top: 25px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-noble__lead {
        text-align: left; } }
  .p-top-noble__list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-noble__list {
        flex-direction: column;
        align-items: center; } }
    .p-top-noble__list li {
      width: 32.08%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-noble__list li {
          width: 90.91%;
          text-align: center; } }
      .p-top-noble__list li + li {
        margin-left: 1.88%; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-top-noble__list li + li {
            margin: 40px 0 0 0; } }
  .p-top-noble__link {
    margin-top: 60px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-noble__link {
        margin-top: 40px; } }

.p-top-bottom {
  margin: 90px 0 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-top-bottom {
      margin: 50px 0 70px; } }
  .p-top-bottom__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-top-bottom__list {
        flex-direction: column; } }
    .p-top-bottom__list li {
      width: 50%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-bottom__list li {
          width: 100%; }
          .p-top-bottom__list li + li {
            margin-top: 30px; } }
      .p-top-bottom__list li a {
        width: 100%;
        display: inline-block;
        transition: opacity .3s; }
        .p-top-bottom__list li a:hover {
          opacity: .5; }
        .p-top-bottom__list li a img {
          width: 100%; }
    .p-top-bottom__list__ttl {
      margin: 0 0 15px 40px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-top-bottom__list__ttl {
          margin: 0 0 0 15px; } }

.p-page-link {
  max-width: 1340px;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 80px;
  padding: 0 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-page-link {
      margin-top: 60px;
      margin-bottom: 40px;
      padding: 0 15px; } }
  .p-page-link li {
    width: 21.09%;
    margin: 0 4.69% 0 0; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-page-link li {
        width: 32.43%;
        margin: 0 1.35% 0 0; } }
    .p-page-link li:last-child {
      margin-right: 0; }
    .p-page-link li a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      .p-page-link li a span {
        width: 85%;
        line-height: 1.25;
        padding-right: 6%;
        position: relative;
        display: inline-block; }
        .p-page-link li a span:after {
          content: "";
          width: 8px;
          height: 8px;
          border-right: 1px solid #201f1f;
          border-bottom: 1px solid #201f1f;
          margin: auto 0;
          position: absolute;
          top: -4px;
          right: 0;
          bottom: 0;
          display: inline-block;
          transform: rotate(45deg);
          transition: border-color 0.3s; }
      .p-page-link li a:hover span:after {
        border-color: #fff; }
  .p-page-link__current a {
    color: #fff; }
    .p-page-link__current a:after {
      transform: scale(1, 1); }

.p-page--technique .p-page-link {
  margin-top: 40px; }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .p-page--technique .p-page-link {
      justify-content: space-between; } }
  .p-page--technique .p-page-link li {
    width: 21.88%; }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .p-page--technique .p-page-link li {
        width: 33%;
        margin: 0; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-page--technique .p-page-link li {
        width: 100%; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-page--technique .p-page-link li + li {
        margin-top: 20px; } }

.p-page--privacy .l-pagebody, .p-page--use .l-pagebody {
  margin-top: 165px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-page--privacy .l-pagebody, .p-page--use .l-pagebody {
      margin-top: 120px; } }

.p-company {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-company {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-company {
      margin-bottom: 70px; } }
  .p-company__lead {
    font-size: 48px;
    font-size: 4.8rem;
    font-weight: 500;
    text-align: center;
    margin: 80px 0 65px; }
    @media screen and (max-width: 811px) {
      .p-company__lead {
        font-size: 45px;
        font-size: 4.5rem; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-company__lead {
        font-size: 32px;
        font-size: 3.2rem;
        line-height: 1.2;
        margin: 70px 0 35px; } }
  .p-company__list-top {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-company__list-top {
        flex-direction: column; } }
    .p-company__list-top li {
      width: 50%;
      position: relative; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-company__list-top li {
          width: 100%; } }
      .p-company__list-top li > div {
        position: relative; }
      .p-company__list-top li a {
        width: 100%;
        height: 100%;
        font-size: 32px;
        font-size: 3.2rem;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        text-shadow: 0px 4px 51px #080103, 0px 4px 51px #080103;
        background: rgba(0, 66, 136, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        transition: background 0.3s; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-company__list-top li a {
            font-size: 30px;
            font-size: 3rem; } }
        .p-company__list-top li a:hover {
          background: rgba(0, 0, 0, 0.7); }
  .p-company__list-bottom {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    .p-company__list-bottom li {
      width: 25%;
      position: relative; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-company__list-bottom li {
          width: 50%; } }
      .p-company__list-bottom li a {
        width: 100%;
        height: 100%;
        font-size: 28px;
        font-size: 2.8rem;
        color: #fff;
        font-weight: 500;
        line-height: 1.3;
        text-align: center;
        color: #fff;
        text-shadow: 0px 4px 51px #080103, 0px 4px 51px #080103;
        background: rgba(0, 66, 136, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        transition: background 0.3s; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-company__list-bottom li a {
            font-size: 24px;
            font-size: 2.4rem; } }
        .p-company__list-bottom li a:hover {
          background: rgba(0, 0, 0, 0.7); }

.p-greetings {
  padding-right: 30px;
  padding-left: 30px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-greetings {
      padding-right: 15px;
      padding-left: 15px; } }
  .p-greetings__inner {
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .p-greetings__inner {
        flex-direction: column; } }
  .p-greetings__left {
    width: 71.48%;
    padding-bottom: 130px; }
    @media screen and (max-width: 1024px) {
      .p-greetings__left {
        width: 100%;
        padding-bottom: 0; } }
  .p-greetings__lead {
    font-size: 52px;
    font-size: 5.2rem;
    font-weight: 500;
    margin-bottom: 60px; }
    @media screen and (max-width: 1024px) {
      .p-greetings__lead {
        font-size: 50px;
        font-size: 5rem; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-greetings__lead {
        font-size: 34px;
        font-size: 3.4rem;
        margin-bottom: 40px; }
        .p-greetings__lead br {
          display: none; } }
  .p-greetings__txt {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 2.25; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-greetings__txt {
        font-size: 14px;
        font-size: 1.4rem; } }
    .p-greetings__txt + .p-greetings__txt {
      margin-top: 4.5em; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-greetings__txt + .p-greetings__txt {
          margin-top: 2.5em; } }
  .p-greetings__regards {
    font-size: 16px;
    font-size: 1.6rem;
    text-align: right;
    margin-top: 2.25em; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-greetings__regards {
        font-size: 14px;
        font-size: 1.4rem;
        margin-top: 1.25em; } }
  .p-greetings__sign {
    text-align: right;
    margin-top: 80px; }
    @media screen and (max-width: 1024px) {
      .p-greetings__sign {
        text-align: left; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-greetings__sign {
        width: 80%;
        margin-top: 50px; } }
  .p-greetings__right {
    width: 22.97%; }
    @media screen and (max-width: 1024px) {
      .p-greetings__right {
        width: 100%;
        text-align: right; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-greetings__right {
        margin-top: 30px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-greetings__right img {
        width: 147px; } }

.p-profile {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 130px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-profile {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-profile {
      margin-bottom: 60px; } }
  .p-profile__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-profile__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: 500;
    margin: 100px 0 15px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-profile__ttl {
        font-size: 22px;
        font-size: 2.2rem;
        margin-top: 60px; } }
  .p-profile .c-table tr td a {
    color: #004ea2; }
    .p-profile .c-table tr td a[target="_blank"]:after {
      content: "";
      width: 17px;
      height: 17px;
      vertical-align: text-bottom;
      background: url(/cms/wp-content/themes/tada-con/images/common/cmn-blank-ico01.svg) 0 0 no-repeat;
      background-size: 100% 100%;
      margin-left: 5px;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-profile .c-table tr td a[target="_blank"]:after {
          width: 14px;
          height: 14px; } }
    .p-profile .c-table tr td a:hover {
      text-decoration: underline; }
  .p-profile__memberlist li span {
    min-width: 23em;
    display: inline-block; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-profile__memberlist li span {
        min-width: unset;
        display: block; } }
  .p-profile__access__map {
    margin-bottom: 40px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-profile__access__map {
        margin-bottom: 30px; } }
    .p-profile__access__map iframe {
      width: 100%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-profile__access__map iframe {
          height: 300px; } }
  .p-profile__access__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-profile__access__list {
        flex-direction: column; } }
    .p-profile__access__list li + li {
      margin-left: 6.25vw; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-profile__access__list li + li {
          margin: 30px 0 0 0; } }
    .p-profile__access__list dl dt {
      font-size: 20px;
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 0.3em; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-profile__access__list dl dt {
          font-size: 18px;
          font-size: 1.8rem; } }
    .p-profile__access__list dl dd {
      font-size: 15px;
      font-size: 1.5rem;
      line-height: 2.13; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-profile__access__list dl dd {
          font-size: 13px;
          font-size: 1.3rem; } }
    .p-profile__access__list__txt {
      text-indent: -10.3em;
      padding-left: 10.3em; }

.p-management {
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  background: url(/cms/wp-content/themes/tada-con/images/company/management/management-bg-pc.png) center top no-repeat;
  background-size: cover;
  margin-top: 55px;
  padding-top: 70px;
  padding-bottom: 840px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-management {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-management {
      background: url(/cms/wp-content/themes/tada-con/images/company/management/management-bg-sp.png) center bottom no-repeat;
      background-size: cover;
      margin-top: 80px;
      padding-top: 0;
      padding-bottom: 483px; } }
  .p-management section:first-child .p-management__ttl {
    margin-top: 0; }
  @media screen and (max-width: 956px) {
    .p-management section:nth-child(2) .p-management__lead {
      text-align: center; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-management section:nth-child(2) .p-management__lead {
      font-size: 22px;
      font-size: 2.2rem; } }
  .p-management__main__lead {
    font-size: 53px;
    font-size: 5.3rem;
    font-weight: 500; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-management__main__lead {
        font-size: 32px;
        font-size: 3.2rem; } }
  .p-management__main__txt {
    font-size: 22px;
    font-size: 2.2rem;
    font-weight: 500;
    margin-top: 0.5em; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-management__main__txt {
        font-size: 16px;
        font-size: 1.6rem; } }
  .p-management__ttl {
    font-size: 30px;
    font-size: 3rem;
    margin: 90px 0 20px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-management__ttl {
        font-size: 25px;
        font-size: 2.5rem;
        margin-top: 60px; } }
    .p-management__ttl span {
      font-weight: 500;
      border-bottom: 1px solid #201f1f;
      display: inline-block; }
  .p-management__lead {
    font-size: 32px;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.88;
    font-weight: 500; }
    @media screen and (max-width: 956px) {
      .p-management__lead {
        text-align: left; }
        .p-management__lead br {
          display: none; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-management__lead {
        font-size: 20px;
        font-size: 2rem;
        line-height: 1.65; } }
    .p-management__lead span {
      font-weight: 500;
      display: inline-block; }
      .p-management__lead span + span {
        margin-left: 1em; }
      @media screen and (max-width: 956px) {
        .p-management__lead span:last-child {
          margin-left: 0;
          display: block; } }
  .p-management__txt {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2.13;
    margin-top: 20px; }
    @media screen and (max-width: 956px) {
      .p-management__txt {
        text-align: left; }
        .p-management__txt br {
          display: none; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-management__txt {
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 1.5; } }
  .p-management__note {
    font-size: 15px;
    font-size: 1.5rem;
    margin-top: 16px; }
    @media screen and (max-width: 956px) {
      .p-management__note {
        text-align: left; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-management__note {
        margin-top: 1.5em; } }

.p-establishment {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 130px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-establishment {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-establishment {
      margin-bottom: 60px; } }
  .p-establishment__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-establishment__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: 500;
    margin: 100px 0 15px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-establishment__ttl {
        font-size: 22px;
        font-size: 2.2rem;
        margin-top: 60px; } }
  .p-establishment .c-table tr {
    background: #fff; }
    .p-establishment .c-table tr:first-child {
      background: #f9f9f9; }
    .p-establishment .c-table tr.c-table--bg {
      background: #f9f9f9; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-establishment .c-table tr th {
        width: 100%;
        padding: 20px 10px 5px;
        display: block; } }
    @media print, screen and (min-width: 1025px) {
      .p-establishment .c-table tr th.c-table--ind01 {
        padding-left: calc(15px + 1em); } }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .p-establishment .c-table tr th.c-table--ind01 {
        padding-left: calc(15px + 1em); } }
    @media print, screen and (min-width: 1025px) {
      .p-establishment .c-table tr th.c-table--ind02 {
        padding-left: calc(15px + 2em); } }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .p-establishment .c-table tr th.c-table--ind02 {
        padding-left: calc(15px + 2em); } }
    .p-establishment .c-table tr td {
      padding-right: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-establishment .c-table tr td {
          width: 100%;
          padding: 0 10px 20px;
          display: block; } }
      .p-establishment .c-table tr td address {
        margin-right: 30px;
        flex: 1; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-establishment .c-table tr td address {
            margin: 0 0 10px 0; } }
  .p-establishment .c-table + table {
    margin-top: 80px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-establishment .c-table + table {
        margin-top: 40px; } }
  .p-establishment__link {
    margin-left: auto; }
    .p-establishment__link a:hover {
      text-decoration: underline; }
  .p-establishment__relation .c-table tr {
    background: #f9f9f9 !important; }
    .p-establishment__relation .c-table tr th span {
      text-indent: -5em;
      padding-left: 5em;
      display: inline-block; }

.p-organizational {
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 70px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-organizational {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-organizational {
      margin-top: 60px; } }
  .p-organizational__link {
    margin-top: 80px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-organizational__link {
        margin-top: 40px; } }

.p-history {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 80px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-history {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-history {
      margin-top: 60px;
      margin-bottom: 70px; } }
  .p-history__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-history__table tr th {
    width: auto; }
    .p-history__table tr th:first-child {
      width: 15%;
      padding-left: 50px;
      word-break: break-word; }
      @media screen and (max-width: 1303px) {
        .p-history__table tr th:first-child {
          padding-left: 3.125vw; } }
      @media screen and (max-width: 1199px) {
        .p-history__table tr th:first-child {
          width: 24%; } }
    .p-history__table tr th:nth-child(2) {
      width: 6%; }
      @media screen and (max-width: 1199px) {
        .p-history__table tr th:nth-child(2) {
          width: 10%; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-history__table tr th:nth-child(2) {
          width: 16%; } }

.p-business {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 80px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-business {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-business {
      margin-top: 60px;
      margin-bottom: 70px;
      padding: 0; } }
  .p-business__inner {
    max-width: 1280px;
    margin: 0 auto; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__inner {
        padding: 0 15px; } }
  .p-business__ttl {
    font-size: 46px;
    font-size: 4.6rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__ttl {
        font-size: 30px;
        font-size: 3rem;
        line-height: 1.31;
        margin-bottom: 30px; } }
    @media print, screen and (min-width: 1025px) {
      .p-business__ttl br {
        display: none; } }
  .p-business__lead {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.78;
    text-align: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__lead {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.5;
        text-align: left; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__lead br {
        display: none; } }
  .p-business__list > li {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .p-business__list > li {
        display: block; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__list > li {
        margin-top: 70px; } }
    .p-business__list > li > p {
      margin-right: 5.78%;
      flex: 1; }
      @media screen and (max-width: 1024px) {
        .p-business__list > li > p {
          text-align: center;
          margin-right: 0;
          margin-bottom: 60px; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-business__list > li > p {
          width: 76.62%;
          margin: 0 auto 25px !important; } }
    .p-business__list > li > div {
      width: 49.3%;
      min-width: 510px; }
      @media screen and (max-width: 1024px) {
        .p-business__list > li > div {
          width: 100%;
          min-width: unset; } }
    .p-business__list > li:nth-child(even) {
      flex-direction: row-reverse; }
      .p-business__list > li:nth-child(even) > p {
        margin-right: 0;
        margin-left: 5.78%; }
  .p-business__list__sub {
    font-size: 26px;
    font-size: 2.6rem; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__list__sub {
        font-size: 15px;
        font-size: 1.5rem; } }
  .p-business__list__ttl {
    font-size: 56px;
    font-size: 5.6rem;
    margin-top: 15px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__list__ttl {
        font-size: 37px;
        font-size: 3.7rem;
        margin-top: 0; } }
    .p-business__list__ttl span {
      font-weight: 500;
      padding-right: 115px;
      position: relative;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-business__list__ttl span {
          padding-right: 57px; } }
      .p-business__list__ttl span:after {
        content: "";
        width: 80px;
        height: 1px;
        background: #004ea2;
        margin: auto 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: inline-block; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-business__list__ttl span:after {
            width: 40px; } }
  .p-business__list__lead {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2.13;
    margin-top: 25px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__list__lead {
        text-align: left;
        line-height: 1.5;
        margin-top: 12px; } }
  .p-business__list__cat {
    width: 79.69%;
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (max-width: 1220px) {
      .p-business__list__cat {
        width: 100%; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__list__cat {
        margin-top: 15px; } }
    .p-business__list__cat li {
      width: 23.53%;
      text-align: center;
      color: #fff;
      font-feature-settings: "palt";
      background: #201f1f;
      margin-right: 1.96%;
      padding: 3px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-business__list__cat li {
          width: 24%;
          font-size: 10px;
          font-size: 1rem;
          margin-right: 1.33%; } }
      .p-business__list__cat li:nth-child(4n) {
        margin-right: 0; }
      .p-business__list__cat li:nth-child(n + 5) {
        margin-top: 5px; }
  .p-business__list__link {
    margin: 60px 0 0 0; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__list__link {
        margin: 60px auto 0; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__list__link {
        margin-top: 40px; } }
  .p-business__page-list li {
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (max-width: 1024px) {
      .p-business__page-list li {
        display: block; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__page-list li {
        margin-top: 50px; } }
    .p-business__page-list li > div {
      flex: 1; }
    .p-business__page-list li > figure {
      width: 370px;
      margin-left: 4.84%;
      text-align: center; }
      @media screen and (max-width: 1024px) {
        .p-business__page-list li > figure {
          width: auto;
          max-width: 740px;
          margin: 60px auto 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-business__page-list li > figure {
          margin-top: 30px; } }
      .p-business__page-list li > figure figcaption {
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        text-align: left;
        margin-top: 20px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-business__page-list li > figure figcaption {
            font-size: 18px;
            font-size: 1.8rem;
            margin-top: 15px; } }
  .p-business__page-list__txt {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 2.13; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__page-list__txt {
        line-height: 1.5;
        text-align: left; } }
  .p-business__page-list__caption {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.67;
    text-align: left;
    display: inline-block; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__page-list__caption {
        font-size: 13px;
        font-size: 1.3rem;
        line-height: 1.38;
        margin-top: 5px; } }
  .p-business__page-list__link {
    margin: 40px 0 0 0; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-business__page-list__link {
        width: 70%;
        margin: 35px auto 0; } }

.p-building {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-building {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (max-width: 1024px) {
    .p-building {
      padding: 0; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-building {
      margin-bottom: 0; } }
  .p-building__inner {
    max-width: 1280px;
    margin: 0 auto; }
    @media screen and (max-width: 1024px) {
      .p-building__inner {
        padding: 0 30px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-building__inner {
        padding: 0 15px; } }
  .p-building__results {
    max-width: 1280px;
    margin: 0 auto;
    color: #fff;
    background: #201f1f;
    margin-top: 100px;
    padding: 55px 0; }
    @media screen and (max-width: 1024px) {
      .p-building__results {
        padding-right: 30px;
        padding-left: 30px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-building__results {
        margin-top: 60px;
        padding: 60px 15px 70px; } }
    .p-building__results .c-ttl {
      font-size: 40px;
      font-size: 4rem;
      margin-bottom: 12px;
      padding-left: 50px; }
      @media screen and (max-width: 1024px) {
        .p-building__results .c-ttl {
          padding-left: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-building__results .c-ttl {
          font-size: 44px;
          font-size: 4.4rem;
          margin-bottom: 10px; } }
    .p-building__results .p-business__page-list li {
      margin-top: 0; }
      .p-building__results .p-business__page-list li > figure {
        width: 36.33%;
        margin-right: 3.52%;
        margin-left: 2.97%; }
        @media screen and (max-width: 1024px) {
          .p-building__results .p-business__page-list li > figure {
            width: auto;
            max-width: 740px; } }
        .p-building__results .p-business__page-list li > figure figcaption {
          font-size: 20px;
          font-size: 2rem;
          font-weight: 500; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-building__results .p-business__page-list li > figure figcaption {
              font-size: 13px;
              font-size: 1.3rem; } }
    .p-building__results .p-business__page-list__txt {
      padding-left: 50px; }
      @media screen and (max-width: 1024px) {
        .p-building__results .p-business__page-list__txt {
          padding-left: 0; } }
      .p-building__results .p-business__page-list__txt + p {
        margin-top: 2.13em; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-building__results .p-business__page-list__txt + p {
            margin-top: 1.5em; } }
    .p-building__results .c-ttl02 {
      font-size: 40px;
      font-size: 4rem;
      border-bottom-color: #fff;
      margin-bottom: 20px;
      padding: 0 0 15px 50px; }
      @media screen and (max-width: 1024px) {
        .p-building__results .c-ttl02 {
          padding-left: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-building__results .c-ttl02 {
          font-size: 23px;
          font-size: 2.3rem; } }

.p-engineering {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-engineering {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-engineering {
      margin-bottom: 70px; } }
  .p-engineering__inner {
    max-width: 1280px;
    margin: 0 auto; }

.p-real-estate {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-real-estate {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-real-estate {
      margin-bottom: 70px; } }
  .p-real-estate__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-real-estate .p-business__page-list__link {
    width: 295px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-real-estate .p-business__page-list__link {
        width: 85%; } }
  .p-real-estate__bottom {
    font-size: 36px;
    font-size: 3.6rem;
    font-weight: 500;
    text-align: center;
    color: #004ea2;
    margin-top: 90px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-real-estate__bottom {
        font-size: 25px;
        font-size: 2.5rem;
        margin-top: 50px; } }

.p-noble {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-noble {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-noble {
      margin-bottom: 70px; } }
  .p-noble__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-noble__list {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-noble__list {
        margin-top: 50px; } }
    .p-noble__list li {
      width: 28.91%;
      text-align: center; }
      @media screen and (max-width: 1024px) {
        .p-noble__list li {
          width: 48%; } }
      .p-noble__list li figure figcaption {
        font-size: 24px;
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 15px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-noble__list li figure figcaption {
            font-size: 18px;
            font-size: 1.8rem; } }
        .p-noble__list li figure figcaption span {
          font-size: 15px;
          font-size: 1.5rem; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-noble__list li figure figcaption span {
              font-size: 13px;
              font-size: 1.3rem; } }
      .p-noble__list li:nth-child(n + 4) {
        margin-top: 70px; }
      @media screen and (max-width: 1024px) {
        .p-noble__list li:nth-child(n + 3) {
          margin-top: 70px; } }
  @media screen and (max-width: 1024px) and (min-width: 1px) and (max-width: 767px) {
    .p-noble__list li:nth-child(n + 3) {
      margin-top: 40px; } }
    .p-noble__list__caption {
      font-size: 15px;
      font-size: 1.5rem;
      text-align: left;
      margin-top: 15px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-noble__list__caption {
          font-size: 13px;
          font-size: 1.3rem; } }
  .p-noble__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: 500;
    margin: 100px 0 15px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-noble__ttl {
        font-size: 22px;
        font-size: 2.2rem;
        margin-top: 60px; } }

.p-technique {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 130px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-technique {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-technique {
      margin-bottom: 70px;
      padding: 0; } }
  .p-technique-link {
    max-width: 1340px;
    margin: 0 auto;
    margin-top: 55px;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique-link {
        margin-top: 60px; } }
    .p-technique-link li {
      font-size: 20px;
      font-size: 2rem; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique-link li {
          font-size: 16px;
          font-size: 1.6rem; } }
      .p-technique-link li a:hover {
        text-decoration: underline; }
      .p-technique-link li + li {
        margin-left: 40px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique-link li + li {
            margin-top: 5px;
            margin-left: 0; } }
  .p-technique__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-technique__head {
    color: #fff;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
    margin: 120px 0 72px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__head {
        margin: 70px 0 50px; } }
    .p-technique__head__inner {
      background: linear-gradient(to right, #000 30%, transparent 70%);
      padding: 20px 50px 16px; }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .p-technique__head__inner {
          background: linear-gradient(to right, #000 65%, transparent 70%); } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__head__inner {
          background: linear-gradient(to right, #000 40%, transparent 70%);
          padding: 21px 10px 18px; } }
    .p-technique__head__num {
      font-size: 30px;
      font-size: 3rem;
      line-height: 0.9;
      margin-left: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__head__num {
          font-size: 15px;
          font-size: 1.5rem; } }
      .p-technique__head__num span {
        font-size: 120px;
        font-size: 12rem;
        margin-left: 15px;
        display: inline-block; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__head__num span {
            font-size: 60px;
            font-size: 6rem; } }
    .p-technique__head__ttl {
      font-size: 48px;
      font-size: 4.8rem;
      font-weight: 500;
      line-height: 1.25;
      margin-top: 15px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__head__ttl {
          font-size: 28px;
          font-size: 2.8rem;
          line-height: 1.33;
          margin-top: 10px; } }
    .p-technique__head__lead {
      margin-top: 10px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__head__lead {
          font-size: 14px;
          font-size: 1.4rem; } }
  .p-technique__sec-ttl {
    margin-top: 85px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__sec-ttl {
        margin-top: 60px; } }
  .p-technique__col2 {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (max-width: 1024px) {
      .p-technique__col2 {
        display: block; } }
    .p-technique__col2__txt {
      width: 50.08%; }
      @media screen and (max-width: 1024px) {
        .p-technique__col2__txt {
          width: 100%; } }
    .p-technique__col2 figure {
      width: 42.11%;
      text-align: center; }
      @media screen and (max-width: 1024px) {
        .p-technique__col2 figure {
          width: 76.13%;
          margin: 30px auto 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__col2 figure {
          width: 100%;
          margin-top: 20px; } }
      .p-technique__col2 figure figcaption {
        font-size: 18px;
        font-size: 1.8rem;
        margin-top: 15px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__col2 figure figcaption {
            font-size: 13px;
            font-size: 1.3rem; } }
  .p-technique__case {
    margin-top: 30px; }
    .p-technique__case__ttl {
      font-size: 26px;
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 18px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__case__ttl {
          font-size: 18px;
          font-size: 1.8rem;
          margin-bottom: 12px; } }
    .p-technique__case__list {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start; }
      @media screen and (max-width: 1024px) {
        .p-technique__case__list {
          justify-content: space-between; } }
      .p-technique__case__list li {
        width: 22.66%;
        text-align: center;
        margin-right: 3.12%; }
        @media screen and (max-width: 1024px) {
          .p-technique__case__list li {
            width: 46.76%;
            margin-right: 0; } }
        @media screen and (max-width: 1024px) {
          .p-technique__case__list li:nth-child(n + 3) {
            margin-top: 40px; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__case__list li:nth-child(n + 3) {
            margin-top: 30px; } }
        .p-technique__case__list li:nth-child(4n) {
          margin-right: 0; }
      .p-technique__case__list__cat {
        margin-top: 18px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__case__list__cat {
            margin-top: 12px; } }
        .p-technique__case__list__cat li {
          width: 37.93%;
          font-size: 13px;
          font-size: 1.3rem;
          text-align: center;
          color: #004ea2;
          border: 1px solid #004ea2;
          margin-right: 10px;
          padding: 2px 0 1px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-technique__case__list__cat li {
              width: 42.21%;
              font-size: 10px;
              font-size: 1rem; } }
      .p-technique__case__list__name {
        font-size: 18px;
        font-size: 1.8rem;
        font-weight: 500;
        line-height: 1.4;
        text-align: left;
        margin-top: 5px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__case__list__name {
            font-size: 13px;
            font-size: 1.3rem; } }
      .p-technique__case__list__completion {
        font-size: 15px;
        font-size: 1.5rem;
        text-align: left; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__case__list__completion {
            font-size: 11px;
            font-size: 1.1rem; } }
    .p-technique__case__bottom {
      font-size: 16px;
      font-size: 1.6rem;
      margin-top: 22px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__case__bottom {
          font-size: 15px;
          font-size: 1.5rem;
          margin-top: 20px; } }
  .p-technique__manufacture {
    text-align: center;
    margin-top: 100px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__manufacture {
        padding-right: 30px;
        padding-left: 30px;
        margin-top: 50px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) and (min-width: 1px) and (max-width: 767px) {
    .p-technique__manufacture {
      padding-right: 15px;
      padding-left: 15px; } }
    .p-technique__manufacture__ttl {
      font-size: 48px;
      font-size: 4.8rem;
      font-weight: 500; }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .p-technique__manufacture__ttl {
          font-size: 44px;
          font-size: 4.4rem; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__manufacture__ttl {
          font-size: 25px;
          font-size: 2.5rem; } }
    .p-technique__manufacture__lead {
      margin-top: 30px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__manufacture__lead {
          text-align: left;
          margin-top: 15px; } }
    .p-technique__manufacture__list {
      margin-top: 70px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__manufacture__list {
          margin-top: 30px; } }
      .p-technique__manufacture__list li {
        margin: 0; }
        .p-technique__manufacture__list li + li {
          margin-left: 40px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-technique__manufacture__list li + li {
              margin-top: 15px;
              margin-left: 0; } }
  .p-technique__sec01 .p-technique__head {
    background-image: url(/cms/wp-content/themes/tada-con/images/technique/technique-sec01-bg-pc.jpg); }
    @media screen and (max-width: 1024px) {
      .p-technique__sec01 .p-technique__head {
        background-image: url(/cms/wp-content/themes/tada-con/images/technique/technique-sec01-bg-sp.jpg); } }
  .p-technique__sec01__img {
    width: 658px;
    margin-top: 45px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__sec01__img {
        width: 100%;
        margin-top: 25px; } }
  .p-technique__sec01__img-list {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__sec01__img-list {
        margin-top: 35px;
        display: block; } }
    .p-technique__sec01__img-list li {
      width: 36.77%;
      text-align: center; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__sec01__img-list li {
          width: 100%;
          margin-bottom: 30px; } }
      .p-technique__sec01__img-list li + li {
        width: 58.58%;
        margin-bottom: 0; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__sec01__img-list li + li {
            width: 100%; } }
    .p-technique__sec01__img-list__caption {
      font-size: 18px;
      font-size: 1.8rem;
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__sec01__img-list__caption {
          font-size: 13px;
          font-size: 1.3rem; } }
      .p-technique__sec01__img-list__caption span {
        width: 40%;
        text-align: center;
        display: inline-block; }
        .p-technique__sec01__img-list__caption span + span {
          width: 55%;
          margin-left: auto; }
  .p-technique__sec02 .p-technique__head {
    background-image: url(/cms/wp-content/themes/tada-con/images/technique/technique-sec02-bg-pc.jpg); }
    @media screen and (max-width: 1024px) {
      .p-technique__sec02 .p-technique__head {
        background-image: url(/cms/wp-content/themes/tada-con/images/technique/technique-sec02-bg-sp.jpg); } }
  .p-technique__sec02__img-list {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__sec02__img-list {
        margin-top: 0; } }
    .p-technique__sec02__img-list li {
      width: 45.31%;
      text-align: center; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__sec02__img-list li {
          width: 100%;
          margin-top: 30px; } }
      .p-technique__sec02__img-list li figure figcaption {
        font-size: 18px;
        font-size: 1.8rem;
        margin-top: 20px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__sec02__img-list li figure figcaption {
            font-size: 13px;
            font-size: 1.3rem;
            margin-top: 15px; } }
      .p-technique__sec02__img-list li + li {
        width: 50%; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__sec02__img-list li + li {
            width: 100%; } }
  .p-technique__sec02 .c-table {
    margin-top: 45px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__sec02 .c-table {
        margin-top: 30px; } }
    .p-technique__sec02 .c-table tr th {
      width: 32.4%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__sec02 .c-table tr th {
          width: 100%;
          padding: 30px 10px 15px;
          display: block; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__sec02 .c-table tr td {
        width: 100%;
        padding: 0 10px 30px;
        display: block; } }
  .p-technique__sec03 .p-technique__head {
    background-image: url(/cms/wp-content/themes/tada-con/images/technique/technique-sec03-bg-pc.jpg); }
    @media screen and (max-width: 1024px) {
      .p-technique__sec03 .p-technique__head {
        background-image: url(/cms/wp-content/themes/tada-con/images/technique/technique-sec03-bg-sp.jpg); } }
  .p-technique__sec03__img-list {
    margin-top: 45px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (max-width: 1097px) {
      .p-technique__sec03__img-list {
        justify-content: space-between; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__sec03__img-list {
        margin-top: 0; } }
    .p-technique__sec03__img-list li {
      text-align: center; }
      @media screen and (max-width: 1024px) {
        .p-technique__sec03__img-list li {
          width: 90.4%; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__sec03__img-list li {
          width: 100%;
          margin-top: 30px; } }
      .p-technique__sec03__img-list li figure figcaption {
        font-size: 18px;
        font-size: 1.8rem;
        margin-top: 20px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__sec03__img-list li figure figcaption {
            font-size: 13px;
            font-size: 1.3rem;
            margin-top: 15px; } }
      .p-technique__sec03__img-list li + li {
        margin-left: 9.38%; }
        @media screen and (max-width: 1097px) {
          .p-technique__sec03__img-list li + li {
            margin-left: 1.8%; } }
        @media screen and (max-width: 1024px) {
          .p-technique__sec03__img-list li + li {
            margin-top: 30px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-technique div[id^="sec0"] {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) and (min-width: 1px) and (max-width: 767px) {
    .p-technique div[id^="sec0"] {
      padding-right: 15px;
      padding-left: 15px; } }
  .p-technique__results {
    max-width: 1280px;
    margin: 0 auto;
    color: #fff;
    background: #201f1f;
    padding: 55px 0 70px;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .p-technique__results {
        padding-right: 30px;
        padding-left: 30px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-technique__results {
        margin-top: 60px;
        padding: 60px 15px 70px; } }
    .p-technique__results .c-ttl {
      font-size: 40px;
      font-size: 4rem;
      margin-bottom: 12px;
      padding-left: 50px; }
      @media screen and (max-width: 1024px) {
        .p-technique__results .c-ttl {
          padding-left: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__results .c-ttl {
          font-size: 44px;
          font-size: 4.4rem;
          margin-bottom: 10px; } }
    .p-technique__results__list {
      margin-bottom: 50px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__results__list {
          margin-bottom: 20px; } }
      .p-technique__results__list li {
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start; }
        @media screen and (max-width: 1024px) {
          .p-technique__results__list li {
            display: block; } }
        .p-technique__results__list li > div {
          flex: 1; }
        .p-technique__results__list li > figure {
          width: 36.33%;
          margin-right: 3.52%;
          margin-left: 2.97%; }
          @media screen and (max-width: 1024px) {
            .p-technique__results__list li > figure {
              width: 100%;
              text-align: center;
              margin: 40px 0 0; } }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-technique__results__list li > figure {
              margin-top: 20px; } }
          .p-technique__results__list li > figure figcaption {
            font-size: 20px;
            font-size: 2rem;
            font-weight: 500; }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-technique__results__list li > figure figcaption {
                font-size: 13px;
                font-size: 1.3rem; } }
      .p-technique__results__list__txt {
        padding-left: 50px; }
        @media screen and (max-width: 1024px) {
          .p-technique__results__list__txt {
            padding-left: 0; } }
        .p-technique__results__list__txt + p {
          margin-top: 2.13em; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-technique__results__list__txt + p {
              margin-top: 1.5em; } }
    .p-technique__results .c-ttl02 {
      font-size: 40px;
      font-size: 4rem;
      border-bottom-color: #fff;
      margin-bottom: 45px;
      padding: 0 0 50px 50px; }
      @media screen and (max-width: 1024px) {
        .p-technique__results .c-ttl02 {
          padding-left: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-technique__results .c-ttl02 {
          font-size: 23px;
          font-size: 2.3rem;
          margin-bottom: 25px;
          padding-bottom: 20px; } }
    .p-technique__results > .p-technique__results__list__txt {
      padding-right: 50px; }
      @media screen and (max-width: 1024px) {
        .p-technique__results > .p-technique__results__list__txt {
          padding-right: 0; } }
    .p-technique__results__bottom {
      color: #201f1f;
      background: #fff;
      margin: 50px 50px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start; }
      @media screen and (max-width: 1024px) {
        .p-technique__results__bottom {
          margin-right: 0;
          margin-left: 0;
          display: block; } }
      @media screen and (max-width: 1024px) {
        .p-technique__results__bottom img {
          width: 100%; } }
      .p-technique__results__bottom > div {
        padding: 35px 45px 25px 25px;
        flex: 1; }
        @media screen and (max-width: 1024px) {
          .p-technique__results__bottom > div {
            padding: 35px 25px 25px; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__results__bottom > div {
            padding: 35px 20px; } }
      .p-technique__results__bottom__ttl {
        font-size: 30px;
        font-size: 3rem;
        font-weight: 500;
        margin-bottom: 5px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-technique__results__bottom__ttl {
            font-size: 23px;
            font-size: 2.3rem; } }
      .p-technique__results__bottom__detail {
        border-bottom: 1px solid #201f1f;
        margin-bottom: 15px;
        padding-bottom: 15px; }

.p-apartment {
  padding-right: 30px;
  padding-left: 30px;
  margin: 115px 0 120px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-apartment {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-apartment {
      margin: 50px 0 70px;
      padding: 0; } }
  .p-apartment__ttl {
    font-size: 46px;
    font-size: 4.6rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-apartment__ttl {
        font-size: 28px;
        font-size: 2.8rem;
        line-height: 1.31;
        margin-bottom: 30px; } }
    @media print, screen and (min-width: 1025px) {
      .p-apartment__ttl br {
        display: none; } }
  .p-apartment__lead {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.78;
    text-align: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-apartment__lead {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.5;
        text-align: left; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-apartment__lead br {
        display: none; } }
  .p-apartment__inner {
    max-width: 1280px;
    margin: 0 auto; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-apartment__inner {
        padding: 0 15px; } }
  .p-apartment__flow {
    max-width: 1080px;
    margin: 0 auto;
    margin-top: 60px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-apartment__flow {
        margin-top: 40px;
        display: block; } }
    .p-apartment__flow__list, .p-apartment__flow__list--building, .p-apartment__flow__list--engineering {
      width: 46.3%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-apartment__flow__list, .p-apartment__flow__list--building, .p-apartment__flow__list--engineering {
          width: 100%; } }
      .p-apartment__flow__list li, .p-apartment__flow__list--building li, .p-apartment__flow__list--engineering li {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: column; }
        .p-apartment__flow__list li:after, .p-apartment__flow__list--building li:after, .p-apartment__flow__list--engineering li:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 13.5px 0 13.5px;
          margin: 0 auto;
          position: absolute;
          right: 0;
          bottom: -30px;
          left: 0;
          display: inline-block; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-apartment__flow__list li:after, .p-apartment__flow__list--building li:after, .p-apartment__flow__list--engineering li:after {
              border-width: 10px 10px 0 10px; } }
        .p-apartment__flow__list li:first-child, .p-apartment__flow__list--building li:first-child, .p-apartment__flow__list--engineering li:first-child {
          margin-bottom: 172px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-apartment__flow__list li:first-child, .p-apartment__flow__list--building li:first-child, .p-apartment__flow__list--engineering li:first-child {
              margin-bottom: 53px; } }
          .p-apartment__flow__list li:first-child:after, .p-apartment__flow__list--building li:first-child:after, .p-apartment__flow__list--engineering li:first-child:after {
            bottom: -43px; }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-apartment__flow__list li:first-child:after, .p-apartment__flow__list--building li:first-child:after, .p-apartment__flow__list--engineering li:first-child:after {
                bottom: -32px; } }
        .p-apartment__flow__list li + li, .p-apartment__flow__list--building li + li, .p-apartment__flow__list--engineering li + li {
          margin-top: 50px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-apartment__flow__list li + li, .p-apartment__flow__list--building li + li, .p-apartment__flow__list--engineering li + li {
              margin-top: 40px; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-apartment__flow__list li:nth-child(2), .p-apartment__flow__list--building li:nth-child(2), .p-apartment__flow__list--engineering li:nth-child(2) {
            margin-bottom: -20px; }
            .p-apartment__flow__list li:nth-child(2):after, .p-apartment__flow__list--building li:nth-child(2):after, .p-apartment__flow__list--engineering li:nth-child(2):after {
              content: none; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-apartment__flow__list li:nth-child(6), .p-apartment__flow__list--building li:nth-child(6), .p-apartment__flow__list--engineering li:nth-child(6) {
            margin-top: 60px; } }
        .p-apartment__flow__list li:nth-child(7), .p-apartment__flow__list--building li:nth-child(7), .p-apartment__flow__list--engineering li:nth-child(7) {
          margin-bottom: 205px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-apartment__flow__list li:nth-child(7), .p-apartment__flow__list--building li:nth-child(7), .p-apartment__flow__list--engineering li:nth-child(7) {
              margin-bottom: 0; } }
        .p-apartment__flow__list li:last-child:after, .p-apartment__flow__list--building li:last-child:after, .p-apartment__flow__list--engineering li:last-child:after {
          content: none; }
      .p-apartment__flow__list__type {
        width: 100%;
        font-size: 32px;
        font-size: 3.2rem;
        font-weight: 500;
        text-align: center;
        color: #fff;
        padding: 13px 0 14px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-apartment__flow__list__type {
            font-size: 23px;
            font-size: 2.3rem;
            padding: 11px 0; } }
      .p-apartment__flow__list__ttl {
        font-size: 40px;
        font-size: 4rem;
        font-weight: 500; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-apartment__flow__list__ttl {
            font-size: 30px;
            font-size: 3rem; } }
      .p-apartment__flow__list__txt {
        width: 82%;
        min-height: 3.2em;
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.6;
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-apartment__flow__list__txt {
            width: 83%;
            min-height: unset;
            font-size: 14px;
            font-size: 1.4rem; } }
      .p-apartment__flow__list__img {
        width: 82%;
        margin-top: 8px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-apartment__flow__list__img {
            width: 83%; } }
      .p-apartment__flow__list--building li:after {
        border-color: rgba(0, 78, 162, 0.2) transparent transparent transparent; }
      .p-apartment__flow__list--building .p-apartment__flow__list__type {
        background: #004ea2; }
      .p-apartment__flow__list--building .p-apartment__flow__list__ttl {
        color: #004ea2; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-apartment__flow__list--engineering {
          margin-top: 60px; } }
      .p-apartment__flow__list--engineering li:after {
        border-color: rgba(186, 37, 39, 0.2) transparent transparent transparent; }
      .p-apartment__flow__list--engineering .p-apartment__flow__list__type {
        background: #ba2527; }
      .p-apartment__flow__list--engineering .p-apartment__flow__list__ttl {
        color: #ba2527; }
    .p-apartment__flow__same, .p-apartment__flow__same--01, .p-apartment__flow__same--02 {
      width: 100%;
      font-size: 36px;
      font-size: 3.6rem;
      font-weight: 500;
      text-align: center;
      background: #fff;
      border: 1px solid #201f1f;
      padding: 9px 0 10px;
      position: absolute;
      left: 0; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-apartment__flow__same, .p-apartment__flow__same--01, .p-apartment__flow__same--02 {
          font-size: 27px;
          font-size: 2.7rem;
          padding: 7px 0;
          position: static; } }
      .p-apartment__flow__same--01 {
        top: 5.2%; }
      .p-apartment__flow__same--02 {
        top: 47.7%; }
        .p-apartment__flow__same--02:before, .p-apartment__flow__same--02:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 13.5px 0 13.5px;
          position: absolute;
          bottom: -40px;
          display: inline-block; }
        .p-apartment__flow__same--02:before {
          border-color: rgba(0, 78, 162, 0.2) transparent transparent transparent;
          left: 21.9%; }
          @media screen and (max-width: 800px) {
            .p-apartment__flow__same--02:before {
              left: 21.3%; } }
        .p-apartment__flow__same--02:after {
          border-color: rgba(186, 37, 39, 0.2) transparent transparent transparent;
          right: 21.7%; }
          @media screen and (max-width: 800px) {
            .p-apartment__flow__same--02:after {
              right: 20.7%; } }
  .p-apartment__auth {
    margin-top: 80px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-apartment__auth {
        margin-top: 60px; } }
    .p-apartment__auth .c-table {
      margin-top: 70px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-apartment__auth .c-table {
          margin-top: 35px; } }
      .p-apartment__auth .c-table tr th {
        width: 100%;
        padding: 20px 10px 5px;
        display: block; }
      .p-apartment__auth .c-table tr td {
        width: 100%;
        padding: 0 10px 20px;
        display: block; }
    .p-apartment__auth__note {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.86;
      margin-top: 32px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-apartment__auth__note {
          font-size: 12px;
          font-size: 1.2rem;
          line-height: 1.5; } }

.p-history-results {
  margin: 115px 0 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-history-results {
      margin: 60px 0 70px; } }
  .p-history-results__ttl {
    font-size: 46px;
    font-size: 4.6rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-history-results__ttl {
        font-size: 28px;
        font-size: 2.8rem;
        line-height: 1.31;
        margin-bottom: 30px; } }
    @media print, screen and (min-width: 1025px) {
      .p-history-results__ttl br {
        display: none; } }
  .p-history-results__lead {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.78;
    text-align: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-history-results__lead {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.5;
        text-align: left;
        padding: 0 15px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-history-results__lead br {
        display: none; } }
  .p-history-results__era {
    padding-right: 30px;
    padding-left: 30px;
    padding-top: 85px;
    position: relative;
    z-index: 0; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-history-results__era {
        padding-right: 15px;
        padding-left: 15px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-history-results__era {
        padding-top: 0; } }
    .p-history-results__era__inner {
      max-width: 1280px;
      margin: 0 auto;
      padding-bottom: 100px;
      position: relative; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-history-results__era__inner {
          padding-bottom: 0; } }
      .p-history-results__era__inner:before {
        content: "";
        width: 1px;
        height: 100%;
        background: #201f1f;
        position: absolute;
        top: 0;
        left: 145px;
        display: inline-block; }
        @media screen and (max-width: 1024px) {
          .p-history-results__era__inner:before {
            content: none; } }
    .p-history-results__era__list > li {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start; }
      @media screen and (max-width: 1024px) {
        .p-history-results__era__list > li {
          display: block; } }
      .p-history-results__era__list > li + li {
        margin-top: 90px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-history-results__era__list > li + li {
            margin-top: 0; } }
      .p-history-results__era__list > li:nth-child(even) .p-history-results__era__list__inner {
        flex-direction: row-reverse; }
    .p-history-results__era__list__year {
      font-size: 32px;
      font-size: 3.2rem;
      line-height: 1;
      color: #004ea2;
      margin: 12px 3.45% 0 0; }
      @media screen and (max-width: 1024px) {
        .p-history-results__era__list__year {
          margin-right: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-history-results__era__list__year {
          font-size: 28px;
          font-size: 2.8rem;
          margin-top: 60px; } }
      .p-history-results__era__list__year span {
        font-weight: 500;
        padding-right: 22px;
        position: relative;
        display: inline-block; }
        @media screen and (max-width: 1024px) {
          .p-history-results__era__list__year span {
            padding-right: 0; } }
        .p-history-results__era__list__year span:after {
          content: "";
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background: #004ea2;
          margin: auto 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: inline-block; }
          @media screen and (max-width: 1024px) {
            .p-history-results__era__list__year span:after {
              content: none; } }
    .p-history-results__era__list__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between;
      flex: 1; }
      @media screen and (max-width: 1024px) {
        .p-history-results__era__list__inner {
          display: block; } }
      .p-history-results__era__list__inner > div {
        width: 52.19%; }
        @media screen and (max-width: 1024px) {
          .p-history-results__era__list__inner > div {
            width: 100%; } }
    .p-history-results__era__list__ttl {
      font-size: 36px;
      font-size: 3.6rem;
      font-weight: 500;
      color: #004ea2; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-history-results__era__list__ttl {
          font-size: 28px;
          font-size: 2.8rem; } }
    .p-history-results__era__list__txt {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 2;
      margin-top: 20px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-history-results__era__list__txt {
          font-size: 15px;
          font-size: 1.5rem;
          line-height: 1.5;
          margin-top: 15px; } }
    .p-history-results__era__list__thumb {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start; }
      .p-history-results__era__list__thumb li {
        width: 22.4%;
        margin: 25px 3.467% 0 0; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-history-results__era__list__thumb li {
            margin-top: 20px; } }
        .p-history-results__era__list__thumb li figure {
          cursor: pointer; }
          .p-history-results__era__list__thumb li figure img {
            opacity: 0.5;
            transition: opacity 0.3s; }
          .p-history-results__era__list__thumb li figure figcaption {
            font-size: 14px;
            font-size: 1.4rem;
            font-weight: 500; }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-history-results__era__list__thumb li figure figcaption {
                font-size: 10px;
                font-size: 1rem;
                margin-top: 3px; } }
          .p-history-results__era__list__thumb li figure:hover img {
            opacity: 1; }
        .p-history-results__era__list__thumb li:nth-child(4n) {
          margin-right: 0; }
        .p-history-results__era__list__thumb li.is-current figure img {
          opacity: 1; }
    .p-history-results__era__list__main {
      width: 44.463%; }
      @media screen and (max-width: 1024px) {
        .p-history-results__era__list__main {
          width: 100%;
          text-align: center; } }
      @media screen and (max-width: 1024px) {
        .p-history-results__era__list__main img {
          margin: 30px auto 0;
          display: block; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-history-results__era__list__main img {
          margin-top: 20px; } }
    .p-history-results__era__bg, .p-history-results__era__bg--01, .p-history-results__era__bg--02, .p-history-results__era__bg--03, .p-history-results__era__bg--04 {
      width: 78.75%;
      position: absolute;
      z-index: -1; }
      .p-history-results__era__bg--01 {
        top: 0;
        left: 0; }
      .p-history-results__era__bg--02 {
        top: 20.21%;
        right: 0; }
      .p-history-results__era__bg--03 {
        top: 45%;
        left: 0; }
      .p-history-results__era__bg--04 {
        top: 65.7%;
        right: 0; }

.p-inquiry {
  padding-right: 30px;
  padding-left: 30px;
  margin: 115px 0 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-inquiry {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-inquiry {
      margin: 80px 0 70px; } }
  .p-inquiry__inner {
    max-width: 1200px;
    margin: 0 auto; }
  .p-inquiry__lead {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.78;
    text-align: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-inquiry__lead {
        font-size: 16px;
        font-size: 1.6rem;
        line-height: inherit;
        text-align: left; } }
  .p-inquiry__realproperty {
    font-size: 24px;
    font-size: 2.4rem;
    text-align: center;
    margin-top: 35px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-inquiry__realproperty {
        font-size: 20px;
        font-size: 2rem;
        margin-top: 25px; } }
    .p-inquiry__realproperty a {
      color: #004ea2;
      position: relative;
      display: inline-block; }
      .p-inquiry__realproperty a:after {
        content: "";
        width: 55px;
        height: 1px;
        background: #004ea2;
        margin: 0 auto;
        position: absolute;
        right: 0;
        bottom: -12px;
        left: 0;
        display: inline-block;
        transition: width 0.3s; }
      .p-inquiry__realproperty a:hover:after {
        width: 165px; }
  .p-inquiry__step {
    margin-top: 75px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    overflow: hidden; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-inquiry__step {
        margin-top: 45px; } }
    .p-inquiry__step li {
      font-size: 16px;
      font-size: 1.6rem;
      line-height: 1;
      color: #201f1f;
      background: #fff;
      border: 2px solid #201f1f;
      border-right: none;
      padding: 14px 20px 13px 25px;
      position: relative;
      z-index: 1; }
      _:-ms-fullscreen, :root .p-inquiry__step li {
        padding: 18px 20px 9px 25px; }
      @media screen and (max-width: 1149px) {
        .p-inquiry__step li {
          padding-right: 5px;
          padding-left: 10px; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-inquiry__step li {
          font-size: 13px;
          font-size: 1.3rem;
          border-width: 1px;
          padding: 11px 10px 10px 15px; } }
      .p-inquiry__step li + li {
        margin-left: 50px; }
        @media screen and (max-width: 1149px) {
          .p-inquiry__step li + li {
            margin-left: 40px; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__step li + li {
            margin-left: 24px; } }
      .p-inquiry__step li:last-child {
        border-right: 2px solid #201f1f; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__step li:last-child {
            border-right-width: 1px; } }
        .p-inquiry__step li:last-child:before, .p-inquiry__step li:last-child:after {
          content: none; }
      .p-inquiry__step li:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 33px 0 33px 30px;
        border-color: transparent transparent transparent #fff;
        margin: auto 0;
        position: absolute;
        top: 0;
        right: -29.5px;
        bottom: 0;
        z-index: -1;
        display: inline-block; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__step li:before {
            border-width: 26.5px 0 26.5px 17px;
            right: -17px; } }
      .p-inquiry__step li:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 0 35px 32px;
        border-color: transparent transparent transparent #201f1f;
        margin: auto 0;
        position: absolute;
        top: 0;
        right: -32px;
        bottom: 0;
        z-index: -2;
        display: inline-block; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__step li:after {
            border-width: 27.5px 0 27.5px 18px;
            right: -18px; } }
      .p-inquiry__step li.p-inquiry__step__current {
        color: #fff;
        background: #201f1f; }
        .p-inquiry__step li.p-inquiry__step__current:before {
          content: none; }
    .p-inquiry__step span {
      font-size: 20px;
      font-size: 2rem;
      margin-top: 3px;
      display: inline-block; }
      @media screen and (max-width: 1149px) {
        .p-inquiry__step span {
          font-size: 18px;
          font-size: 1.8rem; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-inquiry__step span {
          font-size: 16px;
          font-size: 1.6rem; } }
      .p-inquiry__step span:before {
        content: "お問い合わせ"; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__step span:before {
            content: none; } }
  .p-inquiry__form {
    background: #f7f7f7;
    margin-top: 60px;
    padding: 55px 40px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-inquiry__form {
        margin-top: 40px;
        padding: 30px 15px 50px; } }
    .p-inquiry__form__table {
      width: 100%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-inquiry__form__table tr {
          width: 100%;
          display: block; } }
      .p-inquiry__form__table tr th {
        font-size: 20px;
        font-size: 2rem;
        text-align: left;
        padding: 30px 25px 30px 0;
        vertical-align: top;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__form__table tr th {
            font-size: 16px;
            font-size: 1.6rem;
            margin-top: 20px;
            padding: 0;
            justify-content: flex-start; } }
        .p-inquiry__form__table tr th span {
          font-size: 10px;
          font-size: 1rem;
          color: #fff;
          background: #004ea2;
          padding: 2px 9px;
          display: inline-block; }
          _:-ms-fullscreen, :root .p-inquiry__form__table tr th span {
            padding: 0.5em 9px 0; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-inquiry__form__table tr th span {
              margin-left: 15px;
              padding: 1px 6px; } }
      .p-inquiry__form__table tr td {
        width: 76%;
        padding: 25px 0;
        vertical-align: middle; }
        @media screen and (max-width: 1415px) {
          .p-inquiry__form__table tr td {
            width: 66%; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__form__table tr td {
            width: 100%;
            margin-top: 15px;
            padding: 0;
            display: block; } }
        .p-inquiry__form__table tr td input {
          border: none; }
          .p-inquiry__form__table tr td input[type="text"], .p-inquiry__form__table tr td input[type="email"] {
            width: 100%;
            font-size: 18px;
            font-size: 1.8rem;
            padding: 7px 20px; }
            _:-ms-fullscreen, :root .p-inquiry__form__table tr td input[type="text"], :root .p-inquiry__form__table tr td input[type="email"] {
              padding: 11px 20px 3px; }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-inquiry__form__table tr td input[type="text"], .p-inquiry__form__table tr td input[type="email"] {
                font-size: 16px;
                font-size: 1.6rem;
                padding: 10px; } }
            .p-inquiry__form__table tr td input[type="text"][name^="tel"], .p-inquiry__form__table tr td input[type="email"][name^="tel"] {
              margin: 0 5px;
              padding: 7px 20px; }
              _:-ms-fullscreen, :root .p-inquiry__form__table tr td input[type="text"][name^="tel"], :root .p-inquiry__form__table tr td input[type="email"][name^="tel"] {
                padding: 11px 20px 3px; }
              .p-inquiry__form__table tr td input[type="text"][name^="tel"]:first-child, .p-inquiry__form__table tr td input[type="email"][name^="tel"]:first-child {
                margin-left: 0; }
              @media screen and (min-width: 1px) and (max-width: 767px) {
                .p-inquiry__form__table tr td input[type="text"][name^="tel"], .p-inquiry__form__table tr td input[type="email"][name^="tel"] {
                  padding: 10px; } }
          .p-inquiry__form__table tr td input[type="file"] {
            font-size: 16px;
            font-size: 1.6rem;
            outline: none; }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-inquiry__form__table tr td input[type="file"] {
                font-size: 14px;
                font-size: 1.4rem; } }
        .p-inquiry__form__table tr td textarea {
          width: 100%;
          min-height: 165px;
          padding: 7px 20px;
          font-size: 18px;
          font-size: 1.8rem;
          border: none;
          resize: vertical; }
          _:-ms-fullscreen, :root .p-inquiry__form__table tr td textarea {
            padding: 11px 20px 3px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-inquiry__form__table tr td textarea {
              min-height: 140px;
              font-size: 16px;
              font-size: 1.6rem;
              padding: 10px; } }
        .p-inquiry__form__table tr td select {
          height: 2.5em;
          font-size: 18px;
          font-size: 1.8rem;
          border: none;
          padding: 7px 20px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-inquiry__form__table tr td select {
              height: 3em;
              font-size: 16px;
              font-size: 1.6rem;
              padding: 10px; } }
  .p-inquiry__submit {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-inquiry__submit {
        align-items: center;
        flex-direction: column-reverse; } }
    .p-inquiry__submit button {
      padding: 0; }
      .p-inquiry__submit button span {
        width: 100%;
        color: #201f1f;
        padding: 13px 0;
        position: relative;
        z-index: 0;
        display: inline-block;
        transition: color 0.3s; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-inquiry__submit button span {
            padding: 14px 0; } }
        .p-inquiry__submit button span:after {
          content: "";
          width: 120%;
          height: 100%;
          background: #201f1f;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform-origin: right top;
          transform: skewX(-30deg) scale(0, 1);
          transition: transform 0.3s; }
        .p-inquiry__submit button span:hover {
          color: #fff; }
          .p-inquiry__submit button span:hover:after {
            transform-origin: left top;
            transform: skewX(-30deg) scale(1, 1); }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-inquiry__submit button + button {
          margin: 0 0 25px 0; } }
  .p-inquiry .mw_wp_form_confirm .p-inquiry__form__table td {
    font-size: 20px;
    font-size: 2rem; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-inquiry .mw_wp_form_confirm .p-inquiry__form__table td {
        font-size: 16px;
        font-size: 1.6rem; } }
  .p-inquiry .mw_wp_form .error {
    font-size: 16px;
    font-size: 1.6rem; }

.p-privacy {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 65px;
  margin-bottom: 110px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-privacy {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-privacy {
      margin-bottom: 45px; } }
  .p-privacy__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-privacy__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: 500;
    color: #004ea2;
    margin: 60px 0 25px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-privacy__ttl {
        font-size: 24px;
        font-size: 2.4rem; } }
  .p-privacy__txt {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.88;
    margin-bottom: 1.88em; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-privacy__txt {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.6;
        margin-bottom: 1.6em; } }
  .p-privacy__mh {
    font-size: 20px;
    font-size: 2rem;
    font-weight: 500;
    margin: 60px 0 10px;
    position: relative; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-privacy__mh {
        margin-top: 40px; } }
    .p-privacy__mh:before {
      content: "";
      width: 16px;
      height: 1px;
      vertical-align: middle;
      background: #201f1f;
      margin-right: 8px;
      display: inline-block; }

.p-use {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 65px;
  margin-bottom: 110px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-use {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-use {
      margin-bottom: 70px; } }
  .p-use__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-use__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: 500;
    color: #004ea2;
    margin: 60px 0 25px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-use__ttl {
        font-size: 24px;
        font-size: 2.4rem;
        margin: 40px 0 15px; } }
  .p-use__txt {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.88;
    margin-bottom: 1.88em; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-use__txt {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.6;
        margin-bottom: 1.6em; } }
    .p-use__txt a {
      color: #004ea2;
      display: inline-block; }
      .p-use__txt a:after {
        content: "";
        width: 12px;
        height: 12px;
        background: url(/cms/wp-content/themes/tada-con/images/common/cmn-blank-ico01.svg) 0 0 no-repeat;
        background-size: 100% 100%;
        margin-left: 5px;
        display: inline-block; }
      .p-use__txt a:hover {
        text-decoration: underline; }
  .p-use__mh {
    font-size: 20px;
    font-size: 2rem;
    font-weight: 500;
    margin: 60px 0 10px;
    position: relative; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-use__mh {
        margin-top: 40px; } }
    .p-use__mh:before {
      content: "";
      width: 16px;
      height: 1px;
      vertical-align: middle;
      background: #201f1f;
      margin-right: 8px;
      display: inline-block; }
  .p-use__list li span {
    font-weight: 500; }
  .p-use__plugin {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-use__plugin {
        display: block; } }
    .p-use__plugin .p-use__txt {
      margin: 0 0 0 20px;
      flex: 1; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-use__plugin .p-use__txt {
          margin: 10px 0 0 0; } }

.p-sitemap {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-sitemap {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-sitemap {
      margin-bottom: 70px; } }
  .p-sitemap__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-sitemap__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-sitemap__list {
        margin-top: 80px;
        display: block; } }
    .p-sitemap__list li {
      width: 33%;
      margin-top: 100px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-sitemap__list li {
          width: 100%;
          margin-top: 50px; } }
      .p-sitemap__list li dl dt {
        font-size: 24px;
        font-size: 2.4rem;
        font-weight: 500;
        color: #004ea2;
        margin-bottom: 10px; }
        @media screen and (max-width: 1024px) {
          .p-sitemap__list li dl dt {
            font-size: 20px;
            font-size: 2rem; } }
        .p-sitemap__list li dl dt:before {
          content: "";
          width: 40px;
          height: 1px;
          vertical-align: middle;
          background: #004ea2;
          margin-right: 10px;
          display: inline-block; }
          @media screen and (min-width: 768px) and (max-width: 1024px) {
            .p-sitemap__list li dl dt:before {
              width: 30px; } }
      .p-sitemap__list li dl dd {
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1.88;
        margin-left: 60px; }
        @media screen and (max-width: 1024px) {
          .p-sitemap__list li dl dd {
            font-size: 14px;
            font-size: 1.4rem; } }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .p-sitemap__list li dl dd {
            margin-left: 50px; } }
        .p-sitemap__list li dl dd a:hover {
          text-decoration: underline; }

.p-nf {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 165px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-nf {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-nf {
      margin-top: 120px;
      margin-bottom: 70px; } }
  .p-nf .l-breadcrumb {
    padding: 0; }
  .p-nf .l-page-ttl {
    max-width: 1280px;
    padding: 0; }
  .p-nf__inner {
    max-width: 1280px;
    margin: 0 auto; }

.p-shinko__sec-ttl {
  font-size: 32px;
  font-size: 3.2rem;
  font-weight: 500;
  line-height: 1.3;
  text-align: center; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__sec-ttl {
      font-size: 23px;
      font-size: 2.3rem; } }
  .p-shinko__sec-ttl span {
    font-size: 14px;
    font-size: 1.4rem;
    color: #004ea2; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__sec-ttl span {
        font-size: 13px;
        font-size: 1.3rem; } }

.p-shinko__mv img {
  width: 100%; }

.p-shinko__intro {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 80px;
  position: relative;
  z-index: 0; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__intro {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__intro {
      margin-top: 60px;
      padding: 0; } }
  .p-shinko__intro:before {
    content: "";
    width: 40.9375vw;
    height: 100%;
    background: #eff2f5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    display: inline-block; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__intro:before {
        width: 81.875%; } }
  .p-shinko__intro__inr {
    max-width: 1280px;
    margin: 0 auto;
    padding: 5vw 0 9.375vw;
    position: relative; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__intro__inr {
        padding: 50px 0 0; } }
    .p-shinko__intro__inr > div {
      width: 35.3125vw;
      background: #fff;
      padding: 6.8125vw 2.6875vw 8.0625vw;
      position: absolute;
      bottom: 80px;
      left: 0; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko__intro__inr > div {
          width: 92.5%;
          margin: 0 auto;
          padding: 50px 0 50px 20px;
          position: static; } }
  .p-shinko__intro__img {
    text-align: right; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__intro__img {
        margin-top: 50px; } }
    .p-shinko__intro__img img {
      width: 53.75vw; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko__intro__img img {
          width: 100%; } }
  .p-shinko__intro__ttl {
    font-size: 1.75vw;
    font-weight: 500;
    line-height: 1.71;
    color: #004ea2;
    margin-bottom: 1.25vw; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__intro__ttl {
        font-size: 20px;
        font-size: 2rem;
        line-height: 1.3;
        margin-bottom: 10px; } }
  .p-shinko__intro__lead {
    font-size: 1vw;
    line-height: 2; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__intro__lead {
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 1.5; } }

.p-shinko__business {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 100px;
  padding-bottom: 130px;
  position: relative;
  z-index: 0; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__business {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__business {
      margin-top: 60px;
      padding-bottom: 50px; } }
  .p-shinko__business:after {
    content: "";
    width: 100%;
    height: 54.21%;
    background: #eff2f5;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: inline-block; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__business:after {
        height: 50%; } }
  .p-shinko__business__ttl {
    margin-bottom: 40px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__business__ttl {
        margin-bottom: 25px; } }
  .p-shinko__business__inr {
    max-width: 1280px;
    margin: 0 auto; }
  .p-shinko__business__list {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__business__list {
        display: block; } }
    .p-shinko__business__list li {
      width: 46.875%;
      text-align: center; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko__business__list li {
          width: 100%; }
          .p-shinko__business__list li + li {
            margin-top: 35px; } }
    .p-shinko__business__list__ttl {
      font-size: 20px;
      font-size: 2rem;
      font-weight: 500;
      margin-top: 20px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko__business__list__ttl {
          font-size: 16px;
          font-size: 1.6rem; } }

.p-shinko__about {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 110px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__about {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__about {
      margin-top: 80px; } }
  .p-shinko__about__inr {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__about__inr {
        display: block; } }
  .p-shinko__about__lt, .p-shinko__about__gt {
    width: 46.88%; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__about__lt, .p-shinko__about__gt {
        width: 100%; } }
  .p-shinko__about__ttl {
    font-size: 26px;
    font-size: 2.6rem;
    font-weight: 500;
    color: #004ea2;
    margin-bottom: 10px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__about__ttl {
        font-size: 18px;
        font-size: 1.8rem; } }
    .p-shinko__about__ttl-03 {
      margin-top: 50px; }
  .p-shinko__about__map {
    width: 100%;
    margin: 40px 0 0; }
  .p-shinko__about__access {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 10px 0 50px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko__about__access {
        font-size: 15px;
        font-size: 1.5rem; } }
  .p-shinko__about__table {
    width: 100%; }
    .p-shinko__about__table tr {
      border-bottom: 1px solid #eee; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko__about__table tr {
          display: block; } }
      .p-shinko__about__table tr th {
        width: 33.33%;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        text-align: left;
        vertical-align: middle;
        padding: 15px 0; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-shinko__about__table tr th {
            width: 100%;
            font-size: 15px;
            font-size: 1.5rem;
            padding-bottom: 0;
            display: block; } }
      .p-shinko__about__table tr td {
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1.88;
        vertical-align: middle;
        padding: 15px 0; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-shinko__about__table tr td {
            width: 100%;
            font-size: 15px;
            font-size: 1.5rem;
            line-height: 2;
            padding-top: 0;
            display: block; } }

.p-shinko__requirements {
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 80px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__requirements {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko__requirements {
      margin-top: 60px; } }
  .p-shinko__requirements__inr {
    max-width: 1000px;
    margin: 0 auto; }
  .p-shinko__requirements__table {
    width: 100%; }
    .p-shinko__requirements__table tr {
      border-bottom: 1px solid #eee; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko__requirements__table tr {
          display: block; } }
      .p-shinko__requirements__table tr th {
        width: 11.5%;
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.88;
        text-align: left;
        vertical-align: top;
        padding: 15px 0; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-shinko__requirements__table tr th {
            width: 100%;
            font-size: 15px;
            font-size: 1.5rem;
            padding-bottom: 0;
            display: block; } }
      .p-shinko__requirements__table tr td {
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 1.88;
        padding: 15px 0; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-shinko__requirements__table tr td {
            width: 100%;
            font-size: 15px;
            font-size: 1.5rem;
            line-height: 2;
            padding-top: 0;
            display: block; } }

.p-shinko-contact__mv {
  height: 300px;
  background: url(/cms/wp-content/themes/tada-con/images/shinko/shinko-contact-bg.jpg) center top no-repeat;
  background-size: cover;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .p-shinko-contact__mv__ttl {
    font-size: 32px;
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    color: #fff; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko-contact__mv__ttl {
        font-size: 28px;
        font-size: 2.8rem; } }
    .p-shinko-contact__mv__ttl span {
      font-size: 14px;
      font-size: 1.4rem;
      margin-bottom: 10px;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko-contact__mv__ttl span {
          font-size: 12px;
          font-size: 1.2rem; } }

.p-shinko-contact .l-breadcrumb__list {
  margin-bottom: 50px;
  justify-content: flex-end; }

.p-shinko-contact__inr {
  max-width: 910px;
  margin: 0 auto;
  padding: 0 30px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko-contact__inr {
      padding: 0 15px; } }
  .p-shinko-contact__inr .mw_wp_form .p-inquiry__form {
    background: none;
    margin-top: 30px;
    padding: 0; }
    .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr {
      border-bottom: 1px solid rgba(238, 238, 238, 0.8); }
      @media screen and (max-width: 1024px) {
        .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr {
          width: 100%;
          display: block; } }
      .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr th {
        font-size: 16px;
        font-size: 1.6rem;
        padding-top: 37px; }
        @media screen and (max-width: 1024px) {
          .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr th {
            width: 100%;
            margin-top: 20px;
            padding: 0;
            display: block; } }
        @media screen and (max-width: 1024px) {
          .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr th span {
            margin-left: 15px; } }
      .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr td {
        width: 75%;
        padding: 28px 0; }
        @media screen and (max-width: 1024px) {
          .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr td {
            width: 100%;
            margin: 15px 0 20px;
            padding: 0;
            display: block; } }
        .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr td textarea,
        .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr td input {
          border: 1px solid #d0d0d0; }
        .p-shinko-contact__inr .mw_wp_form .p-inquiry__form__table tr td .error {
          font-size: 16px;
          font-size: 1.6rem; }
  .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button {
    width: 300px;
    font-size: 16px;
    font-size: 1.6rem;
    background: #004ea2;
    border: 1px solid #004ea2;
    transition: background 0.3s;
    margin: 0; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button {
        width: 70%; } }
    .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button + button {
      margin-left: 30px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button + button {
          margin: 0 0 25px 0; } }
    .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button span {
      color: #fff;
      padding: 23px 0;
      transition: color 0.3s; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button span {
          padding: 20px 0; } }
      .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button span:after {
        content: none; }
    .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button:hover {
      background: #fff; }
      .p-shinko-contact__inr .mw_wp_form .p-inquiry__submit button:hover span {
        color: #004ea2; }
  .p-shinko-contact__inr .mw_wp_form_confirm .p-inquiry__form__table tr td {
    font-size: 16px;
    font-size: 1.6rem; }

.p-shinko-contact__lead {
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko-contact__lead {
      text-align: left; } }

.p-shinko-contact__return {
  width: 300px;
  font-size: 16px;
  font-size: 1.6rem;
  background: #004ea2;
  border: 1px solid #004ea2;
  transition: background 0.3s; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-shinko-contact__return {
      width: 70%; } }
  .p-shinko-contact__return a {
    color: #fff;
    padding: 23px 0;
    transition: color 0.3s; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-shinko-contact__return a {
        padding: 20px 0; } }
    .p-shinko-contact__return a:after {
      content: none; }
  .p-shinko-contact__return:hover {
    background: #fff; }
    .p-shinko-contact__return:hover a {
      color: #004ea2; }

.p-news__list li {
  font-size: 16px;
  font-size: 1.6rem;
  border-bottom: 1px solid #d5d5d5; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-news__list li {
      font-size: 14px;
      font-size: 1.4rem; } }
  .p-news__list li a {
    width: 100%;
    padding: 37px 16px;
    display: inline-block;
    transition: background .1s; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-news__list li a {
        padding: 25px 15px; } }
    .p-news__list li a:hover {
      background: rgba(213, 213, 213, 0.14); }
    .p-news__list li a article {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start; }

.p-news__list__cat {
  width: 90px;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
  color: #004ea2;
  border: 1px solid #004ea2;
  margin: 0 28px;
  padding: 6px 0 5px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-news__list__cat {
      width: 84px;
      font-size: 12px;
      font-size: 1.2rem;
      margin: 0 13px; } }

.p-news__list__ttl {
  flex: 1; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-news__list__ttl {
      width: 100%;
      margin-top: 10px;
      flex: unset; } }

.p-info, .p-info--single {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-info, .p-info--single {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-info, .p-info--single {
      margin-bottom: 70px; } }
  .p-info__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-info__cat {
    max-width: 1280px;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 110px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-info__cat {
        margin-top: 60px;
        margin-bottom: 30px;
        justify-content: flex-start; } }
    .p-info__cat li {
      width: 21.09%;
      font-size: 16px;
      font-size: 1.6rem;
      text-align: center;
      margin-right: 0;
      margin-left: 4.69%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-info__cat li {
          width: 31%;
          font-size: 13px;
          font-size: 1.3rem;
          margin-left: 3.125%; } }
      @media screen and (max-width: 330px) {
        .p-info__cat li {
          font-size: 12px;
          font-size: 1.2rem; } }
      .p-info__cat li:nth-child(5n+1) {
        margin-left: 0; }
      .p-info__cat li a {
        width: 100%;
        height: 100%;
        padding: 13px 0;
        position: relative;
        display: inline-block; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-info__cat li a {
            border-width: 1px;
            padding: 12px 0; } }
  .p-info--single {
    margin-bottom: 70px;
    padding: 0; }
    .p-info--single__ttl {
      font-size: 36px;
      font-size: 3.6rem;
      font-weight: 500;
      margin-bottom: 22px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-info--single__ttl {
          font-size: 25px;
          font-size: 2.5rem;
          margin-bottom: 10px; } }
    .p-info--single__head {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-info--single__head {
          margin-bottom: 5px; } }
      .p-info--single__head .p-news__list__cat {
        width: auto;
        min-width: 110px;
        margin: 0 20px 5px 0;
        padding: 6px 5px 5px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-info--single__head .p-news__list__cat {
            margin: 0 10px 10px 0; } }
      .p-info--single__head .p-news__list__date {
        font-size: 15px;
        font-size: 1.5rem;
        margin-bottom: 5px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-info--single__head .p-news__list__date {
            font-size: 13px;
            font-size: 1.3rem; } }
    .p-info--single__content > p {
      font-size: 15px;
      font-size: 1.5rem;
      line-height: 1.8;
      margin-bottom: 2em; }
      .p-info--single__content > p:after {
        content: "";
        display: block;
        clear: both; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-info--single__content > p {
          line-height: inherit; } }
    .p-info--single__content a {
      color: #004ea2; }
      .p-info--single__content a:hover {
        text-decoration: underline; }
    .p-info--single__content img.alignleft {
      float: left; }
    .p-info--single__content img.alignright {
      float: right; }
    .p-info--single__content img.aligncenter {
      margin: 0 auto;
      display: block; }

.p-koukoku {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-koukoku {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-koukoku {
      margin-bottom: 70px; } }
  .p-koukoku__inner {
    max-width: 1280px;
    margin: 0 auto; }
  .p-koukoku__years {
    font-size: 20px;
    font-size: 2rem;
    margin: 72px 0 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-end; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-koukoku__years {
        font-size: 14px;
        font-size: 1.4rem;
        margin-top: 80px; } }
    .p-koukoku__years__ttl {
      font-weight: 500;
      margin-right: 40px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-koukoku__years__ttl {
          margin-right: 15px; } }
    .p-koukoku__years__list {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start; }
      .p-koukoku__years__list li {
        font-weight: 500; }
        .p-koukoku__years__list li a:hover {
          text-decoration: underline; }
        .p-koukoku__years__list li + li:before {
          content: "/";
          margin: 0 1.3em;
          display: inline-block; }

.p-results {
  margin: 100px 0 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-results {
      margin: 60px 0 70px; } }
  .p-results__lead {
    font-size: 40px;
    font-size: 4rem;
    font-weight: 500;
    text-align: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results__lead {
        font-size: 23px;
        font-size: 2.3rem; } }
  .p-results__search {
    max-width: 1280px;
    margin: 0 auto;
    color: #fff;
    background: #201f1f;
    margin-top: 75px;
    margin-bottom: 65px;
    padding: 45px; }
    @media screen and (min-width: 768px) and (max-width: 1024px) {
      .p-results__search {
        padding: 45px 30px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results__search {
        margin-top: 60px;
        margin-bottom: 45px;
        padding: 0; } }
    .p-results__search__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__inner {
          display: block; } }
    .p-results__search__tax--type {
      width: 43.36%; }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .p-results__search__tax--type {
          width: 65.36%; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax--type {
          width: 100%; } }
    .p-results__search__tax--area {
      width: 21.01%; }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .p-results__search__tax--area {
          width: 30.01%; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax--area {
          width: 100%; } }
      @media print, screen and (min-width: 1025px) {
        .p-results__search__tax--area > div {
          height: auto !important;
          padding: 0 !important;
          display: block !important; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax--area > div {
          background: #4d4c4c;
          padding: 40px 15px;
          display: none; } }
    .p-results__search__tax--year {
      width: 25.04%; }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .p-results__search__tax--year {
          width: 100%;
          margin-top: 25px; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax--year {
          width: 100%; } }
      @media print, screen and (min-width: 1025px) {
        .p-results__search__tax--year > div {
          height: auto !important;
          padding: 0 !important;
          display: block !important; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax--year > div {
          background: #4d4c4c;
          padding: 40px 15px;
          display: none; } }
    .p-results__search__tax__ttl {
      font-size: 22px;
      font-size: 2.2rem;
      border-bottom: 1px solid #fff;
      margin-bottom: 15px;
      padding-bottom: 10px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax__ttl {
          font-size: 18px;
          font-size: 1.8rem;
          text-align: center;
          border-top: 1px solid #fff;
          border-bottom: none;
          margin-bottom: 0;
          padding: 21px 0;
          position: relative;
          cursor: pointer;
          user-select: none; }
          .p-results__search__tax__ttl:after {
            content: "";
            width: 12px;
            height: 12px;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            margin: auto 0;
            position: absolute;
            top: -9px;
            right: 20px;
            bottom: 0;
            display: inline-block;
            transform: rotate(45deg); }
          .p-results__search__tax__ttl.is-active:after {
            top: 7px;
            transform: rotate(-135deg); } }
    .p-results__search__tax__inner {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between; }
      @media print, screen and (min-width: 1025px) {
        .p-results__search__tax__inner {
          height: auto !important;
          padding: 0 !important;
          display: flex !important; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax__inner {
          background: #4d4c4c;
          padding: 45px 15px 50px;
          display: none; } }
      .p-results__search__tax__inner > div:first-child {
        flex: 1; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-results__search__tax__inner > div:first-child {
            flex: unset;
            border-bottom: 1px solid #fff;
            margin-bottom: 40px;
            padding-bottom: 40px; } }
    .p-results__search__tax__parent {
      font-size: 18px;
      font-size: 1.8rem;
      margin-bottom: 5px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__tax__parent {
          font-size: 20px;
          font-size: 2rem;
          margin-bottom: 10px; } }
    .p-results__search__tax__list, .p-results__search__tax__list--col1, .p-results__search__tax__list--col2, .p-results__search__tax__list--col3 {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: flex-start; }
      .p-results__search__tax__list li, .p-results__search__tax__list--col1 li, .p-results__search__tax__list--col2 li, .p-results__search__tax__list--col3 li {
        font-size: 15px;
        font-size: 1.5rem; }
        @media screen and (max-width: 1024px) {
          .p-results__search__tax__list li, .p-results__search__tax__list--col1 li, .p-results__search__tax__list--col2 li, .p-results__search__tax__list--col3 li {
            font-size: 14px;
            font-size: 1.4rem; } }
        .p-results__search__tax__list li label, .p-results__search__tax__list--col1 li label, .p-results__search__tax__list--col2 li label, .p-results__search__tax__list--col3 li label {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start; }
          .p-results__search__tax__list li label input, .p-results__search__tax__list--col1 li label input, .p-results__search__tax__list--col2 li label input, .p-results__search__tax__list--col3 li label input {
            margin: 0 4px 0 0;
            cursor: inherit; }
      .p-results__search__tax__list--col1 {
        width: 100%;
        flex-direction: column; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-results__search__tax__list--col1 {
            flex-direction: row; } }
        .p-results__search__tax__list--col1 li {
          width: 100%; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-results__search__tax__list--col1 li {
              width: calc(100% / 2); } }
          .p-results__search__tax__list--col1 li + li {
            margin-top: 25px; }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-results__search__tax__list--col1 li + li {
                margin-top: 0; } }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-results__search__tax__list--col1 li:nth-child(n+3) {
              margin-top: 14px; } }
      .p-results__search__tax__list--col2 li {
        width: calc(100% / 2); }
        .p-results__search__tax__list--col2 li:nth-child(n+3) {
          margin-top: 25px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-results__search__tax__list--col2 li:nth-child(n+3) {
              margin-top: 14px; } }
      .p-results__search__tax__list--col3 li {
        width: calc(100% / 3); }
        .p-results__search__tax__list--col3 li:nth-child(n+4) {
          margin-top: 25px; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-results__search__tax__list--col3 li:nth-child(n+4) {
              margin-top: 14px; } }
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          .p-results__search__tax__list--col3 li {
            width: auto;
            margin-right: 35px; }
            .p-results__search__tax__list--col3 li:nth-child(n+4) {
              margin-top: 0; } }
    .p-results__search__submit {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__submit {
          border-top: 1px solid #fff;
          margin-top: 0;
          display: block; } }
      .p-results__search__submit > div {
        flex: 1; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__search__submit__btn {
          margin-top: 50px; } }
      .p-results__search__submit__btn button {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center; }
        .p-results__search__submit__btn button:before {
          content: "";
          width: 17px;
          height: 17px;
          background: url(/cms/wp-content/themes/tada-con/images/common/cmn-search-ico.svg) 0 0 no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          transition: background .3s; }
          @media screen and (max-width: 1024px) {
            .p-results__search__submit__btn button:before {
              width: 12px;
              height: 12px; } }
        .p-results__search__submit__btn button:hover:before {
          background-image: url(/cms/wp-content/themes/tada-con/images/common/cmn-search-ico02.svg); }
        .p-results__search__submit__btn button span {
          margin-left: 20px;
          display: inline-block; }
          @media screen and (max-width: 1024px) {
            .p-results__search__submit__btn button span {
              margin-left: 8px; } }
      .p-results__search__submit__keywords {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center; }
        @media screen and (max-width: 1024px) {
          .p-results__search__submit__keywords {
            width: 100%; } }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-results__search__submit__keywords {
            width: 73.38%;
            margin: 0 auto;
            padding: 22px 0;
            display: block; } }
        .p-results__search__submit__keywords__ttl {
          font-size: 16px;
          font-size: 1.6rem;
          margin-right: 30px;
          padding-right: 45px;
          position: relative; }
          @media screen and (max-width: 1024px) {
            .p-results__search__submit__keywords__ttl {
              font-size: 15px;
              font-size: 1.5rem;
              margin-right: 20px;
              padding-right: 20px; } }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-results__search__submit__keywords__ttl {
              margin-right: 0;
              margin-bottom: 10px;
              padding-right: 0; } }
          .p-results__search__submit__keywords__ttl:after {
            content: "";
            width: 1px;
            height: 48px;
            background: #fff;
            margin: auto 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: inline-block;
            transform: rotate(30deg); }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-results__search__submit__keywords__ttl:after {
                content: none; } }
        .p-results__search__submit__keywords__area {
          width: 100%;
          max-width: 224px;
          position: relative;
          flex: 1; }
          @media screen and (max-width: 1024px) {
            .p-results__search__submit__keywords__area {
              max-width: unset; } }
          .p-results__search__submit__keywords__area input[type="search"] {
            appearance: none;
            width: 100%;
            height: 28px;
            border: none;
            border-radius: 0; }
            @media screen and (min-width: 1px) and (max-width: 767px) {
              .p-results__search__submit__keywords__area input[type="search"] {
                height: 36px; } }
  .p-results__inner {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 30px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results__inner {
        padding: 0 15px; } }
  .p-results__count {
    font-size: 24px;
    font-size: 2.4rem;
    font-weight: 500; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results__count {
        font-size: 17px;
        font-size: 1.7rem; } }
    .p-results__count span {
      font-size: 39px;
      font-size: 3.9rem;
      font-weight: 500;
      margin-left: .2em;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__count span {
          font-size: 30px;
          font-size: 3rem; } }
  .p-results__list {
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results__list {
        margin-top: 20px; } }
    .p-results__list li {
      width: 22.66%;
      margin-right: 3.12%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__list li {
          width: 46.62%;
          margin-right: 6.76%; } }
      .p-results__list li a {
        width: 100%;
        transition: opacity .3s;
        display: inline-block; }
        .p-results__list li a:hover {
          opacity: .5; }
      .p-results__list li:nth-child(4n) {
        margin-right: 0; }
      .p-results__list li:nth-child(n+5) {
        margin-top: 70px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-results__list li:nth-child(n+5) {
            margin-top: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__list li:nth-child(even) {
          margin-right: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__list li:nth-child(n+3) {
          margin-top: 45px; } }
    .p-results__list__thumb {
      height: 250px;
      overflow: hidden; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__list__thumb {
          height: 150px; } }
      .p-results__list__thumb > p {
        height: 100%;
        text-align: center; }
      .p-results__list__thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .p-results__list__ttl {
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: 500;
      margin-top: 18px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__list__ttl {
          font-size: 13px;
          font-size: 1.3rem;
          margin-top: 8px; } }
    .p-results__list__place {
      font-size: 15px;
      font-size: 1.5rem;
      margin-top: 8px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__list__place {
          font-size: 11px;
          font-size: 1.1rem;
          margin-top: 3px; } }
    .p-results__list__year {
      font-size: 15px;
      font-size: 1.5rem; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results__list__year {
          font-size: 11px;
          font-size: 1.1rem; } }
  .p-results--single__txt {
    margin-bottom: 15px !important; }
  .p-results--single__slide__main {
    height: 630px;
    overflow: hidden; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results--single__slide__main {
        height: 245px; } }
    .p-results--single__slide__main li {
      height: 100%; }
    .p-results--single__slide__main .slick-slide img {
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: contain; }
  .p-results--single__slide__thumb {
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results--single__slide__thumb {
        margin-top: 15px; } }
    .p-results--single__slide__thumb li {
      width: 18.38%;
      height: 115px;
      margin-right: 2.1%;
      cursor: pointer;
      overflow: hidden; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results--single__slide__thumb li {
          width: 22.97%;
          height: 55px;
          margin-right: 2.7%; } }
      .p-results--single__slide__thumb li img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: .4;
        transition: opacity .3s; }
      .p-results--single__slide__thumb li.is-current img {
        opacity: 1; }
  .p-results--single__data {
    margin-top: 72px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results--single__data {
        margin-top: 62px; } }
    .p-results--single__data__ttl {
      font-size: 26px;
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 8px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results--single__data__ttl {
          font-size: 18px;
          font-size: 1.8rem;
          margin-bottom: 10px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-results--single__data__table tr {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start; }
        .p-results--single__data__table tr + tr {
          border-top: none; } }
    .p-results--single__data__table tr th {
      width: 13%;
      padding-left: 25px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results--single__data__table tr th {
          width: 40%;
          background: #fff;
          padding: 25px 10px 25px 35px; }
          .p-results--single__data__table tr th:nth-child(1) {
            background: #f9f9f9; } }
    .p-results--single__data__table tr td {
      width: 37%;
      padding: 25px 15px 25px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-results--single__data__table tr td {
          width: 60%;
          background: #fff; }
          .p-results--single__data__table tr td:nth-child(2) {
            background: #f9f9f9; }
          .p-results--single__data__table tr td + th {
            border-top: 1px solid #dedede; }
            .p-results--single__data__table tr td + th + td {
              border-top: 1px solid #dedede; } }

.p-csr {
  padding-right: 30px;
  padding-left: 30px;
  margin-bottom: 130px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-csr {
      padding-right: 15px;
      padding-left: 15px; } }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-csr {
      margin-bottom: 70px; } }
  .p-csr .p-page-link {
    max-width: 1280px;
    padding: 0; }
    @media screen and (max-width: 1024px) {
      .p-csr .p-page-link {
        justify-content: space-between; } }
    .p-csr .p-page-link li {
      margin-right: 2.34%; }
      @media screen and (min-width: 768px) and (max-width: 1024px) {
        .p-csr .p-page-link li {
          width: 24%;
          margin-right: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr .p-page-link li {
          width: 49%;
          font-size: 14px;
          font-size: 1.4rem;
          margin-right: 0; } }
      .p-csr .p-page-link li a {
        padding: 21px 0; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-csr .p-page-link li a {
            padding: 14px 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr .p-page-link li:nth-child(n+3) {
          margin-top: 2%; } }
  .p-csr__inner {
    max-width: 1280px;
    margin: 0 auto; }
    .p-csr__inner > section:not(:first-child) div[id] {
      margin-top: 105px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr__inner > section:not(:first-child) div[id] {
          margin-top: 80px; } }
  .p-csr__list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-csr__list {
        margin-top: 20px; } }
    .p-csr__list li {
      width: 46.88%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr__list li {
          width: 100%; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr__list li + li {
          margin-top: 40px !important; } }
      .p-csr__list li:nth-child(n+3) {
        margin-top: 75px; }
    .p-csr__list__thumb {
      text-align: center;
      position: relative; }
      .p-csr__list__thumb video {
        max-width: 100%;
        height: auto;
        background: #000; }
      .p-csr__list__thumb__cv {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer; }
        @media screen and (max-width: 1112px) {
          .p-csr__list__thumb__cv {
            display: none; } }
    .p-csr__list__ttl {
      font-size: 24px;
      font-size: 2.4rem;
      margin: 25px 0 0; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr__list__ttl {
          font-size: 18px;
          font-size: 1.8rem;
          margin-top: 15px; } }
    .p-csr__list__lead {
      font-size: 16px;
      font-size: 1.6rem;
      margin-top: 12px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr__list__lead {
          font-size: 14px;
          font-size: 1.4rem; } }
    .p-csr__list__link {
      margin-top: 30px;
      margin-left: 0; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-csr__list__link {
          margin-top: 20px;
          margin-left: auto; } }

.p-mansion {
  margin: 100px 0 140px; }
  @media screen and (min-width: 1px) and (max-width: 767px) {
    .p-mansion {
      margin: 60px 0 70px; } }
  .p-mansion__lead {
    font-size: 40px;
    font-size: 4rem;
    font-weight: 500;
    text-align: center; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-mansion__lead {
        font-size: 23px;
        font-size: 2.3rem; } }
  .p-mansion__inner {
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 30px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-mansion__inner {
        padding: 0 15px; } }
  .p-mansion__list {
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: flex-start; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-mansion__list {
        margin-top: 20px; } }
    .p-mansion__list li {
      width: 22.66%;
      margin-right: 3.12%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion__list li {
          width: 46.62%;
          margin-right: 6.76%; } }
      .p-mansion__list li:nth-child(4n) {
        margin-right: 0; }
      .p-mansion__list li:nth-child(n+5) {
        margin-top: 70px; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-mansion__list li:nth-child(n+5) {
            margin-top: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion__list li:nth-child(even) {
          margin-right: 0; } }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion__list li:nth-child(n+3) {
          margin-top: 45px; } }
      .p-mansion__list li:not(.p-mansion__list__sold-out) a {
        width: 100%;
        transition: opacity .3s;
        display: inline-block; }
        .p-mansion__list li:not(.p-mansion__list__sold-out) a:hover {
          opacity: .5; }
    .p-mansion__list__thumb {
      position: relative; }
      .p-mansion__list__thumb__cv {
        width: 100%;
        height: 100%;
        font-size: 36px;
        font-size: 3.6rem;
        font-weight: 500;
        line-height: 1;
        color: #fff;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center; }
        @media screen and (max-width: 1024px) {
          .p-mansion__list__thumb__cv {
            font-size: 24px;
            font-size: 2.4rem; } }
    .p-mansion__list__ttl {
      font-size: 18px;
      font-size: 1.8rem;
      font-weight: 500;
      margin-top: 18px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion__list__ttl {
          font-size: 13px;
          font-size: 1.3rem;
          margin-top: 8px; } }
    .p-mansion__list__sold-out a {
      width: 100%;
      display: inline-block;
      cursor: default;
      user-select: none;
      pointer-events: none; }
  .p-mansion--single__visual {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-mansion--single__visual {
        display: block; } }
    .p-mansion--single__visual__main {
      width: 74.22%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion--single__visual__main {
          width: 100%; } }
    .p-mansion--single__visual > div {
      width: 23.44%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion--single__visual > div {
          width: 100%;
          margin-top: 15px;
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          justify-content: space-between; } }
    .p-mansion--single__visual__side {
      opacity: .4;
      transition: opacity .3s;
      cursor: pointer; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion--single__visual__side {
          width: 32%; } }
      .p-mansion--single__visual__side + p {
        margin-top: 5%; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-mansion--single__visual__side + p {
            margin-top: 0; } }
      .p-mansion--single__visual__side.is-current {
        opacity: 1; }
  .p-mansion--single__summary {
    margin-top: 72px; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-mansion--single__summary {
        margin-top: 62px; } }
    .p-mansion--single__summary__ttl {
      font-size: 26px;
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 8px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion--single__summary__ttl {
          font-size: 18px;
          font-size: 1.8rem;
          margin-bottom: 10px; } }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-mansion--single__summary__table tr {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start; }
        .p-mansion--single__summary__table tr + tr {
          border-top: none; } }
    .p-mansion--single__summary__table tr th {
      width: 13%;
      padding-left: 25px; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion--single__summary__table tr th {
          width: 40%;
          background: #f9f9f9;
          padding: 20px 10px 20px 35px; }
          .p-mansion--single__summary__table tr th:nth-child(1) {
            background: #fff; } }
    .p-mansion--single__summary__table tr td {
      width: 37%; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion--single__summary__table tr td {
          width: 60%;
          background: #f9f9f9; }
          .p-mansion--single__summary__table tr td:nth-child(2) {
            background: #fff; }
          .p-mansion--single__summary__table tr td + th {
            border-top: 1px solid #dedede; }
            .p-mansion--single__summary__table tr td + th + td {
              border-top: 1px solid #dedede; } }
    .p-mansion--single__summary__table tr:first-child th {
      background: #f9f9f9; }
    .p-mansion--single__summary__table tr:first-child td {
      background: #f9f9f9; }
      .p-mansion--single__summary__table tr:first-child td > div {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between; }
  .p-mansion--single__special {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 100px;
    overflow: hidden; }
    @media screen and (min-width: 1px) and (max-width: 767px) {
      .p-mansion--single__special {
        height: 100px;
        margin-top: 60px; } }
    .p-mansion--single__special a {
      width: 100%;
      height: 100%;
      position: relative;
      display: inline-block; }
      @media screen and (min-width: 1px) and (max-width: 767px) {
        .p-mansion--single__special a img {
          width: 100%;
          height: 100%;
          object-fit: cover; } }
      .p-mansion--single__special a:hover .p-mansion--single__special__cv:after {
        transform-origin: left top;
        transform: skewX(-30deg) scale(1, 1); }
    .p-mansion--single__special__cv {
      width: 100%;
      height: 100%;
      background: rgba(32, 31, 31, 0.6);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center; }
      .p-mansion--single__special__cv:after {
        content: "";
        width: 120%;
        height: 100%;
        background: rgba(0, 36, 144, 0.75);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform-origin: right top;
        transform: skewX(-30deg) scale(0, 1);
        transition: transform .3s; }
      .p-mansion--single__special__cv__txt {
        font-size: 28px;
        font-size: 2.8rem;
        font-weight: 500;
        color: #fff;
        position: relative; }
        @media screen and (min-width: 1px) and (max-width: 767px) {
          .p-mansion--single__special__cv__txt {
            font-size: 20px;
            font-size: 2rem; } }
        .p-mansion--single__special__cv__txt:after {
          content: "";
          width: 17px;
          height: 17px;
          background: url(/cms/wp-content/themes/tada-con/images/common/cmn-blank-ico02.svg) 0 0 no-repeat;
          background-size: 100% 100%;
          margin-left: 12px;
          display: inline-block; }
          @media screen and (min-width: 1px) and (max-width: 767px) {
            .p-mansion--single__special__cv__txt:after {
              width: 12px;
              height: 12px; } }

.u-min {
  font-family: "リュウミン R-KL", "Noto Serif CJK JP", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, serif; }

.u-osw {
  font-family: 'Oswald', sans-serif; }

@media print, screen and (min-width: 1025px) {
  .u-tab, .u-sp {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .u-pc, .u-sp {
    display: none !important; } }

@media screen and (min-width: 1px) and (max-width: 767px) {
  .u-pc, .u-tab {
    display: none !important; }
  .u-spnone {
    display: none !important; } }

.u-mt0 {
  margin-top: 0px !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mt65 {
  margin-top: 65px !important; }

.u-mt70 {
  margin-top: 70px !important; }

.u-mt75 {
  margin-top: 75px !important; }

.u-mt80 {
  margin-top: 80px !important; }

.u-mt85 {
  margin-top: 85px !important; }

.u-mt90 {
  margin-top: 90px !important; }

.u-mt95 {
  margin-top: 95px !important; }

.u-mt100 {
  margin-top: 100px !important; }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .u-t-mt0 {
    margin-top: 0px !important; }
  .u-t-mt5 {
    margin-top: 5px !important; }
  .u-t-mt10 {
    margin-top: 10px !important; }
  .u-t-mt15 {
    margin-top: 15px !important; }
  .u-t-mt20 {
    margin-top: 20px !important; }
  .u-t-mt25 {
    margin-top: 25px !important; }
  .u-t-mt30 {
    margin-top: 30px !important; }
  .u-t-mt35 {
    margin-top: 35px !important; }
  .u-t-mt40 {
    margin-top: 40px !important; }
  .u-t-mt45 {
    margin-top: 45px !important; }
  .u-t-mt50 {
    margin-top: 50px !important; }
  .u-t-mt55 {
    margin-top: 55px !important; }
  .u-t-mt60 {
    margin-top: 60px !important; }
  .u-t-mt65 {
    margin-top: 65px !important; }
  .u-t-mt70 {
    margin-top: 70px !important; }
  .u-t-mt75 {
    margin-top: 75px !important; }
  .u-t-mt80 {
    margin-top: 80px !important; }
  .u-t-mt85 {
    margin-top: 85px !important; }
  .u-t-mt90 {
    margin-top: 90px !important; }
  .u-t-mt95 {
    margin-top: 95px !important; }
  .u-t-mt100 {
    margin-top: 100px !important; } }

@media screen and (min-width: 1px) and (max-width: 767px) {
  .u-s-mt0 {
    margin-top: 0px !important; }
  .u-s-mt5 {
    margin-top: 5px !important; }
  .u-s-mt10 {
    margin-top: 10px !important; }
  .u-s-mt15 {
    margin-top: 15px !important; }
  .u-s-mt20 {
    margin-top: 20px !important; }
  .u-s-mt25 {
    margin-top: 25px !important; }
  .u-s-mt30 {
    margin-top: 30px !important; }
  .u-s-mt35 {
    margin-top: 35px !important; }
  .u-s-mt40 {
    margin-top: 40px !important; }
  .u-s-mt45 {
    margin-top: 45px !important; }
  .u-s-mt50 {
    margin-top: 50px !important; }
  .u-s-mt55 {
    margin-top: 55px !important; }
  .u-s-mt60 {
    margin-top: 60px !important; }
  .u-s-mt65 {
    margin-top: 65px !important; }
  .u-s-mt70 {
    margin-top: 70px !important; }
  .u-s-mt75 {
    margin-top: 75px !important; }
  .u-s-mt80 {
    margin-top: 80px !important; }
  .u-s-mt85 {
    margin-top: 85px !important; }
  .u-s-mt90 {
    margin-top: 90px !important; }
  .u-s-mt95 {
    margin-top: 95px !important; }
  .u-s-mt100 {
    margin-top: 100px !important; } }
