// 汎用
&page {
  &-link {
    @include inner-box(1340);
    margin-top: 120px;
    margin-bottom: 80px;
    padding: 0 30px;
    @include flex("", "c");

    @include mq(sp) {
      margin-top: 60px;
      margin-bottom: 40px;
      padding: 0 15px;
    }

    li {
      width: 21.09%;
      margin: 0 4.69% 0 0;

      @include mq(sp) {
        width: 32.43%;
        margin: 0 1.35% 0 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        width: 100%;
        height: 100%;
        @include flex("c", "c");
        span {
          width: 85%;
          line-height: 1.25;
          padding-right: 6%;
          position: relative;
          display: inline-block;
          &:after {
            content: "";
            width: 8px;
            height: 8px;
            border-right: 1px solid $col_sub;
            border-bottom: 1px solid $col_sub;
            margin: auto 0;
            position: absolute;
            top: -4px;
            right: 0;
            bottom: 0;
            display: inline-block;
            transform: rotate(45deg);
            transition: border-color 0.3s;
          }
        }

        &:hover {
          span {
            &:after {
              border-color: #fff;
            }
          }
        }
      }
    }
    &__current {
      a {
        color: #fff;
        &:after {
          transform: scale(1, 1);
        }
      }
    }
  }

  // 多田建設の技術
  &--technique {
    .p-page-link {
      margin-top: 40px;

      @include mq(tab) {
        justify-content: space-between;
      }

      li {
        width: 21.88%;

        @include mq(tab) {
          width: 33%;
          margin: 0;
        }

        @include mq(sp) {
          width: 100%;
        }

        + li {
          @include mq(sp) {
            margin-top: 20px;
          }
        }
      }
    }
  }

  // 個人情報保護方針・ご利用にあたって
  &--privacy,
  &--use {
    .l-pagebody {
      margin-top: 165px;

      @include mq(sp) {
        margin-top: 120px;
      }
    }
  }
}
// -----------------------------------------

// 多田建設について
&company {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__lead {
    @include fz(48);
    font-weight: 500;
    text-align: center;
    margin: 80px 0 65px;

    @include mq(811) {
      @include fz(45);
    }

    @include mq(sp) {
      @include fz(32);
      line-height: 1.2;
      margin: 70px 0 35px;
    }
  }
  &__list-top {
    @include inner-box;
    @include flex("", "fs");

    @include mq(sp) {
      flex-direction: column;
    }

    li {
      width: 50%;
      position: relative;

      @include mq(sp) {
        width: 100%;
      }

      > div {
        position: relative;
      }
      a {
        width: 100%;
        height: 100%;
        @include fz(32);
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        text-shadow: 0px 4px 51px #080103, 0px 4px 51px #080103;
        background: rgba(#004288, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        @include flex("c", "c");
        transition: background 0.3s;

        @include mq(sp) {
          @include fz(30);
        }

        &:hover {
          background: rgba(#000, 0.7);
        }
      }
    }
  }
  &__list-bottom {
    @include inner-box;
    @include flex("", "fs");

    li {
      width: 25%;
      position: relative;

      @include mq(sp) {
        width: 50%;
      }

      a {
        width: 100%;
        height: 100%;
        @include fz(28);
        color: #fff;
        font-weight: 500;
        line-height: 1.3;
        text-align: center;
        color: #fff;
        text-shadow: 0px 4px 51px #080103, 0px 4px 51px #080103;
        background: rgba(#004288, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        @include flex("c", "c");
        transition: background 0.3s;

        @include mq(sp) {
          @include fz(24);
        }

        &:hover {
          background: rgba(#000, 0.7);
        }
      }
    }
  }
}

// ご挨拶
&greetings {
  @include wrapper-box;
  &__inner {
    @include inner-box;
    margin-top: 60px;
    @include flex("fe", "");

    @include mq(1024) {
      flex-direction: column;
    }
  }
  &__left {
    width: 71.48%;
    padding-bottom: 130px;

    @include mq(1024) {
      width: 100%;
      padding-bottom: 0;
    }
  }
  &__lead {
    @include fz(52);
    font-weight: 500;
    margin-bottom: 60px;

    @include mq(1024) {
      @include fz(50);
    }

    @include mq(sp) {
      @include fz(34);
      margin-bottom: 40px;
      br {
        display: none;
      }
    }
  }
  &__txt {
    @include fz(16);
    line-height: 2.25;

    @include mq(sp) {
      @include fz(14);
    }

    + .p-greetings__txt {
      margin-top: 4.5em;

      @include mq(sp) {
        margin-top: 2.5em;
      }
    }
  }
  &__regards {
    @include fz(16);
    text-align: right;
    margin-top: 2.25em;

    @include mq(sp) {
      @include fz(14);
      margin-top: 1.25em;
    }
  }
  &__sign {
    text-align: right;
    margin-top: 80px;

    @include mq(1024) {
      text-align: left;
    }

    @include mq(sp) {
      width: 80%;
      margin-top: 50px;
    }
  }
  &__right {
    width: 22.97%;

    @include mq(1024) {
      width: 100%;
      text-align: right;
    }

    @include mq(sp) {
      margin-top: 30px;
    }

    img {
      @include mq(sp) {
        width: 147px;
      }
    }
  }
}

// 会社概要
&profile {
  @include wrapper-box;
  margin-bottom: 130px;

  @include mq(sp) {
    margin-bottom: 60px;
  }

  &__inner {
    @include inner-box;
  }
  &__ttl {
    @include fz(26);
    font-weight: 500;
    margin: 100px 0 15px;

    @include mq(sp) {
      @include fz(22);
      margin-top: 60px;
    }
  }
  .c-table {
    tr {
      td {
        a {
          color: $col_main;
          &[target="_blank"] {
            &:after {
              content: "";
              width: 17px;
              height: 17px;
              vertical-align: text-bottom;
              background: url(#{$img_common_path}/cmn-blank-ico01.svg) 0 0 no-repeat;
              background-size: 100% 100%;
              margin-left: 5px;
              display: inline-block;

              @include mq(sp) {
                width: 14px;
                height: 14px;
              }
            }
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &__memberlist {
    li {
      span {
        min-width: 23em;
        display: inline-block;

        @include mq(sp) {
          min-width: unset;
          display: block;
        }
      }
    }
  }
  &__access {
    &__map {
      margin-bottom: 40px;

      @include mq(sp) {
        margin-bottom: 30px;
      }

      iframe {
        width: 100%;

        @include mq(sp) {
          height: 300px;
        }
      }
    }
    &__list {
      @include flex("", "fs");

      @include mq(sp) {
        flex-direction: column;
      }

      li {
        + li {
          margin-left: vwpc(100);

          @include mq(sp) {
            margin: 30px 0 0 0;
          }
        }
      }
      dl {
        dt {
          @include fz(20);
          font-weight: 500;
          margin-bottom: 0.3em;

          @include mq(sp) {
            @include fz(18);
          }
        }
        dd {
          @include fz(15);
          line-height: 2.13;

          @include mq(sp) {
            @include fz(13);
          }
        }
      }
      &__txt {
        text-indent: -10.3em;
        padding-left: 10.3em;
      }
    }
  }
}

// 経営理念
&management {
  @include wrapper-box;
  text-align: center;
  background: url(#{$img_path}/company/management/management-bg-pc.png) center top no-repeat;
  background-size: cover;
  margin-top: 55px;
  padding-top: 70px;
  padding-bottom: 840px;

  @include mq(sp) {
    background: url(#{$img_path}/company/management/management-bg-sp.png) center bottom no-repeat;
    background-size: cover;
    margin-top: 80px;
    padding-top: 0;
    padding-bottom: 483px;
  }

  section {
    &:first-child {
      .p-management__ttl {
        margin-top: 0;
      }
    }
    &:nth-child(2) {
      .p-management__lead {
        @include mq(956) {
          text-align: center;
        }

        @include mq(sp) {
          @include fz(22);
        }
      }
    }
  }
  &__main {
    &__lead {
      @include fz(53);
      font-weight: 500;

      @include mq(sp) {
        @include fz(32);
      }
    }
    &__txt {
      @include fz(22);
      font-weight: 500;
      margin-top: 0.5em;

      @include mq(sp) {
        @include fz(16);
      }
    }
  }
  &__ttl {
    @include fz(30);
    margin: 90px 0 20px;

    @include mq(sp) {
      @include fz(25);
      margin-top: 60px;
    }
    span {
      font-weight: 500;
      border-bottom: 1px solid $col_sub;
      display: inline-block;
    }
  }
  &__lead {
    @include fz(32);
    font-weight: 500;
    line-height: 1.88;
    font-weight: 500;

    @include mq(956) {
      text-align: left;
      br {
        display: none;
      }
    }

    @include mq(sp) {
      @include fz(20);
      line-height: 1.65;
    }

    span {
      font-weight: 500;
      display: inline-block;
      + span {
        margin-left: 1em;
      }
      &:last-child {
        @include mq(956) {
          margin-left: 0;
          display: block;
        }
      }
    }
  }
  &__txt {
    @include fz(15);
    line-height: 2.13;
    margin-top: 20px;

    @include mq(956) {
      text-align: left;
      br {
        display: none;
      }
    }

    @include mq(sp) {
      @include fz(14);
      line-height: 1.5;
    }
  }
  &__note {
    @include fz(15);
    margin-top: 16px;

    @include mq(956) {
      text-align: left;
    }

    @include mq(sp) {
      margin-top: 1.5em;
    }
  }
}

// 事業所・関連会社
&establishment {
  @include wrapper-box;
  margin-bottom: 130px;

  @include mq(sp) {
    margin-bottom: 60px;
  }

  &__inner {
    @include inner-box;
  }
  &__ttl {
    @include fz(26);
    font-weight: 500;
    margin: 100px 0 15px;

    @include mq(sp) {
      @include fz(22);
      margin-top: 60px;
    }
  }
  .c-table {
    tr {
      background: #fff;
      &:first-child {
        background: #f9f9f9;
      }
      &.c-table--bg {
        background: #f9f9f9;
      }
      th {
        @include mq(sp) {
          width: 100%;
          padding: 20px 10px 5px;
          display: block;
        }

        &.c-table--ind01 {
          @include mq(pc) {
            padding-left: calc(15px + 1em);
          }

          @include mq(tab) {
            padding-left: calc(15px + 1em);
          }
        }
        &.c-table--ind02 {
          @include mq(pc) {
            padding-left: calc(15px + 2em);
          }

          @include mq(tab) {
            padding-left: calc(15px + 2em);
          }
        }
      }
      td {
        padding-right: 40px;
        @include flex("c", "fs");

        @include mq(sp) {
          width: 100%;
          padding: 0 10px 20px;
          display: block;
        }

        address {
          margin-right: 30px;
          flex: 1;

          @include mq(sp) {
            margin: 0 0 10px 0;
          }
        }
      }
    }
    + table {
      margin-top: 80px;

      @include mq(sp) {
        margin-top: 40px;
      }
    }
  }
  &__link {
    margin-left: auto;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__relation {
    .c-table {
      tr {
        background: #f9f9f9 !important;
        th {
          span {
            text-indent: -5em;
            padding-left: 5em;
            display: inline-block;
          }
        }
      }
    }
  }
}

// 組織図
&organizational {
  @include wrapper-box;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 70px;

  @include mq(sp) {
    margin-top: 60px;
  }

  &__link {
    margin-top: 80px;

    @include mq(sp) {
      margin-top: 40px;
    }
  }
}

// 沿革
&history {
  @include wrapper-box;
  margin-top: 80px;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-top: 60px;
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }
  &__table {
    tr {
      th {
        width: auto;
        &:first-child {
          width: 15%;
          padding-left: 50px;
          word-break: break-word;

          @include mq(1303) {
            padding-left: vwpc(50);
          }

          @include mq(1199) {
            width: 24%;
          }
        }
        &:nth-child(2) {
          width: 6%;

          @include mq(1199) {
            width: 10%;
          }

          @include mq(sp) {
            width: 16%;
          }
        }
      }
    }
  }
}
// -----------------------------------------

// 事業内容
&business {
  @include wrapper-box;
  margin-top: 80px;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-top: 60px;
    margin-bottom: 70px;
    padding: 0;
  }

  &__inner {
    @include inner-box;

    @include mq(sp) {
      padding: 0 15px;
    }
  }
  &__ttl {
    @include fz(46);
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;

    @include mq(sp) {
      @include fz(30);
      line-height: 1.31;
      margin-bottom: 30px;
    }

    br {
      @include mq(pc) {
        display: none;
      }
    }
  }
  &__lead {
    @include fz(18);
    line-height: 1.78;
    text-align: center;

    @include mq(sp) {
      @include fz(15);
      line-height: 1.5;
      text-align: left;
    }

    br {
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__list {
    > li {
      margin-top: 100px;
      @include flex("c", "");

      @include mq(1024) {
        display: block;
      }

      @include mq(sp) {
        margin-top: 70px;
      }

      > p {
        margin-right: 5.78%;
        flex: 1;

        @include mq(1024) {
          text-align: center;
          margin-right: 0;
          margin-bottom: 60px;
        }

        @include mq(sp) {
          width: 76.62%;
          margin: 0 auto 25px !important;
        }
      }
      > div {
        width: 49.3%;
        min-width: 510px;

        @include mq(1024) {
          width: 100%;
          min-width: unset;
        }
      }

      &:nth-child(even) {
        flex-direction: row-reverse;
        > p {
          margin-right: 0;
          margin-left: 5.78%;
        }
      }
    }
    &__sub {
      @include fz(26);

      @include mq(sp) {
        @include fz(15);
      }
    }
    &__ttl {
      @include fz(56);
      margin-top: 15px;

      @include mq(sp) {
        @include fz(37);
        margin-top: 0;
      }

      span {
        font-weight: 500;
        padding-right: 115px;
        position: relative;
        display: inline-block;

        @include mq(sp) {
          padding-right: 57px;
        }

        &:after {
          content: "";
          width: 80px;
          height: 1px;
          background: $col_main;
          margin: auto 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: inline-block;

          @include mq(sp) {
            width: 40px;
          }
        }
      }
    }
    &__lead {
      @include fz(15);
      line-height: 2.13;
      margin-top: 25px;

      @include mq(sp) {
        text-align: left;
        line-height: 1.5;
        margin-top: 12px;
      }
    }
    &__cat {
      width: 79.69%;
      margin-top: 35px;
      @include flex("", "fs");

      @include mq(1220) {
        width: 100%;
      }

      @include mq(sp) {
        margin-top: 15px;
      }

      li {
        width: 23.53%;
        text-align: center;
        color: #fff;
        font-feature-settings: "palt";
        background: $col_sub;
        margin-right: 1.96%;
        padding: 3px 0;
        @include flex("c", "c");

        @include mq(sp) {
          width: 24%;
          @include fz(10);
          margin-right: 1.33%;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
        &:nth-child(n + 5) {
          margin-top: 5px;
        }
      }
    }
    &__link {
      margin: 60px 0 0 0;

      @include mq(sp) {
        margin: 60px auto 0;
      }

      @include mq(sp) {
        margin-top: 40px;
      }
    }
  }

  // 下層汎用
  &__page-list {
    li {
      margin-top: 100px;
      @include flex("", "fs");

      @include mq(1024) {
        display: block;
      }

      @include mq(sp) {
        margin-top: 50px;
      }

      > div {
        flex: 1;
      }
      > figure {
        width: 370px;
        margin-left: 4.84%;
        text-align: center;

        @include mq(1024) {
          width: auto;
          max-width: 740px;
          margin: 60px auto 0;
        }

        @include mq(sp) {
          margin-top: 30px;
        }

        figcaption {
          @include fz(16);
          font-weight: 500;
          text-align: left;
          margin-top: 20px;

          @include mq(1024) {
          }

          @include mq(sp) {
            @include fz(18);
            margin-top: 15px;
          }
        }
      }
    }
    &__txt {
      @include fz(15);
      line-height: 2.13;

      @include mq(sp) {
        line-height: 1.5;
        text-align: left;
      }
    }
    &__caption {
      @include fz(12);
      line-height: 1.67;
      text-align: left;
      display: inline-block;

      @include mq(sp) {
        @include fz(13);
        line-height: 1.38;
        margin-top: 5px;
      }
    }
    &__link {
      margin: 40px 0 0 0;

      @include mq(sp) {
        width: 70%;
        margin: 35px auto 0;
      }
    }
  }
}

// 建築工事
&building {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(1024) {
    padding: 0;
  }

  @include mq(sp) {
    margin-bottom: 0;
  }

  &__inner {
    @include inner-box;

    @include mq(1024) {
      padding: 0 30px;
    }

    @include mq(sp) {
      padding: 0 15px;
    }
  }
  &__results {
    @include inner-box;
    color: #fff;
    background: $col_sub;
    margin-top: 100px;
    padding: 55px 0;

    @include mq(1024) {
      padding-right: 30px;
      padding-left: 30px;
    }

    @include mq(sp) {
      margin-top: 60px;
      padding: 60px 15px 70px;
    }

    .c-ttl {
      @include fz(40);
      margin-bottom: 12px;
      padding-left: 50px;

      @include mq(1024) {
        padding-left: 0;
      }

      @include mq(sp) {
        @include fz(44);
        margin-bottom: 10px;
      }
    }
    .p-business__page-list {
      li {
        margin-top: 0;
        > figure {
          width: 36.33%;
          margin-right: 3.52%;
          margin-left: 2.97%;

          @include mq(1024) {
            width: auto;
            max-width: 740px;
          }

          figcaption {
            @include fz(20);
            font-weight: 500;

            @include mq(sp) {
              @include fz(13);
            }
          }
        }
      }
      &__txt {
        padding-left: 50px;

        @include mq(1024) {
          padding-left: 0;
        }

        + p {
          margin-top: 2.13em;

          @include mq(sp) {
            margin-top: 1.5em;
          }
        }
      }
    }
    .c-ttl02 {
      @include fz(40);
      border-bottom-color: #fff;
      margin-bottom: 20px;
      padding: 0 0 15px 50px;

      @include mq(1024) {
        padding-left: 0;
      }

      @include mq(sp) {
        @include fz(23);
      }
    }
  }
}

// 土木工事
&engineering {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }
}

// 不動産開発
&real-estate {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }

  .p-business__page-list__link {
    width: 295px;

    @include mq(sp) {
      width: 85%;
    }
  }
  &__bottom {
    @include fz(36);
    font-weight: 500;
    text-align: center;
    color: $col_main;
    margin-top: 90px;

    @include mq(sp) {
      @include fz(25);
      margin-top: 50px;
    }
  }
}

// ノーブルマンション
&noble {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }

  &__list {
    margin-top: 80px;
    @include flex("", "");

    @include mq(sp) {
      margin-top: 50px;
    }

    li {
      width: 28.91%;
      text-align: center;

      @include mq(1024) {
        width: 48%;
      }

      figure {
        figcaption {
          @include fz(24);
          font-weight: 500;
          line-height: 1.2;
          margin-bottom: 15px;

          @include mq(sp) {
            @include fz(18);
          }

          span {
            @include fz(15);

            @include mq(sp) {
              @include fz(13);
            }
          }
        }
      }
      &:nth-child(n + 4) {
        margin-top: 70px;
      }
      &:nth-child(n + 3) {
        @include mq(1024) {
          margin-top: 70px;

          @include mq(sp) {
            margin-top: 40px;
          }
        }
      }
    }
    &__caption {
      @include fz(15);
      text-align: left;
      margin-top: 15px;

      @include mq(sp) {
        @include fz(13);
      }
    }
  }

  &__ttl {
    @include fz(26);
    font-weight: 500;
    margin: 100px 0 15px;

    @include mq(sp) {
      @include fz(22);
      margin-top: 60px;
    }
  }
}
// -----------------------------------------

// 多田建設の技術
&technique {
  @include wrapper-box;
  margin-bottom: 130px;

  @include mq(sp) {
    margin-bottom: 70px;
    padding: 0;
  }

  &-link {
    @include inner-box(1340);
    margin-top: 55px;
    padding: 0 30px;
    @include flex("", "c");

    @include mq(sp) {
      margin-top: 60px;
    }

    li {
      @include fz(20);

      @include mq(sp) {
        @include fz(16);
      }

      a {
        &:hover {
          text-decoration: underline;
        }
      }

      + li {
        margin-left: 40px;

        @include mq(sp) {
          margin-top: 5px;
          margin-left: 0;
        }
      }
    }
  }

  &__inner {
    @include inner-box;
  }
  &__head {
    color: #fff;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right top;
    margin: 120px 0 72px;

    @include mq(sp) {
      margin: 70px 0 50px;
    }

    &__inner {
      background: linear-gradient(to right, #000 30%, transparent 70%);
      padding: 20px 50px 16px;

      @include mq(tab) {
        background: linear-gradient(to right, #000 65%, transparent 70%);
      }

      @include mq(sp) {
        background: linear-gradient(to right, #000 40%, transparent 70%);
        padding: 21px 10px 18px;
      }
    }
    &__num {
      @include fz(30);
      line-height: 0.9;
      margin-left: 5px;
      @include flex("c", "fs");

      @include mq(sp) {
        @include fz(15);
      }

      span {
        @include fz(120);
        margin-left: 15px;
        display: inline-block;

        @include mq(sp) {
          @include fz(60);
        }
      }
    }
    &__ttl {
      @include fz(48);
      font-weight: 500;
      line-height: 1.25;
      margin-top: 15px;

      @include mq(sp) {
        @include fz(28);
        line-height: 1.33;
        margin-top: 10px;
      }
    }
    &__lead {
      margin-top: 10px;

      @include mq(sp) {
        @include fz(14);
      }
    }
  }
  &__sec-ttl {
    margin-top: 85px;

    @include mq(sp) {
      margin-top: 60px;
    }
  }
  &__col2 {
    @include flex("", "");

    @include mq(1024) {
      display: block;
    }

    &__txt {
      width: 50.08%;

      @include mq(1024) {
        width: 100%;
      }
    }
    figure {
      width: 42.11%;
      text-align: center;

      @include mq(1024) {
        width: 76.13%;
        margin: 30px auto 0;
      }

      @include mq(sp) {
        width: 100%;
        margin-top: 20px;
      }

      figcaption {
        @include fz(18);
        margin-top: 15px;

        @include mq(sp) {
          @include fz(13);
        }
      }
    }
  }
  &__case {
    margin-top: 30px;
    &__ttl {
      @include fz(26);
      font-weight: 500;
      margin-bottom: 18px;

      @include mq(sp) {
        @include fz(18);
        margin-bottom: 12px;
      }
    }
    &__list {
      @include flex("", "fs");

      @include mq(1024) {
        justify-content: space-between;
      }

      li {
        width: 22.66%;
        text-align: center;
        margin-right: 3.12%;

        @include mq(1024) {
          width: 46.76%;
          margin-right: 0;
        }

        &:nth-child(n + 3) {
          @include mq(1024) {
            margin-top: 40px;
          }

          @include mq(sp) {
            margin-top: 30px;
          }
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }
      &__cat {
        margin-top: 18px;
        @include flex("", "fs");

        @include mq(sp) {
          margin-top: 12px;
        }

        li {
          width: 37.93%;
          @include fz(13);
          text-align: center;
          color: $col_main;
          border: 1px solid $col_main;
          margin-right: 10px;
          padding: 2px 0 1px;

          @include mq(sp) {
            width: 42.21%;
            @include fz(10);
          }
        }
      }
      &__name {
        @include fz(18);
        font-weight: 500;
        line-height: 1.4;
        text-align: left;
        margin-top: 5px;

        @include mq(sp) {
          @include fz(13);
        }
      }
      &__completion {
        @include fz(15);
        text-align: left;

        @include mq(sp) {
          @include fz(11);
        }
      }
    }
    &__bottom {
      @include fz(16);
      margin-top: 22px;

      @include mq(sp) {
        @include fz(15);
        margin-top: 20px;
      }
    }
  }
  &__manufacture {
    text-align: center;
    margin-top: 100px;

    @include mq(sp) {
      @include wrapper-box;
      margin-top: 50px;
    }

    &__ttl {
      @include fz(48);
      font-weight: 500;

      @include mq(tab) {
        @include fz(44);
      }

      @include mq(sp) {
        @include fz(25);
      }
    }
    &__lead {
      margin-top: 30px;

      @include mq(sp) {
        text-align: left;
        margin-top: 15px;
      }
    }
    &__list {
      margin-top: 70px;
      @include flex("", "c");

      @include mq(sp) {
        margin-top: 30px;
      }

      li {
        margin: 0;
        + li {
          margin-left: 40px;

          @include mq(sp) {
            margin-top: 15px;
            margin-left: 0;
          }
        }
      }
    }
  }
  &__sec01 {
    .p-technique__head {
      background-image: url(#{$img_path}/technique/technique-sec01-bg-pc.jpg);

      @include mq(1024) {
        background-image: url(#{$img_path}/technique/technique-sec01-bg-sp.jpg);
      }
    }
    &__img {
      width: 658px;
      margin-top: 45px;

      @include mq(sp) {
        width: 100%;
        margin-top: 25px;
      }
    }
    &__img-list {
      margin-top: 45px;
      @include flex("", "");

      @include mq(sp) {
        margin-top: 35px;
        display: block;
      }

      li {
        width: 36.77%;
        text-align: center;

        @include mq(sp) {
          width: 100%;
          margin-bottom: 30px;
        }

        + li {
          width: 58.58%;
          margin-bottom: 0;

          @include mq(sp) {
            width: 100%;
          }
        }
      }
      &__caption {
        @include fz(18);
        margin-top: 15px;
        @include flex("", "c");

        @include mq(sp) {
          @include fz(13);
        }

        span {
          width: 40%;
          text-align: center;
          display: inline-block;
          + span {
            width: 55%;
            margin-left: auto;
          }
        }
      }
    }
  }
  &__sec02 {
    .p-technique__head {
      background-image: url(#{$img_path}/technique/technique-sec02-bg-pc.jpg);

      @include mq(1024) {
        background-image: url(#{$img_path}/technique/technique-sec02-bg-sp.jpg);
      }
    }
    &__img-list {
      margin-top: 45px;
      @include flex("", "");

      @include mq(sp) {
        margin-top: 0;
      }

      li {
        width: 45.31%;
        text-align: center;

        @include mq(sp) {
          width: 100%;
          margin-top: 30px;
        }

        figure {
          figcaption {
            @include fz(18);
            margin-top: 20px;

            @include mq(sp) {
              @include fz(13);
              margin-top: 15px;
            }
          }
        }
        + li {
          width: 50%;

          @include mq(sp) {
            width: 100%;
          }
        }
      }
    }
    .c-table {
      margin-top: 45px;

      @include mq(sp) {
        margin-top: 30px;
      }
      tr {
        th {
          width: 32.4%;

          @include mq(sp) {
            width: 100%;
            padding: 30px 10px 15px;
            display: block;
          }
        }
        td {
          @include mq(sp) {
            width: 100%;
            padding: 0 10px 30px;
            display: block;
          }
        }
      }
    }
  }
  &__sec03 {
    .p-technique__head {
      background-image: url(#{$img_path}/technique/technique-sec03-bg-pc.jpg);

      @include mq(1024) {
        background-image: url(#{$img_path}/technique/technique-sec03-bg-sp.jpg);
      }
    }
    &__img-list {
      margin-top: 45px;
      @include flex("", "fs");

      @include mq(1097) {
        justify-content: space-between;
      }

      @include mq(sp) {
        margin-top: 0;
      }

      li {
        text-align: center;

        @include mq(1024) {
          width: 90.4%;
        }

        @include mq(sp) {
          width: 100%;
          margin-top: 30px;
        }

        figure {
          figcaption {
            @include fz(18);
            margin-top: 20px;

            @include mq(sp) {
              @include fz(13);
              margin-top: 15px;
            }
          }
        }
        + li {
          margin-left: 9.38%;

          @include mq(1097) {
            margin-left: 1.8%;
          }

          @include mq(1024) {
            margin-top: 30px;
          }
        }
      }
    }
  }
  div[id^="sec0"] {
    @include mq(sp) {
      @include wrapper-box;
    }
  }

  // 実績紹介
  &__results {
    @include inner-box;
    color: #fff;
    background: $col_sub;
    padding: 55px 0 70px;
    position: relative;

    @include mq(1024) {
      padding-right: 30px;
      padding-left: 30px;
    }

    @include mq(sp) {
      margin-top: 60px;
      padding: 60px 15px 70px;
    }

    .c-ttl {
      @include fz(40);
      margin-bottom: 12px;
      padding-left: 50px;

      @include mq(1024) {
        padding-left: 0;
      }

      @include mq(sp) {
        @include fz(44);
        margin-bottom: 10px;
      }
    }
    &__list {
      margin-bottom: 50px;

      @include mq(sp) {
        margin-bottom: 20px;
      }

      li {
        margin-top: 0;
        @include flex("", "fs");

        @include mq(1024) {
          display: block;
        }

        > div {
          flex: 1;
        }
        > figure {
          width: 36.33%;
          margin-right: 3.52%;
          margin-left: 2.97%;

          @include mq(1024) {
            width: 100%;
            text-align: center;
            margin: 40px 0 0;
          }

          @include mq(sp) {
            margin-top: 20px;
          }

          figcaption {
            @include fz(20);
            font-weight: 500;

            @include mq(sp) {
              @include fz(13);
            }
          }
        }
      }
      &__txt {
        padding-left: 50px;

        @include mq(1024) {
          padding-left: 0;
        }

        + p {
          margin-top: 2.13em;

          @include mq(sp) {
            margin-top: 1.5em;
          }
        }
      }
    }
    .c-ttl02 {
      @include fz(40);
      border-bottom-color: #fff;
      margin-bottom: 45px;
      padding: 0 0 50px 50px;

      @include mq(1024) {
        padding-left: 0;
      }

      @include mq(sp) {
        @include fz(23);
        margin-bottom: 25px;
        padding-bottom: 20px;
      }
    }
    > .p-technique__results__list__txt {
      padding-right: 50px;

      @include mq(1024) {
        padding-right: 0;
      }
    }
    &__bottom {
      color: $col_sub;
      background: #fff;
      margin: 50px 50px 0;
      @include flex("", "fs");

      @include mq(1024) {
        margin-right: 0;
        margin-left: 0;
        display: block;
      }

      img {
        @include mq(1024) {
          width: 100%;
        }
      }

      > div {
        padding: 35px 45px 25px 25px;
        flex: 1;

        @include mq(1024) {
          padding: 35px 25px 25px;
        }

        @include mq(sp) {
          padding: 35px 20px;
        }
      }
      &__ttl {
        @include fz(30);
        font-weight: 500;
        margin-bottom: 5px;

        @include mq(sp) {
          @include fz(23);
        }
      }
      &__detail {
        border-bottom: 1px solid $col_sub;
        margin-bottom: 15px;
        padding-bottom: 15px;
      }
    }
  }
}

// もの作りに安心を
&apartment {
  @include wrapper-box;
  margin: 115px 0 120px;

  @include mq(sp) {
    margin: 50px 0 70px;
    padding: 0;
  }

  &__ttl {
    @include fz(46);
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;

    @include mq(sp) {
      @include fz(28);
      line-height: 1.31;
      margin-bottom: 30px;
    }

    br {
      @include mq(pc) {
        display: none;
      }
    }
  }
  &__lead {
    @include fz(18);
    line-height: 1.78;
    text-align: center;

    @include mq(sp) {
      @include fz(15);
      line-height: 1.5;
      text-align: left;
    }

    br {
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__inner {
    @include inner-box;

    @include mq(sp) {
      padding: 0 15px;
    }
  }
  &__flow {
    @include inner-box(1080);
    margin-top: 60px;
    position: relative;
    @include flex("", "");

    @include mq(sp) {
      margin-top: 40px;
      display: block;
    }

    &__list {
      width: 46.3%;

      @include mq(sp) {
        width: 100%;
      }

      li {
        position: relative;
        @include flex("c", "c");
        flex-direction: column;
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 13.5px 0 13.5px;
          margin: 0 auto;
          position: absolute;
          right: 0;
          bottom: -30px;
          left: 0;
          display: inline-block;

          @include mq(sp) {
            border-width: 10px 10px 0 10px;
          }
        }
        &:first-child {
          margin-bottom: 172px;

          @include mq(sp) {
            margin-bottom: 53px;
          }

          &:after {
            bottom: -43px;

            @include mq(sp) {
              bottom: -32px;
            }
          }
        }
        + li {
          margin-top: 50px;

          @include mq(sp) {
            margin-top: 40px;
          }
        }
        &:nth-child(2) {
          @include mq(sp) {
            margin-bottom: -20px;
            &:after {
              content: none;
            }
          }
        }
        &:nth-child(6) {
          @include mq(sp) {
            margin-top: 60px;
          }
        }
        &:nth-child(7) {
          margin-bottom: 205px;

          @include mq(sp) {
            margin-bottom: 0;
          }
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
      }
      &__type {
        width: 100%;
        @include fz(32);
        font-weight: 500;
        text-align: center;
        color: #fff;
        padding: 13px 0 14px;

        @include mq(sp) {
          @include fz(23);
          padding: 11px 0;
        }
      }
      &__ttl {
        @include fz(40);
        font-weight: 500;

        @include mq(sp) {
          @include fz(30);
        }
      }
      &__txt {
        width: 82%;
        min-height: 3.2em;
        @include fz(15);
        line-height: 1.6;
        margin-top: 5px;
        @include flex("", "c");

        @include mq(sp) {
          width: 83%;
          min-height: unset;
          @include fz(14);
        }
      }
      &__img {
        width: 82%;
        margin-top: 8px;

        @include mq(sp) {
          width: 83%;
        }
      }

      &--building {
        @extend .p-apartment__flow__list;
        li {
          &:after {
            border-color: rgba($col_main, 0.2) transparent transparent transparent;
          }
        }
        .p-apartment__flow__list {
          &__type {
            background: $col_main;
          }
          &__ttl {
            color: $col_main;
          }
        }
      }
      &--engineering {
        @extend .p-apartment__flow__list;
        @include mq(sp) {
          margin-top: 60px;
        }

        li {
          &:after {
            border-color: rgba(#ba2527, 0.2) transparent transparent transparent;
          }
        }
        .p-apartment__flow__list {
          &__type {
            background: #ba2527;
          }
          &__ttl {
            color: #ba2527;
          }
        }
      }
    }
    &__same {
      width: 100%;
      @include fz(36);
      font-weight: 500;
      text-align: center;
      background: #fff;
      border: 1px solid $col_sub;
      padding: 9px 0 10px;
      position: absolute;
      left: 0;

      @include mq(sp) {
        @include fz(27);
        padding: 7px 0;
        position: static;
      }

      &--01 {
        @extend .p-apartment__flow__same;
        top: 5.2%;
      }
      &--02 {
        @extend .p-apartment__flow__same;
        top: 47.7%;
        &:before,
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 14px 13.5px 0 13.5px;
          position: absolute;
          bottom: -40px;
          display: inline-block;
        }
        &:before {
          border-color: rgba($col_main, 0.2) transparent transparent transparent;
          left: 21.9%;

          @include mq(800) {
            left: 21.3%;
          }
        }
        &:after {
          border-color: rgba(#ba2527, 0.2) transparent transparent transparent;
          right: 21.7%;

          @include mq(800) {
            right: 20.7%;
          }
        }
      }
    }
  }
  &__auth {
    margin-top: 80px;

    @include mq(sp) {
      margin-top: 60px;
    }

    .c-table {
      margin-top: 70px;

      @include mq(sp) {
        margin-top: 35px;
      }

      tr {
        th {
          width: 100%;
          padding: 20px 10px 5px;
          display: block;
        }
        td {
          width: 100%;
          padding: 0 10px 20px;
          display: block;
        }
      }
    }
    &__note {
      @include fz(14);
      line-height: 1.86;
      margin-top: 32px;

      @include mq(sp) {
        @include fz(12);
        line-height: 1.5;
      }
    }
  }
}

// 歴史と実績の多田建設
&history-results {
  margin: 115px 0 140px;

  @include mq(sp) {
    margin: 60px 0 70px;
  }

  &__ttl {
    @include fz(46);
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;

    @include mq(sp) {
      @include fz(28);
      line-height: 1.31;
      margin-bottom: 30px;
    }

    br {
      @include mq(pc) {
        display: none;
      }
    }
  }
  &__lead {
    @include fz(18);
    line-height: 1.78;
    text-align: center;

    @include mq(sp) {
      @include fz(15);
      line-height: 1.5;
      text-align: left;
      padding: 0 15px;
    }

    br {
      @include mq(sp) {
        display: none;
      }
    }
  }
  &__era {
    @include wrapper-box;
    padding-top: 85px;
    position: relative;
    z-index: 0;

    @include mq(sp) {
      padding-top: 0;
    }

    &__inner {
      @include inner-box;
      padding-bottom: 100px;
      position: relative;

      @include mq(sp) {
        padding-bottom: 0;
      }

      &:before {
        content: "";
        width: 1px;
        height: 100%;
        background: $col_sub;
        position: absolute;
        top: 0;
        left: 145px;
        display: inline-block;

        @include mq(1024) {
          content: none;
        }
      }
    }
    &__list {
      > li {
        @include flex("", "fs");

        @include mq(1024) {
          display: block;
        }

        + li {
          margin-top: 90px;

          @include mq(sp) {
            margin-top: 0;
          }
        }
        &:nth-child(even) {
          .p-history-results__era__list__inner {
            flex-direction: row-reverse;
          }
        }
      }
      &__year {
        @include fz(32);
        line-height: 1;
        color: $col_main;
        margin: 12px 3.45% 0 0;

        @include mq(1024) {
          margin-right: 0;
        }

        @include mq(sp) {
          @include fz(28);
          margin-top: 60px;
        }

        span {
          font-weight: 500;
          padding-right: 22px;
          position: relative;
          display: inline-block;

          @include mq(1024) {
            padding-right: 0;
          }

          &:after {
            content: "";
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: $col_main;
            margin: auto 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: inline-block;

            @include mq(1024) {
              content: none;
            }
          }
        }
      }
      &__inner {
        @include flex("", "");
        flex: 1;

        @include mq(1024) {
          display: block;
        }

        > div {
          width: 52.19%;

          @include mq(1024) {
            width: 100%;
          }
        }
      }
      &__ttl {
        @include fz(36);
        font-weight: 500;
        color: $col_main;

        @include mq(sp) {
          @include fz(28);
        }
      }
      &__txt {
        @include fz(16);
        line-height: 2;
        margin-top: 20px;

        @include mq(sp) {
          @include fz(15);
          line-height: 1.5;
          margin-top: 15px;
        }
      }
      &__thumb {
        @include flex("", "fs");
        li {
          width: 22.4%;
          margin: 25px 3.467% 0 0;

          @include mq(sp) {
            margin-top: 20px;
          }

          figure {
            cursor: pointer;
            img {
              opacity: 0.5;
              transition: opacity 0.3s;
            }
            figcaption {
              @include fz(14);
              font-weight: 500;

              @include mq(sp) {
                @include fz(10);
                margin-top: 3px;
              }
            }
            &:hover {
              img {
                opacity: 1;
              }
            }
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
          &.is-current {
            figure {
              img {
                opacity: 1;
              }
            }
          }
        }
      }
      &__main {
        width: 44.463%;

        @include mq(1024) {
          width: 100%;
          text-align: center;
        }

        img {
          @include mq(1024) {
            margin: 30px auto 0;
            display: block;
          }

          @include mq(sp) {
            margin-top: 20px;
          }
        }
      }
    }
    &__bg {
      width: 78.75%;
      position: absolute;
      z-index: -1;

      &--01 {
        top: 0;
        left: 0;
        @extend .p-history-results__era__bg;
      }
      &--02 {
        top: 20.21%;
        right: 0;
        @extend .p-history-results__era__bg;
      }
      &--03 {
        top: 45%;
        left: 0;
        @extend .p-history-results__era__bg;
      }
      &--04 {
        top: 65.7%;
        right: 0;
        @extend .p-history-results__era__bg;
      }
    }
  }
}
// -----------------------------------------

// お問い合わせ
&inquiry {
  @include wrapper-box;
  margin: 115px 0 140px;

  @include mq(sp) {
    margin: 80px 0 70px;
  }

  &__inner {
    @include inner-box(1200);
  }
  &__lead {
    @include fz(18);
    line-height: 1.78;
    text-align: center;

    @include mq(sp) {
      @include fz(16);
      line-height: inherit;
      text-align: left;
    }
  }
  &__realproperty {
    @include fz(24);
    text-align: center;
    margin-top: 35px;

    @include mq(sp) {
      @include fz(20);
      margin-top: 25px;
    }

    a {
      color: $col_main;
      position: relative;
      display: inline-block;
      &:after {
        content: "";
        width: 55px;
        height: 1px;
        background: $col_main;
        margin: 0 auto;
        position: absolute;
        right: 0;
        bottom: -12px;
        left: 0;
        display: inline-block;
        transition: width 0.3s;
      }
      &:hover {
        &:after {
          width: 165px;
        }
      }
    }
  }
  &__step {
    margin-top: 75px;
    @include flex("", "c");
    overflow: hidden;

    @include mq(sp) {
      margin-top: 45px;
    }

    li {
      @include fz(16);
      line-height: 1;
      color: $col_sub;
      background: #fff;
      border: 2px solid $col_sub;
      border-right: none;
      padding: 14px 20px 13px 25px;
      position: relative;
      z-index: 1;

      @include hack(gteIE11) {
        padding: 18px 20px 9px 25px;
      }

      @include mq(1149) {
        padding-right: 5px;
        padding-left: 10px;
      }

      @include mq(sp) {
        @include fz(13);
        border-width: 1px;
        padding: 11px 10px 10px 15px;
      }

      + li {
        margin-left: 50px;

        @include mq(1149) {
          margin-left: 40px;
        }

        @include mq(sp) {
          margin-left: 24px;
        }
      }
      &:last-child {
        border-right: 2px solid $col_sub;

        @include mq(sp) {
          border-right-width: 1px;
        }

        &:before,
        &:after {
          content: none;
        }
      }
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 33px 0 33px 30px;
        border-color: transparent transparent transparent #fff;
        margin: auto 0;
        position: absolute;
        top: 0;
        right: -29.5px;
        bottom: 0;
        z-index: -1;
        display: inline-block;

        @include mq(sp) {
          border-width: 26.5px 0 26.5px 17px;
          right: -17px;
        }
      }
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 35px 0 35px 32px;
        border-color: transparent transparent transparent $col_sub;
        margin: auto 0;
        position: absolute;
        top: 0;
        right: -32px;
        bottom: 0;
        z-index: -2;
        display: inline-block;

        @include mq(sp) {
          border-width: 27.5px 0 27.5px 18px;
          right: -18px;
        }
      }
      &.p-inquiry__step__current {
        color: #fff;
        background: $col_sub;
        &:before {
          content: none;
        }
      }
    }
    span {
      @include fz(20);
      margin-top: 3px;
      display: inline-block;

      @include mq(1149) {
        @include fz(18);
      }

      @include mq(sp) {
        @include fz(16);
      }

      &:before {
        content: "お問い合わせ";

        @include mq(sp) {
          content: none;
        }
      }
    }
  }
  &__form {
    background: #f7f7f7;
    margin-top: 60px;
    padding: 55px 40px;

    @include mq(sp) {
      margin-top: 40px;
      padding: 30px 15px 50px;
    }

    &__table {
      width: 100%;
      tr {
        @include mq(sp) {
          width: 100%;
          display: block;
        }

        th {
          @include fz(20);
          text-align: left;
          padding: 30px 25px 30px 0;
          vertical-align: top;
          @include flex("c", "");

          @include mq(sp) {
            @include fz(16);
            margin-top: 20px;
            padding: 0;
            justify-content: flex-start;
          }

          span {
            @include fz(10);
            color: #fff;
            background: $col_main;
            padding: 2px 9px;
            display: inline-block;

            @include hack(gteIE11) {
              padding: 0.5em 9px 0;
            }

            @include mq(sp) {
              margin-left: 15px;
              padding: 1px 6px;
            }
          }
        }
        td {
          width: 76%;
          padding: 25px 0;
          vertical-align: middle;

          @include mq(1415) {
            width: 66%;
          }

          @include mq(sp) {
            width: 100%;
            margin-top: 15px;
            padding: 0;
            display: block;
          }

          input {
            border: none;
            &[type="text"],
            &[type="email"] {
              width: 100%;
              @include fz(18);
              padding: 7px 20px;

              @include hack(gteIE11) {
                padding: 11px 20px 3px;
              }

              @include mq(sp) {
                @include fz(16);
                padding: 10px;
              }

              &[name^="tel"] {
                margin: 0 5px;
                padding: 7px 20px;

                @include hack(gteIE11) {
                  padding: 11px 20px 3px;
                }

                &:first-child {
                  margin-left: 0;
                }

                @include mq(sp) {
                  padding: 10px;
                }
              }
            }
            &[type="file"] {
              @include fz(16);
              outline: none;

              @include mq(sp) {
                @include fz(14);
              }
            }
          }
          textarea {
            width: 100%;
            min-height: 165px;
            padding: 7px 20px;
            @include fz(18);
            border: none;
            resize: vertical;

            @include hack(gteIE11) {
              padding: 11px 20px 3px;
            }

            @include mq(sp) {
              min-height: 140px;
              @include fz(16);
              padding: 10px;
            }
          }
          select {
            height: 2.5em;
            @include fz(18);
            border: none;
            padding: 7px 20px;

            @include mq(sp) {
              height: 3em;
              @include fz(16);
              padding: 10px;
            }
          }
        }
      }
    }
  }
  &__submit {
    width: 100%;
    margin-top: 40px;
    @include flex("", "c");

    @include mq(sp) {
      align-items: center;
      flex-direction: column-reverse;
    }

    button {
      padding: 0;
      span {
        width: 100%;
        color: $col_sub;
        padding: 13px 0;
        position: relative;
        z-index: 0;
        display: inline-block;
        transition: color 0.3s;

        @include mq(sp) {
          padding: 14px 0;
        }

        &:after {
          content: "";
          width: 120%;
          height: 100%;
          background: $col_sub;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform-origin: right top;
          transform: skewX(-30deg) scale(0, 1);
          transition: transform 0.3s;
        }
        &:hover {
          color: #fff;
          &:after {
            transform-origin: left top;
            transform: skewX(-30deg) scale(1, 1);
          }
        }
      }
      + button {
        @include mq(sp) {
          margin: 0 0 25px 0;
        }
      }
    }
  }

  // レイアウト調整
  .mw_wp_form {
    &_confirm {
      .p-inquiry__form__table {
        td {
          @include fz(20);

          @include mq(sp) {
            @include fz(16);
          }
        }
      }
    }
    .error {
      @include fz(16);
    }
  }
}

// 個人情報保護方針
&privacy {
  @include wrapper-box;
  margin-top: 65px;
  margin-bottom: 110px;

  @include mq(sp) {
    margin-bottom: 45px;
  }

  &__inner {
    @include inner-box;
  }
  &__ttl {
    @include fz(26);
    font-weight: 500;
    color: $col_main;
    margin: 60px 0 25px;

    @include mq(sp) {
      @include fz(24);
    }
  }
  &__txt {
    @include fz(16);
    line-height: 1.88;
    margin-bottom: 1.88em;

    @include mq(sp) {
      @include fz(15);
      line-height: 1.6;
      margin-bottom: 1.6em;
    }
  }
  &__mh {
    @include fz(20);
    font-weight: 500;
    margin: 60px 0 10px;
    position: relative;

    @include mq(sp) {
      margin-top: 40px;
    }

    &:before {
      content: "";
      width: 16px;
      height: 1px;
      vertical-align: middle;
      background: $col_sub;
      margin-right: 8px;
      display: inline-block;
    }
  }
}
// -----------------------------------------

// ご利用にあたって
&use {
  @include wrapper-box;
  margin-top: 65px;
  margin-bottom: 110px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }
  &__ttl {
    @include fz(26);
    font-weight: 500;
    color: $col_main;
    margin: 60px 0 25px;

    @include mq(sp) {
      @include fz(24);
      margin: 40px 0 15px;
    }
  }
  &__txt {
    @include fz(16);
    line-height: 1.88;
    margin-bottom: 1.88em;

    @include mq(sp) {
      @include fz(15);
      line-height: 1.6;
      margin-bottom: 1.6em;
    }

    a {
      color: $col_main;
      display: inline-block;
      &:after {
        content: "";
        width: 12px;
        height: 12px;
        background: url(#{$img_common_path}/cmn-blank-ico01.svg) 0 0 no-repeat;
        background-size: 100% 100%;
        margin-left: 5px;
        display: inline-block;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__mh {
    @include fz(20);
    font-weight: 500;
    margin: 60px 0 10px;
    position: relative;

    @include mq(sp) {
      margin-top: 40px;
    }

    &:before {
      content: "";
      width: 16px;
      height: 1px;
      vertical-align: middle;
      background: $col_sub;
      margin-right: 8px;
      display: inline-block;
    }
  }
  &__list {
    li {
      span {
        font-weight: 500;
      }
    }
  }
  &__plugin {
    @include flex("c", "fs");

    @include mq(sp) {
      display: block;
    }

    .p-use__txt {
      margin: 0 0 0 20px;
      flex: 1;

      @include mq(sp) {
        margin: 10px 0 0 0;
      }
    }
  }
}
// -----------------------------------------

// サイトマップ
&sitemap {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }
  &__list {
    @include flex("", "");

    @include mq(sp) {
      margin-top: 80px;
      display: block;
    }

    li {
      width: 33%;
      margin-top: 100px;

      @include mq(sp) {
        width: 100%;
        margin-top: 50px;
      }

      dl {
        dt {
          @include fz(24);
          font-weight: 500;
          color: $col_main;
          margin-bottom: 10px;

          @include mq(1024) {
            @include fz(20);
          }

          &:before {
            content: "";
            width: 40px;
            height: 1px;
            vertical-align: middle;
            background: $col_main;
            margin-right: 10px;
            display: inline-block;

            @include mq(tab) {
              width: 30px;
            }
          }
        }
        dd {
          @include fz(16);
          line-height: 1.88;
          margin-left: 60px;

          @include mq(1024) {
            @include fz(14);
          }

          @include mq(tab) {
            margin-left: 50px;
          }

          a {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
// -----------------------------------------

// 404
&nf {
  @include wrapper-box;
  margin-top: 165px;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-top: 120px;
    margin-bottom: 70px;
  }

  .l-breadcrumb {
    padding: 0;
  }

  .l-page-ttl {
    max-width: 1280px;
    padding: 0;
  }

  &__inner {
    @include inner-box;
  }
}
// -----------------------------------------

// 株式会社シンコー
&shinko {
  &__sec-ttl {
    @include fz(32);
    font-weight: 500;
    line-height: 1.3;
    text-align: center;

    @include mq(sp) {
      @include fz(23);
    }

    span {
      @include fz(14);
      color: #004ea2;

      @include mq(sp) {
        @include fz(13);
      }
    }
  }
  &__mv {
    img {
      width: 100%;
    }
  }
  &__intro {
    @include wrapper-box;
    margin-top: 80px;
    position: relative;
    z-index: 0;

    @include mq(sp) {
      margin-top: 60px;
      padding: 0;
    }

    &:before {
      content: "";
      width: vwpc(655);
      height: 100%;
      background: #eff2f5;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      display: inline-block;

      @include mq(sp) {
        width: 81.875%;
      }
    }
    &__inr {
      @include inner-box;
      padding: vwpc(80) 0 vwpc(150);
      position: relative;

      @include mq(sp) {
        padding: 50px 0 0;
      }

      > div {
        width: vwpc(565);
        background: #fff;
        padding: vwpc(109) vwpc(43) vwpc(129);
        position: absolute;
        bottom: 80px;
        left: 0;

        @include mq(sp) {
          width: 92.5%;
          margin: 0 auto;
          padding: 50px 0 50px 20px;
          position: static;
        }
      }
    }
    &__img {
      text-align: right;

      @include mq(sp) {
        margin-top: 50px;
      }

      img {
        width: vwpc(860);

        @include mq(sp) {
          width: 100%;
        }
      }
    }
    &__ttl {
      font-size: vwpc(28);
      font-weight: 500;
      line-height: 1.71;
      color: #004ea2;
      margin-bottom: vwpc(20);

      @include mq(sp) {
        @include fz(20);
        line-height: 1.3;
        margin-bottom: 10px;
      }
    }
    &__lead {
      font-size: vwpc(16);
      line-height: 2;

      @include mq(sp) {
        @include fz(15);
        line-height: 1.5;
      }
    }
  }
  &__business {
    @include wrapper-box;
    margin-top: 100px;
    padding-bottom: 130px;
    position: relative;
    z-index: 0;

    @include mq(sp) {
      margin-top: 60px;
      padding-bottom: 50px;
    }

    &:after {
      content: "";
      width: 100%;
      height: 54.21%;
      background: #eff2f5;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      display: inline-block;

      @include mq(sp) {
        height: 50%;
      }
    }
    &__ttl {
      margin-bottom: 40px;

      @include mq(sp) {
        margin-bottom: 25px;
      }
    }
    &__inr {
      @include inner-box;
    }
    &__list {
      @include flex("", "");

      @include mq(sp) {
        display: block;
      }

      li {
        width: 46.875%;
        text-align: center;

        @include mq(sp) {
          width: 100%;
          + li {
            margin-top: 35px;
          }
        }
      }
      &__ttl {
        @include fz(20);
        font-weight: 500;
        margin-top: 20px;

        @include mq(sp) {
          @include fz(16);
        }
      }
    }
  }
  &__about {
    @include wrapper-box;
    margin-top: 110px;

    @include mq(sp) {
      margin-top: 80px;
    }

    &__inr {
      @include inner-box;
      @include flex("", "");

      @include mq(sp) {
        display: block;
      }
    }
    &__lt,
    &__gt {
      width: 46.88%;

      @include mq(sp) {
        width: 100%;
      }
    }
    &__ttl {
      @include fz(26);
      font-weight: 500;
      color: #004ea2;
      margin-bottom: 10px;

      @include mq(sp) {
        @include fz(18);
      }

      &-03 {
        margin-top: 50px;
      }
    }
    &__map {
      width: 100%;
      margin: 40px 0 0;
    }
    &__access {
      @include fz(16);
      margin: 10px 0 50px;

      @include mq(sp) {
        @include fz(15);
      }
    }
    &__table {
      width: 100%;
      tr {
        border-bottom: 1px solid #eee;

        @include mq(sp) {
          display: block;
        }

        th {
          width: 33.33%;
          @include fz(16);
          font-weight: 500;
          text-align: left;
          vertical-align: middle;
          padding: 15px 0;

          @include mq(sp) {
            width: 100%;
            @include fz(15);
            padding-bottom: 0;
            display: block;
          }
        }
        td {
          @include fz(16);
          line-height: 1.88;
          vertical-align: middle;
          padding: 15px 0;

          @include mq(sp) {
            width: 100%;
            @include fz(15);
            line-height: 2;
            padding-top: 0;
            display: block;
          }
        }
      }
    }
  }
  &__requirements {
    @include wrapper-box;
    margin-top: 80px;

    @include mq(sp) {
      margin-top: 60px;
    }

    &__inr {
      @include inner-box(1000);
    }
    &__table {
      width: 100%;
      tr {
        border-bottom: 1px solid #eee;

        @include mq(sp) {
          display: block;
        }

        th {
          width: 11.5%;
          @include fz(16);
          font-weight: 500;
          line-height: 1.88;
          text-align: left;
          vertical-align: top;
          padding: 15px 0;

          @include mq(sp) {
            width: 100%;
            @include fz(15);
            padding-bottom: 0;
            display: block;
          }
        }
        td {
          @include fz(16);
          line-height: 1.88;
          padding: 15px 0;

          @include mq(sp) {
            width: 100%;
            @include fz(15);
            line-height: 2;
            padding-top: 0;
            display: block;
          }
        }
      }
    }
  }

  // 株式会社シンコー - お問い合わせ
  &-contact {
    &__mv {
      height: 300px;
      background: url(#{$img_path}/shinko/shinko-contact-bg.jpg) center top no-repeat;
      background-size: cover;
      margin-bottom: 30px;
      @include flex("c", "c");

      &__ttl {
        @include fz(32);
        font-weight: 500;
        line-height: 1;
        text-align: center;
        color: #fff;

        @include mq(sp) {
          @include fz(28);
        }

        span {
          @include fz(14);
          margin-bottom: 10px;
          display: inline-block;

          @include mq(sp) {
            @include fz(12);
          }
        }
      }
    }
    .l-breadcrumb {
      &__list {
        margin-bottom: 50px;
        justify-content: flex-end;
      }
    }
    &__inr {
      @include inner-box(910);
      padding: 0 30px;

      @include mq(sp) {
        padding: 0 15px;
      }

      .mw_wp_form {
        .p-inquiry {
          &__form {
            background: none;
            margin-top: 30px;
            padding: 0;
            &__table {
              tr {
                border-bottom: 1px solid rgba(#eee, 0.8);

                @include mq(1024) {
                  width: 100%;
                  display: block;
                }
                th {
                  @include fz(16);
                  padding-top: 37px;

                  @include mq(1024) {
                    width: 100%;
                    margin-top: 20px;
                    padding: 0;
                    display: block;
                  }

                  span {
                    @include mq(1024) {
                      margin-left: 15px;
                    }
                  }
                }
                td {
                  width: 75%;
                  padding: 28px 0;

                  @include mq(1024) {
                    width: 100%;
                    margin: 15px 0 20px;
                    padding: 0;
                    display: block;
                  }

                  textarea,
                  input {
                    border: 1px solid #d0d0d0;
                  }

                  .error {
                    @include fz(16);
                  }
                }
              }
            }
          }
          &__submit {
            button {
              width: 300px;
              @include fz(16);
              background: $col_main;
              border: 1px solid $col_main;
              transition: background 0.3s;
              margin: 0;

              @include mq(sp) {
                width: 70%;
              }

              + button {
                margin-left: 30px;

                @include mq(sp) {
                  margin: 0 0 25px 0;
                }
              }

              span {
                color: #fff;
                padding: 23px 0;
                transition: color 0.3s;

                @include mq(sp) {
                  padding: 20px 0;
                }

                &:after {
                  content: none;
                }
              }
              &:hover {
                background: #fff;
                span {
                  color: $col_main;
                }
              }
            }
          }
        }
        &_confirm {
          .p-inquiry {
            &__form {
              &__table {
                tr {
                  td {
                    @include fz(16);
                  }
                }
              }
            }
          }
        }
      }
    }
    &__lead {
      @include fz(16);
      text-align: center;

      @include mq(sp) {
        text-align: left;
      }
    }
    &__return {
      width: 300px;
      @include fz(16);
      background: $col_main;
      border: 1px solid $col_main;
      transition: background 0.3s;

      @include mq(sp) {
        width: 70%;
      }

      a {
        color: #fff;
        padding: 23px 0;
        transition: color 0.3s;

        @include mq(sp) {
          padding: 20px 0;
        }

        &:after {
          content: none;
        }
      }
      &:hover {
        background: #fff;
        a {
          color: $col_main;
        }
      }
    }
  }
}
