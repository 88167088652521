&table {
  width: 100%;
  @include fz(16);

  @include mq(sp) {
    @include fz(14);
  }

  tr {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
    &:nth-child(odd) {
      background: #f9f9f9;
    }
    th {
      width: 21.88%;
      line-height: 1.88;
      text-align: left;
      vertical-align: middle;
      padding: 25px 0 25px 15px;

      @include mq(sp) {
        width: 29%;
        padding: 15px 5px 15px;
      }
    }
    td {
      line-height: 1.88;
      vertical-align: middle;
      padding: 25px 15px 25px 0;

      @include mq(sp) {
        padding: 20px 5px 20px;
      }
    }
  }
}
