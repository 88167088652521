// お知らせ
&news {
  &__list {
    li {
      @include fz(16);
      border-bottom: 1px solid #d5d5d5;

      @include mq(sp) {
        @include fz(14);
      }

      a {
        width: 100%;
        padding: 37px 16px;
        display: inline-block;
        transition: background .1s;

        @include mq(sp) {
          padding: 25px 15px;
        }

        &:hover {
          background: rgba(#d5d5d5, .14);
        }
        article {
          @include flex("c", "fs");
        }
      }
    }
    &__cat {
      width: 90px;
      @include fz(13);
      line-height: 1;
      text-align: center;
      color: $col_main;
      border: 1px solid $col_main;
      margin: 0 28px;
      padding: 6px 0 5px;

      @include mq(sp) {
        width: 84px;
        @include fz(12);
        margin: 0 13px;
      }
    }
    &__ttl {
      flex: 1;

      @include mq(sp) {
        width: 100%;
        margin-top: 10px;
        flex: unset;
      }
    }
  }
}

&info {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }

  &__cat {
    @include inner-box;
    margin-top: 120px;
    margin-bottom: 110px;
    @include flex("", "c");

    @include mq(sp) {
      margin-top: 60px;
      margin-bottom: 30px;
      justify-content: flex-start;
    }

    li {
      width: 21.09%;
      @include fz(16);
      text-align: center;
      margin-right: 0;
      margin-left: 4.69%;

      @include mq(sp) {
        width: 31%;
        @include fz(13);
        margin-left: 3.125%;
      }

      @include mq(330) {
        @include fz(12);
      }

      &:nth-child(5n+1) {
        margin-left: 0;
      }
      a {
        width: 100%;
        height: 100%;
        padding: 13px 0;
        position: relative;
        display: inline-block;

        @include mq(sp) {
          border-width: 1px;
          padding: 12px 0;
        }
      }
    }
  }

  &--single {
    @extend .p-info;
    margin-bottom: 70px;
    padding: 0;

    &__ttl {
      @include fz(36);
      font-weight: 500;
      margin-bottom: 22px;

      @include mq(sp) {
        @include fz(25);
        margin-bottom: 10px;
      }
    }
    &__head {
      margin-bottom: 10px;
      @include flex("c", "fs");

      @include mq(sp) {
        margin-bottom: 5px;
      }

      .p-news__list__cat {
        width: auto;
        min-width: 110px;
        margin: 0 20px 5px 0;
        padding: 6px 5px 5px;

        @include mq(sp) {
          margin: 0 10px 10px 0;
        }
      }
      .p-news__list__date {
        @include fz(15);
        margin-bottom: 5px;

        @include mq(sp) {
          @include fz(13);
        }
      }
    }
    &__content {
      > p {
        @include fz(15);
        line-height: 1.8;
        margin-bottom: 2em;
        @include clearfix;

        @include mq(sp) {
          line-height: inherit;
        }
      }
      a {
        color: $col_main;
        &:hover {
          text-decoration: underline;
        }
      }
      img {
        &.alignleft {
          float: left;
        }
        &.alignright {
          float: right;
        }
        &.aligncenter {
          margin: 0 auto;
          display: block;
        }
      }
    }
  }
}
// -----------------------------------------


// 電子公告
&koukoku {
  @include wrapper-box;
  margin-bottom: 140px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  &__inner {
    @include inner-box;
  }
  &__years {
    @include fz(20);
    margin: 72px 0 30px;
    @include flex("", "fe");

    @include mq(sp) {
      @include fz(14);
      margin-top: 80px;
    }

    &__ttl {
      font-weight: 500;
      margin-right: 40px;

      @include mq(sp) {
        margin-right: 15px;
      }
    }
    &__list {
      @include flex("", "fs");
      li {
        font-weight: 500;
        a {
          &:hover {
            text-decoration: underline;
          }
        }
        + li {
          &:before {
            content: "/";
            margin: 0 1.3em;
            display: inline-block;
          }
        }
      }
    }
  }
}
// -----------------------------------------


// 実績紹介
&results {
  margin: 100px 0 140px;

  @include mq(sp) {
    margin: 60px 0 70px;
  }

  &__lead {
    @include fz(40);
    font-weight: 500;
    text-align: center;

    @include mq(sp) {
      @include fz(23);
    }
  }
  &__search {
    @include inner-box;
    color: #fff;
    background: $col_sub;
    margin-top: 75px;
    margin-bottom: 65px;
    padding: 45px;

    @include mq(tab) {
      padding: 45px 30px;
    }

    @include mq(sp) {
      margin-top: 60px;
      margin-bottom: 45px;
      padding: 0;
    }

    &__inner {
      @include flex("", "");

      @include mq(sp) {
        display: block;
      }
    }
    &__tax {
      &--type {
        width: 43.36%;

        @include mq(tab) {
          width: 65.36%;
        }

        @include mq(sp) {
          width: 100%;
        }
      }
      &--area {
        width: 21.01%;

        @include mq(tab) {
          width: 30.01%;
        }

        @include mq(sp) {
          width: 100%;
        }

        > div {
          @include mq(pc) {
            height: auto !important;
            padding: 0 !important;
            display: block !important;
          }

          @include mq(sp) {
            background: #4d4c4c;
            padding: 40px 15px;
            display: none;
          }
        }
      }
      &--year {
        width: 25.04%;

        @include mq(tab) {
          width: 100%;
          margin-top: 25px;
        }

        @include mq(sp) {
          width: 100%;
        }

        > div {
          @include mq(pc) {
            height: auto !important;
            padding: 0 !important;
            display: block !important;
          }

          @include mq(sp) {
            background: #4d4c4c;
            padding: 40px 15px;
            display: none;
          }
        }
      }
      &__ttl {
        @include fz(22);
        border-bottom: 1px solid #fff;
        margin-bottom: 15px;
        padding-bottom: 10px;

        @include mq(sp) {
          @include fz(18);
          text-align: center;
          border-top: 1px solid #fff;
          border-bottom: none;
          margin-bottom: 0;
          padding: 21px 0;
          position: relative;
          cursor: pointer;
          user-select: none;

          &:after {
            content: "";
            width: 12px;
            height: 12px;
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            margin: auto 0;
            position: absolute;
            top: -9px;
            right: 20px;
            bottom: 0;
            display: inline-block;
            transform: rotate(45deg);
          }
          &.is-active {
            &:after {
              top: 7px;
              transform: rotate(-135deg);
            }
          }
        }
      }
      &__inner {
        @include flex("", "");

        @include mq(pc) {
          height: auto !important;
          padding: 0 !important;
          display: flex !important;
        }

        @include mq(sp) {
          background: #4d4c4c;
          padding: 45px 15px 50px;
          display: none;
        }

        > div {
          &:first-child {
            flex: 1;

            @include mq(sp) {
              flex: unset;
              border-bottom: 1px solid #fff;
              margin-bottom: 40px;
              padding-bottom: 40px;
            }
          }
        }
      }
      &__parent {
        @include fz(18);
        margin-bottom: 5px;

        @include mq(sp) {
          @include fz(20);
          margin-bottom: 10px;
        }
      }
      &__list {
        @include flex("", "fs");
        li {
          @include fz(15);

          @include mq(1024) {
            @include fz(14);
          }

          label {
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            input {
              margin: 0 4px 0 0;
              cursor: inherit;
            }
          }
        }
        &--col1 {
          width: 100%;
          @extend .p-results__search__tax__list;
          flex-direction: column;

          @include mq(sp) {
            flex-direction: row;
          }

          li {
            width: 100%;

            @include mq(sp) {
              width: calc(100% / 2);
            }

            + li {
              margin-top: 25px;

              @include mq(sp) {
                margin-top: 0;
              }
            }
            &:nth-child(n+3) {
              @include mq(sp) {
                margin-top: 14px;
              }
            }
          }
        }
        &--col2 {
          @extend .p-results__search__tax__list;
          li {
            width: calc(100% / 2);
            &:nth-child(n+3) {
              margin-top: 25px;

              @include mq(sp) {
                margin-top: 14px;
              }
            }
          }
        }
        &--col3 {
          @extend .p-results__search__tax__list;
          li {
            width: calc(100% / 3);
            &:nth-child(n+4) {
              margin-top: 25px;

              @include mq(sp) {
                margin-top: 14px;
              }
            }

            @include mq(tab) {
              width: auto;
              margin-right: 35px;
              &:nth-child(n+4) {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
    &__submit {
      margin-top: 40px;
      @include flex("", "");

      @include mq(sp) {
        border-top: 1px solid #fff;
        margin-top: 0;
        display: block;
      }

      > div {
        flex: 1;
      }
      &__btn {
        @include mq(sp) {
          margin-top: 50px;
        }

        button {
          position: relative;
          @include flex("c", "c");
          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background: url(#{$img_common_path}/cmn-search-ico.svg) 0 0 no-repeat;
            background-size: 100% 100%;
            display: inline-block;
            transition: background .3s;

            @include mq(1024) {
              width: 12px;
              height: 12px;
            }
          }

          &:hover {
            &:before {
              background-image: url(#{$img_common_path}/cmn-search-ico02.svg);
            }
          }
          span {
            margin-left: 20px;
            display: inline-block;

            @include mq(1024) {
              margin-left: 8px;
            }
          }
        }
      }
      &__keywords {
        @include flex("c", "c");

        @include mq(1024) {
          width: 100%;
        }

        @include mq(sp) {
          width: 73.38%;
          margin: 0 auto;
          padding: 22px 0;
          display: block;
        }

        &__ttl {
          @include fz(16);
          margin-right: 30px;
          padding-right: 45px;
          position: relative;

          @include mq(1024) {
            @include fz(15);
            margin-right: 20px;
            padding-right: 20px;
          }

          @include mq(sp) {
            margin-right: 0;
            margin-bottom: 10px;
            padding-right: 0;
          }

          &:after {
            content: "";
            width: 1px;
            height: 48px;
            background: #fff;
            margin: auto 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            display: inline-block;
            transform: rotate(30deg);

            @include mq(sp) {
              content: none;
            }
          }
        }
        &__area {
          width: 100%;
          max-width: 224px;
          position: relative;
          flex: 1;

          @include mq(1024) {
            max-width: unset;
          }

          input {
            &[type="search"] {
              appearance: none;
              width: 100%;
              height: 28px;
              border: none;
              border-radius: 0;

              @include mq(sp) {
                height: 36px;
              }
            }
          }
        }
      }
    }
  }
  &__inner {
    @include inner-box(1340);
    padding: 0 30px;

    @include mq(sp) {
      padding: 0 15px;
    }
  }
  &__count {
    @include fz(24);
    font-weight: 500;

    @include mq(sp) {
      @include fz(17);
    }

    span {
      @include fz(39);
      font-weight: 500;
      margin-left: .2em;
      display: inline-block;

      @include mq(sp) {
        @include fz(30);
      }
    }
  }
  &__list {
    margin-top: 35px;
    @include flex("", "fs");

    @include mq(sp) {
      margin-top: 20px;
    }

    li {
      width: 22.66%;
      margin-right: 3.12%;

      @include mq(sp) {
        width: 46.62%;
        margin-right: 6.76%;
      }

      a {
        width: 100%;
        @include hov-alpha;
        display: inline-block;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 70px;

        @include mq(sp) {
          margin-top: 0;
        }
      }
      &:nth-child(even) {
        @include mq(sp) {
          margin-right: 0;
        }
      
      }&:nth-child(n+3) {
        @include mq(sp) {
          margin-top: 45px;
        }
      }
    }
    &__thumb {
      height: 250px;
      overflow: hidden;

      @include mq(sp) {
        height: 150px;
      }

      > p {
        height: 100%;
        text-align: center;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__ttl {
      @include fz(18);
      font-weight: 500;
      margin-top: 18px;

      @include mq(sp) {
        @include fz(13);
        margin-top: 8px;
      }
    }
    &__place {
      @include fz(15);
      margin-top: 8px;

      @include mq(sp) {
        @include fz(11);
        margin-top: 3px;
      }
    }
    &__year {
      @include fz(15);

      @include mq(sp) {
        @include fz(11);
      }
    }
  }

  &--single {
    &__txt {
      margin-bottom: 15px !important;
    }
    &__slide {
      &__main {
        height: 630px;
        overflow: hidden;

        @include mq(sp) {
          height: 245px;
        }

        li {
          height: 100%;
        }
        .slick-slide img {
          width: 100%;
          height: 100%;
          margin: auto;
          object-fit: contain;
        }
      }
      &__thumb {
        margin-top: 25px;
        @include flex("", "fs");

        @include mq(sp) {
          margin-top: 15px;
        }

        li {
          width: 18.38%;
          height: 115px;
          margin-right: 2.1%;
          cursor: pointer;
          overflow: hidden;

          @include mq(sp) {
            width: 22.97%;
            height: 55px;
            margin-right: 2.7%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .4;
            transition: opacity .3s;
          }
          &.is-current {
            img {
              opacity: 1;
            }
          }
        }
      }
    }
    &__data {
      margin-top: 72px;

      @include mq(sp) {
        margin-top: 62px;
      }

      &__ttl {
        @include fz(26);
        font-weight: 500;
        margin-bottom: 8px;

        @include mq(sp) {
          @include fz(18);
          margin-bottom: 10px;
        }
      }
      &__table {
        tr {
          @include mq(sp) {
            @include flex("", "fs");
            + tr {
              border-top: none;
            }
          }

          th {
            width: 13%;
            padding-left: 25px;

            @include mq(sp) {
              width: 40%;
              background: #fff;
              padding: 25px 10px 25px 35px;
              &:nth-child(1) {
                background: #f9f9f9;
              }
            }
          }
          td {
            width: 37%;
            padding: 25px 15px 25px;

            @include mq(sp) {
              width: 60%;
              background: #fff;
              &:nth-child(2) {
                background: #f9f9f9;
              }
              + th {
                border-top: 1px solid #dedede;
                + td {
                  border-top: 1px solid #dedede;
                }
              }
            }
          }
        }
      }
    }
  }
}
// -----------------------------------------


// CSR
&csr {
  @include wrapper-box;
  margin-bottom: 130px;

  @include mq(sp) {
    margin-bottom: 70px;
  }

  .p-page-link {
    max-width: 1280px;
    padding: 0;

    @include mq(1024) {
      justify-content: space-between;
    }

    li {
      margin-right: 2.34%;

      @include mq(tab) {
        width: 24%;
        margin-right: 0;
      }

      @include mq(sp) {
        width: 49%;
        @include fz(14);
        margin-right: 0;
      }

      a {
        padding: 21px 0;

        @include mq(sp) {
          padding: 14px 0;
        }
      }
      &:nth-child(n+3) {
        @include mq(sp) {
          margin-top: 2%;
        }
      }
    }
  }
  &__inner {
    @include inner-box;
    > section {
      &:not(:first-child) {
        div[id] {
          margin-top: 105px;

          @include mq(sp) {
            margin-top: 80px;
          }
        }
      }
    }
  }
  &__list {
    margin-top: 40px;
    @include flex("", "");

    @include mq(sp) {
      margin-top: 20px;
    }

    li {
      width: 46.88%;

      @include mq(sp) {
        width: 100%;
      }

      + li {
        @include mq(sp) {
          margin-top: 40px !important;
        }
      }

      &:nth-child(n+3) {
        margin-top: 75px;
      }
    }
    &__thumb {
      text-align: center;
      position: relative;
      video {
        max-width: 100%;
        height: auto;
        background: #000;
      }
      &__cv {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;

        @include mq(1112) {
          display: none;
        }
      }
    }
    &__ttl {
      @include fz(24);
      margin: 25px 0 0;

      @include mq(sp) {
        @include fz(18);
        margin-top: 15px;
      }
    }
    &__lead {
      @include fz(16);
      margin-top: 12px;

      @include mq(sp) {
        @include fz(14);
      }
    }
    &__link {
      margin-top: 30px;
      margin-left: 0;

      @include mq(sp) {
        margin-top: 20px;
        margin-left: auto;
      }
    }
  }
}
// -----------------------------------------


// 販売マンション
&mansion {
  margin: 100px 0 140px;

  @include mq(sp) {
    margin: 60px 0 70px;
  }

  &__lead {
    @include fz(40);
    font-weight: 500;
    text-align: center;

    @include mq(sp) {
      @include fz(23);
    }
  }
  &__inner {
    @include inner-box(1340);
    padding: 0 30px;

    @include mq(sp) {
      padding: 0 15px;
    }
  }
  &__list {
    margin-top: 80px;
    @include flex("", "fs");

    @include mq(sp) {
      margin-top: 20px;
    }

    li {
      width: 22.66%;
      margin-right: 3.12%;

      @include mq(sp) {
        width: 46.62%;
        margin-right: 6.76%;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:nth-child(n+5) {
        margin-top: 70px;

        @include mq(sp) {
          margin-top: 0;
        }
      }
      &:nth-child(even) {
        @include mq(sp) {
          margin-right: 0;
        }
      
      }&:nth-child(n+3) {
        @include mq(sp) {
          margin-top: 45px;
        }
      }
      &:not(.p-mansion__list__sold-out) {
        a {
          width: 100%;
          @include hov-alpha;
          display: inline-block;
        }
      }
    }
    &__thumb {
      position: relative;
      &__cv {
        width: 100%;
        height: 100%;
        @include fz(36);
        font-weight: 500;
        line-height: 1;
        color: #fff;
        background: rgba(#000, .8);
        position: absolute;
        top: 0;
        left: 0;
        @include flex("c", "c");

        @include mq(1024) {
          @include fz(24);
        }
      }
    }
    &__ttl {
      @include fz(18);
      font-weight: 500;
      margin-top: 18px;

      @include mq(sp) {
        @include fz(13);
        margin-top: 8px;
      }
    }
    &__sold-out {
      a {
        width: 100%;
        display: inline-block;
        cursor: default;
        user-select: none;
        pointer-events: none;
      }
    }
  }

  &--single {
    &__visual {
      @include flex("", "");

      @include mq(sp) {
        display: block;
      }

      &__main {
        width: 74.22%;

        @include mq(sp) {
          width: 100%;
        }
      }
      > div {
        width: 23.44%;

        @include mq(sp) {
          width: 100%;
          margin-top: 15px;
          @include flex("", "");
        }
      }
      &__side {
        opacity: .4;
        transition: opacity .3s;
        cursor: pointer;

        @include mq(sp) {
          width: 32%;
        }

        + p {
          margin-top: 5%;

          @include mq(sp) {
            margin-top: 0;
          }
        }
        &.is-current {
          opacity: 1;
        }
      }
    }
    &__summary {
      margin-top: 72px;

      @include mq(sp) {
        margin-top: 62px;
      }

      &__ttl {
        @include fz(26);
        font-weight: 500;
        margin-bottom: 8px;

        @include mq(sp) {
          @include fz(18);
          margin-bottom: 10px;
        }
      }
      &__table {
        tr {
          @include mq(sp) {
            @include flex("", "fs");
            + tr {
              border-top: none;
            }
          }

          th {
            width: 13%;
            padding-left: 25px;

            @include mq(sp) {
              width: 40%;
              background: #f9f9f9;
              padding: 20px 10px 20px 35px;
              &:nth-child(1) {
                background: #fff;
              }
            }
          }
          td {
            width: 37%;

            @include mq(sp) {
              width: 60%;
              background: #f9f9f9;
              &:nth-child(2) {
                background: #fff;
              }
              + th {
                border-top: 1px solid #dedede;
                + td {
                  border-top: 1px solid #dedede;
                }
              }
            }
          }
          &:first-child {
            th {
              background: #f9f9f9;
            }
            td {
              background: #f9f9f9;
              > div {
                @include flex("", "");
              }
            }
          }
        }
      }
    }
    &__special {
      @include inner-box(900);
      margin-top: 100px;
      overflow: hidden;

      @include mq(sp) {
        height: 100px;
        margin-top: 60px;
      }

      a {
        width: 100%;
        height: 100%;
        position: relative;
        display: inline-block;

        img {
          @include mq(sp) {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &:hover {
          .p-mansion--single__special__cv {
            &:after {
              transform-origin: left top;
              transform: skewX(-30deg) scale(1, 1);
            }
          }
        }
      }
      &__cv {
        width: 100%;
        height: 100%;
        background: rgba($col_sub, .6);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        @include flex("c", "c");
        &:after {
          content: "";
          width: 120%;
          height: 100%;
          background: rgba(#002490, .75);
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform-origin: right top;
          transform: skewX(-30deg) scale(0, 1);
          transition: transform .3s;
        }
        &__txt {
          @include fz(28);
          font-weight: 500;
          color: #fff;
          position: relative;

          @include mq(sp) {
            @include fz(20);
          }

          &:after {
            content: "";
            width: 17px;
            height: 17px;
            background: url(#{$img_common_path}/cmn-blank-ico02.svg) 0 0 no-repeat;
            background-size: 100% 100%;
            margin-left: 12px;
            display: inline-block;

            @include mq(sp) {
              width: 12px;
              height: 12px;
            }
          }
        }
      }
    }
  }
}
// -----------------------------------------