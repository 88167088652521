@include mq(pc) {
  &tab,
  &sp {
    display: none !important;
  }
}

@include mq(tab) {
  &pc,
  &sp {
    display: none !important;
  }
}

@include mq(sp) {
  &pc,
  &tab {
    display: none !important;
  }

  &spnone {
    display: none !important;
  }
}