&pagetop {
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 10;
  display: none;

  @include mq(sp) {
    display: none !important;
  }

  a {
    width: 100%;
    display: inline-block;
    @include hov-alpha;
  }
}

&contact {
  @include wrapper-box;
  color: #fff;
  background: url(#{$img_common_path}/cmn-contact-bg-pc.jpg) center top no-repeat;
  background-size: cover;
  margin-top: 140px;
  padding-top: 100px;
  padding-bottom: 100px;

  @include mq(sp) {
    background-image: url(#{$img_common_path}/cmn-contact-bg-sp.jpg);
    margin-top: 80px;
    padding-top: 39px;
    padding-bottom: 39px;
  }

  &__inr {
    @include inner-box;
    @include flex("c", "");

    @include mq(sp) {
      display: block;
    }
  }
  &__ttl {
    @include fz(22);
    font-weight: 500;
    margin-bottom: 10px;

    @include mq(sp) {
      @include fz(23);
      line-height: 1;
      margin-bottom: 20px;
    }

    span {
      @include fz(14);
      margin-bottom: 10px;
      display: inline-block;

      @include mq(sp) {
        @include fz(13);
        margin-bottom: 5px;
      }
    }
  }
  &__lead {
    @include fz(16);
    line-height: 1.6;

    @include mq(sp) {
      @include fz(15);
      line-height: 1.5;
    }
  }
  &__info {
    margin-top: 20px;
    @include flex("c", "");

    @include mq(sp) {
      width: 100%;
    }
  }
  &__tel {
    @include fz(34);
    font-weight: 700;
    @include flex("c", "");

    @include mq(sp) {
      @include fz(26);
    }

    &:before {
      content: "";
      width: 26px;
      height: 26px;
      background: url(#{$img_common_path}/cmn-contat-tel-ico.svg) 0 0 no-repeat;
      background-size: 100% 100%;
      margin-right: 10px;
      display: inline-block;

      @include mq(sp) {
        width: 19px;
        height: 19px;
        margin-right: 7px;
      }
    }
  }
  &__time {
    @include fz(14);

    @include mq(sp) {
      @include fz(12);
    }
  }
  &__btn {
    width: 260px;
    @include fz(16);
    margin-left: 30px;

    @include mq(sp) {
      width: 37.8%;
      @include fz(13);
      margin-left: 0;
    }

    a {
      width: 100%;
      color: #fff;
      border: 1px solid #fff;
      padding: 18px 0;
      @include flex("c", "c");
      transition: color .3s, background .3s;

      @include mq(sp) {
        padding: 9px 0;
      }

      &:before {
        content: "";
        width: 21px;
        height: 14px;
        background: url(#{$img_common_path}/cmn-contact-ico01.svg) 0 0 no-repeat;
        background-size: 100% 100%;
        margin: 2px 10px 0 0;
        display: inline-block;
        transition: background .3s;

        @include mq(sp) {
          width: 13px;
          height: 9px;
        }
      }
      &:hover {
        color: #37517b;
        background: rgba(#fff, .9);
        &:before {
          background-image: url(#{$img_common_path}/cmn-contact-ico02.svg);
        }
      }
    }
  }
  &__logo {
    margin-top: 30px;
    @include flex("c", "fe");
    &__01 {
      width: 210px;
      text-align: center;

      @include mq(sp) {
        width: 50%;
        img {
          width: 150px;
        }
      }
    }
    &__02 {
      width: 106px;
      text-align: center;
      margin-left: 30px;

      @include mq(sp) {
        width: 50%;
        margin-left: 0;
        img {
          width: 112px;
        }
      }
    }
  }
}

&footer {
  @include wrapper-box;
  padding-top: 80px;
  padding-bottom: 30px;
  background: #e6e6e6;

  @include mq(1024) {
    padding-top: 55px;
    padding-bottom: 25px;
  }

  &__inner {
    @include inner-box;
  }
  &__main {
    @include flex("", "");

    @include mq(1024) {
      flex-direction: column;
    }
  }
  &__logo {
    width: 184px;

    @include mq(1024) {
      width: 100%;
      @include flex("c", "");
    }

    > p {
      @include mq(1024) {
        text-align: center;
      }
      + p {
        margin-top: 30px;
      }
    }
    &__01 {
      width: 100%;
      img {
        @include mq(1024) {
          width: 150px;
        }
      }
    }
    &__02 {
      @include mq(1024) {
        width: 50%;
      }

      img {
        @include mq(1024) {
          width: 150px;
        }
      }
    }
    &__03 {
      @include mq(1024) {
        width: 50%;
      }

      img {
        width: 103px;

        @include mq(1024) {
          width: 74px;
        }
      }
    }
  }
  &__navi {
    width: 48.59%;
    margin-top: 15px;

    @include mq(1024) {
      display: none;
    }

    &__list {
      @include flex("", "");
      dl {
        dt {
          @include fz(16);
          font-weight: bold;
          margin-bottom: 10px;
          a {
            color: $col_sub;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        dd {
          @include fz(13);
          margin-left: 8px;
          a {
            color: #626262;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        + dl {
          margin-top: 25px;
        }
      }
    }
  }
  &__btn {
    @include mq(1024) {
      width: 64.19%;
      margin: 25px auto 0;
    }

    li {
      width: 215px;

      @include mq(1024) {
        width: 100%;
      }

      + li {
        margin-top: 15px;

        @include mq(1024) {
          margin-top: 20px;
        }
      }
    }
  }
  &__bottom {
    margin-top: 46px;
    @include flex("", "fe");

    @include mq(1024) {
      margin-top: 25px;
      display: block;
    }

    li {
      @include fz(13);
      border-right: 1px solid #626262;
      margin-right: 1.3em;
      padding-right: 1.3em;

      @include mq(1024) {
        width: 100%;
        text-align: center;
        border-right: none;
        margin-right: 0;
        margin-bottom: 15px;
        padding-right: 0;
      }

      a {
        color: #626262;
        &:hover {
          text-decoration: underline;
        }
      }
      &:last-child {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
      }
    }
  }
  &__copy {
    text-align: center;
    margin-top: 35px;
    display: block;

    @include mq(1024) {
      margin-top: 0;
    }
  }

  &--shinko {
    background: #004ea2;
    padding: 16px 0;

    @include mq(sp) {
      padding: 8px 0;
    }

    &__copy {
      @include fz(12);
      text-align: center;
      color: #fff;
      display: block;

      @include mq(sp) {
        @include fz(10);
      }
    }
  }
}