html {
  font-size: $base-font-size;
}

body {
  font-family: 'Noto Sans CJK JP', 'Noto Sans JP', sans-serif;
  @include fz(12);
  font-weight: 400;
  -webkit-text-size-adjust: 100%;
  color: $col_sub;
  line-height: 1.5;
  &.is-preload {
    * {
      transition: none !important;
      &:before,
      &:after {
        transition: none !important;
      }
    }
  }
}

a {
  color: $col_sub;
  text-decoration: none;
  outline: none;
  transition: opacity .3s;
  &[href^="tel:"] {
    @include mq(pc) {
      cursor: default;
      pointer-events: none;
    }
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

input, textarea, button, select {
  font: inherit;
}

button {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
}

label {
  cursor: pointer;
}