&wrapper {
  &--info {
    @include inner-box(1340);
    margin-bottom: 130px;

    @include mq(sp) {
      margin-bottom: 70px;
    }

    &__inner {
      @include wrapper-box;
      margin-top: 80px;
      @include flex("", "");

      @include mq(834) {
        display: block;
      }

      .l-content {
        width: 72%;

        @include mq(834) {
          width: 100%;
        }
      }
    }
  }

  &--mansion {
    margin-bottom: 130px;

    @include mq(sp) {
      margin-bottom: 70px;
    }

    &__inner {
      @extend .l-wrapper--info__inner;
      display: block;
      .l-content {
        width: 100%;
      }
    }
  }

  &--search {
    margin-top: 165px;

    @include mq(sp) {
      margin-top: 120px;
    }
  }
}

&page-mv {
  margin: 80px 0 85px;
  position: relative;

  @include mq(sp) {
    margin: 75px 0 45px;
  }

  &__img {
    img {
      width: 100%;
    }
  }
  &__ttl {
    width: 100%;
    height: 100%;
    @include fz(36);
    font-weight: 500;
    color: #fff;
    text-shadow: 0px 4px 43px #080103;
    margin: auto 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    @include flex("c", "c");

    @include mq(sp) {
      @include fz(30);
    }
  }
}

&breadcrumb {
  @include wrapper-box;
  &__list {
    @include inner-box;
    @include flex("", "fs");
    li {
      @include fz(14);
      &:after {
        content: "＞";
        @include fz(12);
        color: #757575;
        margin: 0 .5em;
        display: inline-block;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
      a {
        &:hover {
          text-decoration: underline;
        }
      }
      span {
        color: #757575;
      }
    }
  }

  @include mq(sp) {
    display: none;
  }
}

&page-ttl {
  @include wrapper-box;
  max-width: 1340px;
  margin: 15px auto 0;
  &__main {
    @include inner-box;
    padding: 0 50px 7px 0;
    position: relative;
    display: inline-block;

    @include mq(sp) {
      width: 100%;
      padding: 0;
      line-height: 1.2;
    }

    &:before {
      content: "";
      width: 160px;
      height: 4px;
      background: $col_main;
      position: absolute;
      bottom: 1px;
      left: 0;
      display: inline-block;

      @include mq(sp) {
        width: 45px;
        bottom: -19px;
      }
    }
    &:after {
      content: "";
      width: 100%;
      height: 1px;
      background: $col_sub;
      position: absolute;
      bottom: 0;
      left: 0;
      display: inline-block;

      @include mq(sp) {
        width: 100%;
        bottom: -20px;
      }
    }
    .c-ttl__sub {
      @include mq(sp) {
        margin-left: 0;
        display: block;
      }
    }
  }
}

&sidebar {
  width: 19.92%;
  margin: 10px 0 0 5.74%;

  @include mq(tab) {
    width: 175px;
    margin-left: 3%;
  }

  @include mq(834) {
    width: 100%;
    margin: 60px 0 0;
  }

  &__list {
    @include fz(15);
    text-align: center;
    border-top: 1px solid $col_sub;
    &:last-child {
      border-bottom: 1px solid $col_sub;
    }
    dt {
      padding: 15px 0;
      position: relative;
      cursor: pointer;
      user-select: none;
      transition: background .1s;
      &:after {
        content: "";
        width: 8px;
        height: 8px;
        border-right: 1px solid $col_sub;
        border-bottom: 1px solid $col_sub;
        margin: auto 0;
        position: absolute;
        top: -7px;
        right: 20px;
        bottom: 0;
        display: inline-block;
        transform: rotate(45deg);

        @include mq(tab) {
          right: 15px;
        }
      }
      &.is-active {
        background: #f4f4f4;
        &:after {
          top: 5px;
          transform: rotate(-135deg);
        }
      }
    }
    dd {
      display: none;
    }
    &__cat {
      li {
        border-top: 1px solid #e8e8e8;
        a {
          width: 100%;
          padding: 15px 0;
          display: inline-block;
          transition: background .3s;
          &:hover {
            background: #f4f4f4;
          }
        }
      }
      &--parent {
        font-weight: bold;
      }
    }
    &__years {
      li {
        border-top: 1px solid #e8e8e8;
        a {
          width: 100%;
          padding: 15px 0;
          display: inline-block;
          transition: background .3s;
          &:hover {
            background: #f4f4f4;
          }
        }
      }
    }
  }
}

&loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
  visibility: visible;
  transform: skew(-35deg);
  transition: visibility 1s;
  &:before,
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    background: $col_main;
    display: block;
    position: fixed;
    top: 0;
    transition: transform.5s ease-in;
    transition-delay: .5s;

    @include mq(sp) {
      width: 120%;
    }
  }
  &:before {
    right: calc(50% - 1px);
  }
  &:after {
    left: calc(50% - 1px);
  }
  &.is-load {
    visibility: hidden;
    &:before {
      transform: translateX(-120%);
    }
    &:after {
      transform: translateX(120%);
    }
  }
}