&top {
  &-mv {
    position: relative;
    img {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
    }
    &__ttl {
      @include fz(120);
      line-height: 1.08;
      text-shadow: 0px 15px 8px rgba(8, 1, 3, 0.48);
      color: #fff;
      margin: 0 auto;
      padding: 0 30px;
      position: absolute;
      right: 40px;
      bottom: 97px;

      @include mq(834) {
        @include fz(110);
      }

      @include mq(sp) {
        font-size: vwsp(116);
        padding: 0 15px;
        right: auto;
        bottom: 87.5px;
        left: 0;
      }

      span {
        opacity: 0;
        display: inline-block;
        transform: scale(2);
        transition: opacity .2s, transform .2s;
        &.is-animate {
          opacity: 1;
          transform: scale(1);
        }
        &:nth-child(1) {
          transition-delay: 1s;
        }
        @for $i from 2 through 21 {
          &:nth-child(#{$i}) {
            transition-delay: .05s * $i + 1;
          }
        }
      }
    }
  }

  &-news {
    @include wrapper-box;
    margin-top: 120px;

    @include mq(sp) {
      margin-top: 70px;
    }

    &__inner {
      @include inner-box;
      padding: 0 vwpc(52) 0 vwpc(78);
      @include flex("", "");

      @include mq(sp) {
        padding: 0;
        flex-direction: column;
      }
    }
    &__ttl {
      width: 93px;
      margin: 30px vwpc(58) 0 0;

      @include mq(sp) {
        width: 100%;
        margin: 0 0 35px 0;
      }

      &__main {
        width: 1em;
        @include fz(36);
        line-height: 1;

        @include mq(sp) {
          width: 100%;
          @include fz(30);
          text-align: center;
        }
      }
      &__sub {
        @include fz(24);
        text-align: right;
        color: $col_main;
        margin-top: 25px;
        padding-top: 20px;
        position: relative;

        @include mq(sp) {
          @include fz(13);
          text-align: center;
          margin-top: 15px;
          padding-top: 0;
        }

        &:before {
          content: "";
          width: 105px;
          height: 1px;
          background: $col_main;
          position: absolute;
          top: 0;
          left: -17px;
          display: inline-block;
          transform: rotate(-45deg);

          @include mq(sp) {
            content: none;
          }
        }
      }
    }
    .p-news__list {
      width: 86.96%;
      flex: 1;

      @include mq(sp) {
        width: 100%;
      }
    }
    &__link {
      margin-top: 80px;

      @include mq(sp) {
        margin-top: 40px;
      }
    }
  }

  &-recruit {
    height: 400px;
    margin-top: 120px;
    position: relative;

    @include mq(1024) {
      height: auto;
      margin-top: 70px;
    }

    &__list {
      height: 100%;
      @include flex("", "fs");

      @include mq(1024) {
        height: auto;
      }

      li {
        width: 18.75%;
        height: 100%;
        position: relative;
        overflow: hidden;
        &:nth-child(odd) {
          background: #f2f6fa;
        }
        &:nth-child(even) {
          background: #d9e5f1;
        }
        &:nth-child(3) {
          margin-left: auto;
        }

        @include mq(1024) {
          flex: 1;
          &:first-child  {
            display: none;
          }
        }

        img{
          max-width: unset;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);

          @include mq(1024) {
            width: 100%;
            position: static;
            transform: translateX(0);
          }
        }
      }
    }
    &__center {
      width: 25%;
      min-width: 350px;
      height: 100%;
      text-align: center;
      color: #fff;
      background: $col_main;
      margin: 0 auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      @include flex("c", "c");
      flex-direction: column;

      @include mq(1024) {
        width: 100%;
        min-width: unset;
        height: auto;
        padding: 50px 0;
        position: static;
      }

      &__ttl {
        line-height: .6;
        &__sub {
          @include fz(14);
        }
      }
      &__txt {
        @include fz(15);
        margin: 35px 0;

        @include mq(sp) {
          margin: 20px 0 30px;
        }
      }
    }
  }

  &-results {
    color: #fff;
    background: $col_sub;
    margin-top: 90px;

    @include mq(1024) {
      margin-top: 0;
    }

    &__list {
      height: 760px;
      @include flex("", "fs");
      overflow: hidden;

      @include mq(sp) {
        height: auto;
        flex-direction: column;
      }

      &__main {
        width: 62.5%;
        height: 100%;
        position: relative;

        @include mq(sp) {
          width: 100%;
          height: 304px;
          overflow: hidden;
        }

        li {
          height: 100%;
          &:not(:first-child) {
            display: none;
          }
          a {
            width: 100%;
            height: 100%;
            color: #fff;
            display: inline-block;
            @include hov-alpha;
            > p {
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            > div {
              position: absolute;
              bottom: 7.11%;
              left: 16%;

              @include mq(1024) {
                bottom: 25px;
                left: 15px;
              }
            }
          }
        }
        &__ttl {
          @include fz(34);
          text-shadow: 0px 4px 5px #080103;

          @include mq(1024) {
            @include fz(25);
          }
        }
        &__completion {
          @include fz(20);
          text-shadow: 0px 4px 5px #080103;
          margin-top: 10px;

          @include mq(1024) {
            @include fz(15);
            margin-top: 5px;
          }
        }
      }
      &__thumb {
        width: 37.5%;
        @include flex("", "fs");

        @include mq(sp) {
          width: 100%;
        }

        li {
          width: 50%;
          height: 190.25px;
          border-left: 1px solid #fff;
          border-bottom: 1px solid #fff;
          cursor: pointer;

          @include mq(sp) {
            width: 25%;
            height: 64px;
            &:nth-child(4n+1) {
              border-left: none;
            }
            &:nth-child(n+5) {
              border-bottom: none;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: .4;
            transition: opacity .3s;
          }
          &.is-current {
            img {
              opacity: 1;
            }
          }
          @include mq(pc) {
            &:hover{
              img {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    &__box {
      @include wrapper-box;
      padding-top: 60px;
      padding-bottom: 60px;

      @include mq(1024) {
        padding-top: 40px;
        padding-bottom: 50px;
      }
    }
    &__inner {
      @include inner-box;
    }
    &__head {
      border-bottom: 1px solid #fff;
      margin-bottom: 50px;
      padding-bottom: 5px;
      @include flex("c", "");

      @include mq(1024) {
        margin-bottom: 25px;
        padding-bottom: 0;
      }

      &__ttl {
        .c-ttl__sub {
          margin-left: 30px;

          @include mq(1024) {
            margin-left: 12px;
          }
        }
      }
      &__link {
        @include fz(16);

        @include mq(1024) {
          @include fz(14);
          margin-bottom: -25px;
        }

        a {
          color: #fff;
          display: inline-block;
          &:after {
            content: "＞";
            @include fz(12);
            margin-left: 1em;
            display: inline-block;

            @include mq(1024) {
              @include fz(10);
            }
          }
          &:hover {
            border-bottom: 1px solid #fff;
          }
        }
      }
    }
    &__search {
      @include flex("c", "fs");

      @include mq(1024) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__ttl {
        @include fz(16);
        margin-right: 30px;
        padding-right: 45px;
        position: relative;

        @include mq(1024) {
          @include fz(15);
          margin-right: 20px;
          padding-right: 20px;
        }

        &:after {
          content: "";
          width: 1px;
          height: 48px;
          background: #fff;
          margin: auto 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: inline-block;
          transform: rotate(30deg);
        }
      }
      &__cat {
        width: 32.81%;
        @include flex("c", "fs");

        @include mq(1245) {
          width: 41.81%;
        }

        @include mq(1024) {
          width: 100%;
        }

        &__list {
          @include flex("", "fs");
          flex: 1;
          li {
            width: 50%;
            @include fz(15);

            @include mq(1024) {
              @include fz(14);
            }

            @include mq(sp) {
              @include fz(13);
            }

            &:nth-child(n+3) {
              margin-top: 10px;
            }
            label {
              display: inline-flex;
              align-items: center;
              justify-content: flex-start;
              input {
                margin: 0 4px 0 0;
              }
            }
          }
        }
      }
      &__keywords {
        width: 32.81%;
        margin-left: 6.25%;
        @include flex("c", "fs");

        @include mq(1024) {
          width: 100%;
          margin: 30px 0 0 0;
        }

        &__area {
          width: 100%;
          max-width: 224px;
          flex: 1;

          @include mq(1024) {
            max-width: unset;
          }

          input {
            appearance: none;
            width: 100%;
            height: 28px;
            border: none;
            padding: 1px;
            border-radius: 0;
          }
        }
      }
      &__submit {
        width: 18.36%;
        @include fz(15);
        color: #fff;
        background: $col_sub;
        border: 1px solid #fff;
        margin-left: auto;
        position: relative;
        z-index: 0;
        @include flex("c", "c");
        overflow: hidden;
        cursor: pointer;
        transition: color .3s;

        @include mq(tab) {
          width: 235px;
          margin: 35px auto 0;
        }

        @include mq(sp) {
          width: 60%;
          margin: 35px auto 0;
        }

        &:before {
          content: "";
          width: 17px;
          height: 17px;
          background: url(#{$img_common_path}/cmn-search-ico.svg) 0 0 no-repeat;
          background-size: 100% 100%;
          display: inline-block;
          transition: background .3s;

          @include mq(1024) {
            width: 12px;
            height: 12px;
          }
        }
        &:after {
          content: "";
          width: 120%;
          height: 100%;
          background: #fff;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          transform-origin: right top;
          transform: skewX(-30deg) scale(0, 1);
          transition: transform .3s;
        }
        span {
          font-family: 'Noto Sans CJK JP', 'Noto Sans JP', sans-serif;
          margin-left: 20px;
          padding: 13px 0;
          display: inline-block;

          @include mq(1024) {
            margin-left: 8px;
          }
        }
        &:hover {
          color: $col_sub;
          &:before {
            background-image: url(#{$img_common_path}/cmn-search-ico02.svg);
          }
          &:after {
            transform-origin: left top;
            transform: skewX(-30deg) scale(1, 1);
          }
        }
      }
    }
  }

  &-methods {
    margin-top: 100px;
    @include flex("fs", "fs");

    @include mq(1024) {
      margin-top: 60px;
      flex-direction: column;
    }

    > div {
      width: 36.06%;
      min-width: 418px;
      padding: 0 2.88% 0 10.13%;
      position: relative;
      @include flex("fs", "");
      flex-direction: column;

      @include mq(1024) {
        width: 100%;
        padding: 0 15px;
        min-width: unset;
      }
    }
    &__ttl {
      @include mq(1024) {
        margin-left: 80px;
      }

      @include mq(sp) {
        margin-left: 65px;
      }

      &:before,
      &:after {
        content: "";
        background: $col_main;
        position: absolute;
        display: inline-block;
      }
      &:before {
        width: 312px;
        height: 1px;
        top: 97px;
        left: 0;

        @include mq(1024) {
          width: 155px;
          top: 71px;
        }
      }
      &:after {
        width: 1px;
        height: 155px;
        top: 20px;
        left: 16.32%;

        @include mq(1024) {
          height: 79px;
          top: 33px;
          left: 45px;
        }
      }
    }
    &__sub {
      @include fz(60);
      line-height: 1.17;
      text-align: center;
      margin-top: 60px;

      @include mq(1024) {
        width: 100%;
        @include fz(40);
        margin-top: 50px;
      }

      &:before,
      &:after {
        content: "";
        background: $col_main;
        position: absolute;
        display: inline-block;
      }
      &:before {
        width: 312px;
        height: 1px;
        top: 366px;
        right: 0;

        @include mq(1024) {
          width: 155px;
          top: 258px;
        }
      }
      &:after {
        width: 1px;
        height: 155px;
        top: 289px;
        right: 69px;

        @include mq(1024) {
          height: 79px;
          top: 218px;
          right: 39px;
        }
      }
    }
    &__lead {
      @include fz(15);
      line-height: 2.13;
      margin-top: 190px;

      @include mq(1024) {
        margin-top: 110px;
      }
    }
    &__list {
      @include flex("", "fs");
      flex: 1;

      @include mq(1024) {
        margin-top: 50px;
        padding: 0 15px;
      }

      @include mq(sp) {
        width: 100%;
        flex-direction: column;
        flex: unset;
      }

      li {
        width: 272px;
        height: 600px;
        margin-right: 24px;
        overflow: hidden;

        @include mq(1368) {
          width: 31%;
          margin-right: 2.78%;
        }

        @include mq(sp) {
          width: 100%;
          height: auto;
          margin-right: 0;
        }

        &:nth-child(2) {
          margin-top: 28px;

          @include mq(tab) {
            margin-top: 0;
          }

          @include mq(sp) {
            margin-top: 40px;
          }
        }
        &:last-child {
          margin-top: 56px;
          margin-right: 0;

          @include mq(tab) {
            margin-top: 0;
          }

          @include mq(sp) {
            margin-top: 40px;
          }
        }
        a {
          width: 100%;
          height: 100%;
          color: #fff;
          position: relative;
          display: inline-block;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @include mq(pc) {
            &:hover {
              .p-top-methods__list__cv {
                &:before {
                  transform-origin: left top;
                  transform: skewY(-30deg) scale(1, 1);
                }
                &__ttl {
                  margin-top: 144px;
                }
                &__lead {
                  opacity: 1;
                  visibility: visible;
                }
                &__num {
                  opacity: 0;
                  visibility: hidden;
                }
                &__hov-txt {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
      }
      &__cv {
        width: 100%;
        height: 100%;
        background: rgba(#000, .4);
        padding: 0 9.19%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        @include flex("", "fs");
        flex-direction: column;

        @include mq(pc) {
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: -5%;
            z-index: -1;
            width: 110%;
            height: 180%;
            background: rgba(#002490, .75);
            display: inline-block;
            transform-origin: left bottom;
            transform: skewY(-30deg) scale(1, 0);
            transition: transform .5s;
          }
        }

        @include mq(1024) {
          align-items: center;
          justify-content: center;
        }

        &__ttl {
          @include fz(36);
          line-height: 1.11;
          text-align: center;
          text-shadow: -3.1px 2.5px 9px #080103;
          margin-top: 210px;
          transition: margin-top .5s;

          @include mq(1219) {
            font-size: vwpc(46);
          }

          @include mq(1024) {
            @include fz(26);
            line-height: 1.33;
            margin-top: 0;
          }
        }
        &__lead {
          @include fz(14);
          text-align: justify;
          margin-top: 70px;
          opacity: 0;
          visibility: hidden;
          transition: opacity .5s, visibility .5s;

          @include mq(1219) {
            font-size: vwpc(18);
          }

          @include mq(1024) {
            display: none;
          }
        }
        &__num {
          width: 100%;
          @include fz(30);
          line-height: .9;
          color: #fff;
          padding-right: 10px;
          position: absolute;
          bottom: 0;
          right: 0;
          @include flex("c", "fe");
          transition: opacity .5s, visibility .5s;

          @include mq(1219) {
            font-size: vwpc(40);
          }

          @include mq(1024) {
            @include fz(18);
            padding-right: 7px;
          }

          span {
            @include fz(120);
            margin-left: 5.51%;
            display: inline-block;

            @include mq(1219) {
              font-size: vwpc(130);
            }

            @include mq(1024) {
              @include fz(73);
              margin-left: 12px;
            }
          }
        }
        &__hov-txt {
          width: 57.35%;
          @include fz(16);
          text-align: right;
          position: absolute;
          right: 11.03%;
          bottom: 50px;
          opacity: 0;
          visibility: hidden;
          transition: opacity .5s, visibility .5s;

          @include mq(1219) {
            font-size: vwpc(18);
          }

          &:before {
            content: "";
            width: 38.96%;
            height: 1px;
            background: #fff;
            margin: auto 0;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: inline-block;
          }
        }
      }
    }
  }

  &-noble {
    @include wrapper-box;
    color: #fff;
    background: url(#{$img_path}/top-noble-bg-pc.jpg) center top no-repeat;
    background-size: cover;
    margin-top: 120px;

    @include mq(sp) {
      background-image: url(#{$img_path}/top-noble-bg-sp.jpg);
      margin-top: 70px;
    }

    &__inner {
      @include inner-box;
      padding-top: 100px;
      padding-bottom: 100px;

      @include mq(sp) {
        padding-top: 60px;
        padding-bottom: 70px;
      }
    }
    &__ttl {
      @include fz(46);
      text-align: center;

      @include mq(sp) {
        @include fz(30);
      }
    }
    &__lead {
      @include fz(15);
      line-height: 2.13;
      text-align: center;
      margin-top: 25px;

      @include mq(sp) {
        text-align: left;
      }
    }
    &__list {
      margin-top: 40px;
      @include flex("", "fs");

      @include mq(sp) {
        flex-direction: column;
        align-items: center;
      }

      li {
        width: 32.08%;

        @include mq(sp) {
          width: 90.91%;
          text-align: center;
        }

        + li {
          margin-left: 1.88%;

          @include mq(sp) {
            margin: 40px 0 0 0;
          }
        }
      }
    }
    &__link {
      margin-top: 60px;

      @include mq(sp) {
        margin-top: 40px;
      }
    }
  }

  &-bottom {
    margin: 90px 0 140px;

    @include mq(sp) {
      margin: 50px 0 70px;
    }

    &__list {
      @include flex("", "");

      @include mq(sp) {
        flex-direction: column;
      }

      li {
        width: 50%;

        @include mq(sp) {
          width: 100%;
          + li {
            margin-top: 30px;
          }
        }

        a {
          width: 100%;
          display: inline-block;
          @include hov-alpha;
          img {
            width: 100%;
          }
        }
      }
      &__ttl {
        margin: 0 0 15px 40px;

        @include mq(sp) {
          margin: 0 0 0 15px;
        }
      }
    }
  }
}