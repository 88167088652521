@charset "UTF-8";

/*!
Coding style from FLOCSS
https://github.com/hiloki/flocss
 */

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/function";
@import "foundation/setting";
@import "foundation/mixin";
@import "foundation/reset";
@import "foundation/base";


// ==========================================================================
// Layout
// ==========================================================================

.l- {
  @import "layout/header";
  @import "layout/navi";
  @import "layout/main";
  @import "layout/footer";
}


// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

.c- {
  @import "object/component/title";
  @import "object/component/text";
  @import "object/component/button";
  @import "object/component/list";
  @import "object/component/table";
}

// slick
@import "object/component/slick";


// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

.p- {
  @import "object/project/top";
  @import "object/project/page";
  @import "object/project/post";
}


// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

.u- {
  @import "object/utility/font";
  @import "object/utility/display";
  @import "object/utility/margin";
}