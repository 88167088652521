// ベースフォントサイズ
$base-font-size: 10px;

// breakpoint
$breakpoint_list: 767px, 1024px;

// カラー
$col_main: #004ea2;
$col_sub: #201f1f;

// 画像ファイルパス
$img_path: "/cms/wp-content/themes/tada-con/images";
$img_common_path: "/cms/wp-content/themes/tada-con/images/common";