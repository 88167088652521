&btn {
  width: 235px;
  @include fz(15);
  text-align: center;
  border: 1px solid $col_sub;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;

  @include mq(sp) {
    width: 60%;
  }

  a,
  button {
    width: 100%;
    color: $col_sub;
    padding: 13px 0;
    position: relative;
    z-index: 0;
    display: inline-block;
    transition: color .3s;

    @include mq(sp) {
      padding: 14px 0;
    }

    &:after {
      content: "";
      width: 120%;
      height: 100%;
      background: $col_sub;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transform-origin: right top;
      transform: skewX(-30deg) scale(0, 1);
      transition: transform .3s;
    }
    &:hover {
      color: #fff;
      &:after {
        transform-origin: left top;
        transform: skewX(-30deg) scale(1, 1);
      }
    }
  }
  &--rev {
    @extend .c-btn;
    border-color: #fff;
    a,
    button {
      color: #fff;
      &:after {
        background: #fff;
      }
      &:hover {
        color: $col_sub;
      }
    }
  }

  &02 {
    @extend .c-btn;
    border-color: #fff;
    a {
      color: #fff;
      &:after {
        background: #fff;
      }
      &:hover {
        color: $col_main;
      }
    }
    &--rev {
      @extend .c-btn02;
      border-color: $col_main;
      a {
        color: $col_main;
        &:after {
          background: $col_main;
        }
        &:hover {
          color: #fff;
        }
      }
    }
  }
}