// グローバル
&gnavi {
  @include mq(pc) {
    height: auto !important;
    display: block !important;
  }

  @include mq(1024) {
    width: 100%;
    max-height: calc(100vh - 75px);
    background: rgba(#000, .9);
    position: absolute;
    top: 75px;
    left: 0;
    overflow-y: auto;
    display: none;
  }

  &__list {
    height: 80px;
    @include flex("","fs");

    @include mq(1024) {
      height: auto;
      padding: 60px 15px 100px;
      flex-direction: column;
    }

    > li {
      height: 100%;
      @include fz(15);

      @include mq(1024) {
        width: 100%;
        height: auto;
        + li {
          margin-top: 40px;
        }
      }

      > a {
        height: 100%;
        padding: 0 vwpc(22);
        @include flex("c","fs");
        transition: color .1s;

        @include mq(1103) {
          padding: 0 vwpc(15);
        }

        @include mq(1024) {
          font-family: "リュウミン R-KL", "Noto Serif CJK JP", "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, serif;
          @include fz(23);
          color: #fff;
          padding: 0;
        }

        &:hover {
          @include mq(pc) {
            color: $col_main !important;
          }
        }
        span {
          font-weight: 500;
          display: inline-block;
        }
      }
      &.is-mnavi {
        > a {
          color: $col_main;
        }
      }
      &.is-current {
        > a {
          span {
            position: relative;
            &:after {
              content: "";
              width: 100%;
              height: 2px;
              background: $col_main;
              margin: 0 auto;
              position: absolute;
              right: 0;
              bottom: -4px;
              left: 0;
              display: inline-block;

              @include mq(1024) {
                content: none;
              }
            }
          }
        }
      }
    }
  }
}


// メガ
&mnavi {
  @include wrapper-box;
  width: 100%;
  background: rgba(#000, .9);
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 10;
  display: none;

  @include mq(1024) {
    height: auto !important;
    background: none;
    padding: 0;
    position: static;
    display: block !important;
  }

  &__inner {
    @include inner-box;
    @include flex("", "fs");
    color: #fff;
    padding-top: 27px;
    padding-bottom: 27px;

    @include mq(1024) {
      padding-top: 0;
      padding-bottom: 0;
      flex-direction: column;
    }

    > div {
      margin-left: 55px;
      flex: 1;

      @include mq(1024) {
        margin-left: 0;
        flex: unset;
      }
    }
  }
  &__thumb {
    @include mq(1024) {
      display: none;
    }
  }
  &__ttl {
    @include fz(26);
    margin-top: 5px;

    @include mq(1024) {
      display: none;
    }
  }
  &__list {
    @include flex("", "fs");
    border-left: 1px solid #fff;
    margin-top: 25px;

    @include mq(1024) {
      border-left: none;
      margin-top: 0;
      flex-direction: column;
    }

    li {
      @include fz(16);
      @include flex("", "c");
      font-weight: 500;
      text-align: center;
      border-right: 1px solid #fff;
      padding: 0 20px;

      @include mq(1024) {
        text-align: left;
        border-right: none;
        margin-top: 1em;
        padding: 0;
        justify-content: flex-start;
      }

      a {
        color: #fff;

        @include mq(1024) {
          color: rgba(#fff, .8);
          &:before {
            content: "– ";
          }
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}